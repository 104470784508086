<template>
  <div class="box">
    <!-- 头部 -->
    <headers></headers>
    <el-carousel :interval="3000">
      <el-carousel-item>
        <img src="@/assets/homePage/新闻资讯banner.png" class="images">
      </el-carousel-item>
    </el-carousel>
    <!-- 内容 -->
    <div class="content">
      <h1 style="font-weight: 400;">{{ dataList.title }}</h1>
      <p class="p_1">{{ dataList.date }}</p>
      <el-divider class="divI">
        <div class="icon-wechat span_1" @click="qrCodes">
          <div id="qrcode" ref="qrcode" class="qrcode" v-show="VXisShow">
            <p>即可分享页面内容</p>
            <p>打开微信扫一扫</p>
          </div>
        </div>
        <span class="icon-sina-weibo span_2" @click="weiBo"></span>
      </el-divider>
      <!-- 主要内容 -->
      <!-- 内容一 -->
      <p class="p_01" v-for="item in dataList.content_1" :key="item.id" style="text-indent: 2em;">{{ item.title }}</p>
      <!-- 第一张图片 -->
      <img :src="dataList.img_1" alt="">
      <!-- 内容二 -->
      <p class="p_01" v-for="item in dataList.content_2" :key="item.id" style="text-indent: 2em;">{{ item.title }}</p>
      <!-- 图片2 -->
      <img :src="dataList.img_2" alt="" />
      <!-- 内容三 -->
      <p class="p_01" v-for="item in dataList.content_3" :key="item.id" style="text-indent: 2em;">{{ item.title }}</p>
      <img :src="dataList.img_3" alt="" />

      <p class="p_01" v-for="item in dataList.content_4" :key="item.id" style="text-indent: 2em;">{{ item.title }}</p>

    </div>
    <!-- 底部 -->
    <foot></foot>
  </div>
  <div class="_div">
    <!-- 微信弹框遮罩层 -->
    <div class="shade" v-show="isShade"></div>
    <!-- 微信弹出框 -->
    <div class="diaLog" v-show="isShow_diaLog">
      <van-icon name="certificate" size="12rem" style="color: #EF3E2E;display: block;" />
      <h3>网页链接已复制</h3>
      <p>快去微信分享给好友吧！</p>
      <el-divider content-position="right" style="width: 90%;margin:0 auto;"></el-divider>
      <p class="I" @click="know">我知道了</p>
    </div>
    <!-- 头部 -->
    <move_header></move_header>
    <!-- 轮播图 -->
    <van-swipe :autoplay="3000" indicator-color="white">
      <van-swipe-item>
        <img src="@/assets/homePage/关于盛泰banner.png" style="width: 100%;">
      </van-swipe-item>
    </van-swipe>
    <!-- 中间内容区域 -->
    <div class="center">
      <h2>{{ dataList.title }}</h2>
      <p class="p_1">{{ dataList.date }}</p>
      <el-divider class="divI">

        <div class="icon-wechat span_1 btn" @click="copYBtn" :data-clipboard-text="urlInfo"> </div>

        <span class="icon-sina-weibo span_2" @click="weiBo"></span>
      </el-divider>
      <div class="textImg">
        <!-- 内容一 -->
        <p class="p_01" v-for="item in dataList.content_1" :key="item.id" style="text-indent: 2em;">{{ item.title }}</p>
        <!-- 第一张图片 -->
        <img :src="dataList.img_1" alt="">
        <!-- 内容二 -->
        <p class="p_01" v-for="item in dataList.content_2" :key="item.id" style="text-indent: 2em;">{{ item.title }}</p>
        <!-- 图片2 -->
        <img :src="dataList.img_2" alt="" />
        <!-- 内容三 -->
        <p class="p_01" v-for="item in dataList.content_3" :key="item.id" style="text-indent: 2em;">{{ item.title }}</p>

        <img :src="dataList.img_3" alt="" />
        <p class="p_01" v-for="item in dataList.content_4" :key="item.id" style="text-indent: 2em;">{{ item.title }}</p>

      </div>
    </div>
    <!-- 底部 -->
    <move_foot></move_foot>
  </div>
</template>

<script>
import foot from '../components/foot.vue'
import headers from '../components/header.vue'
import move_foot from "../move_components/move_foot.vue";
import move_header from "../move_components/move_header.vue";
import { reactive, toRefs, onMounted } from 'vue'
import QRCode from 'qrcodejs2'
import { Dialog } from 'vant';
import { useRoute } from "vue-router";
import Clipboard from 'clipboard';
export default {
  components: { foot, headers, move_foot, move_header },
  name: 'zhNewsId',
  setup() {
    const route = useRoute()
    const state = reactive({
      VXisShow: false,
      dataList: {},
      isShow_diaLog: false,
      isShade: false,
      urlInfo: window.location.href,
    })
    let methods = {
      weiBo(title, url, pic) {
        let param = {
          url: window.location.href,
          /*分享地址(可选)*/
          type: '',
          count: '1',
          /** 是否显示分享数，1显示(可选)*/
          appkey: '',
          /** 您申请的应用appkey,显示分享来源(可选)*/
          title: '这个内容很有趣，一起来看看吧！',
          /** 分享的文字内容(可选，默认为所在页面的title)*/
          pic: pic || '',
          /**分享图片的路径(可选)*/
          ralateUid: '',
          /**关联用户的UID，分享微博会@该用户(可选)*/
          rnd: '',
        }
        let temp = [];
        for (var p in param) {
          temp.push(p + '=' + encodeURIComponent(param[p] || ''))
        }
        var targetUrl = 'http://service.weibo.com/share/share.php?' + temp.join('&');
        window.open(targetUrl, 'sinaweibo', 'height=800, width=800');
      },
      qrCode() {
        let qrcode = new QRCode('qrcode', {
          width: 100,
          height: 100,
          text: window.location.href, // 二维码地址
          colorDark: "#000",
          colorLight: "#fff",
        })
      },
      qrCodes() {
        state.VXisShow = !state.VXisShow

      },
      copYBtn() {
        state.isShow_diaLog = true;
        state.isShade = true
      let clipboard = new Clipboard(".btn"); // 这里括号里填写上面点击事件绑定的class名
      clipboard.on("success", e => {
 
        clipboard.destroy();
      });
      clipboard.on("error", e => {
        clipboard.destroy();
      });
      },
      know() {
        state.isShow_diaLog = false
        state.isShade = false
      }
    }
    onMounted(() => {
      window.scrollTo(0, 0)
      methods.qrCode()
      if (route.query.id == 1) {
        state.dataList = {
          title: '喜讯！盛泰光电何阿强上榜“最美青年技能人才”',
          date: '2022-5-12',
          img_1: require('@/assets/news/1/1.jpg'),
          content_1: [
            { id: 1, title: '2022年我们党将召开第二十次全国代表大会，中国共青团也迎来建团100周年，在以习近平同志为核心的党中央坚强领导下，广大青年听党号令、跟着党奋斗，在经济发展、乡村振兴、创新创业、社会服务等领域履职尽责、砥砺笃行，用实际行动践行了“请党放心、强国有我”的青春誓言，彰显了新时代中国青年的良好风貌，在我们的身边有这么一群青年，他们技艺超群，他们用现代化理论知识成为引领大足各行业的优秀技能劳动者！他们就是“最美青年技能人才”' },
            { id: 2, title: '很荣幸，盛泰光电何阿强同志上榜大足区“最美青年”优秀事迹展示活动第四期“最美青年技能人才”' },
            { id: 3, title: '何阿强，男，中共党员，现任盛泰光电科技股份有限公司工程经理。' },
            { id: 4, title: '自2017年7月加入江西盛泰光学有限公司以来，他孜孜不倦、锲而不舍磨练自己的技能之“剑”，充分发挥技能带头人作用。2019年3—5月，他远赴印度，协助公司高层完成印度分公司的前期测绘、规划及装修工作；2019年7月，他参与公司新园区“盛泰科技企业孵化园”的规划建设，负责图纸绘制，设计对接，进度跟进等工作；2021年6月—12月，主导产业园到盛泰孵化园的产能搬迁工作，协调公司内部生产计划及外部搬迁工作，保质保量的完成了整体产能转移，为公司大量生产高精尖产品做好铺垫。' },
            { id: 5, title: '该同志在日常工作中坚持贯彻IE思维，降本增效、精益求精。协同设计院完成83000㎡的现代化园区设计工作；协同顾问团队完成20000㎡百级/千级恒温恒湿车间设计、建设工作；完成16条智能化SMT贴装线体建设及12条COB封装线体的智能化改造；跟进智能AOI设备、自动机械手等百余台设备的自动化导入；主导并制定各类型产品的标准生产工艺；全厂智能化设备的选型评估，导入等相关工作。利用专业知识合理规划生产车间与线体，对公司投入生产做出卓越贡献。' }
          ]
        }
      } else if (route.query.id == 2) {
        state.dataList = {
          title: '杨烈常委一行莅临我司调研基层社会治理和平安建设工作',
          date: '2022-2-21',
          img_1: require('@/assets/news/2/1.jpg'),
          img_2: require('@/assets/news/2/2.jpg'),
          img_3: require('@/assets/news/2/3.jpg'),
          content_1: [
            { id: 1, title: '2022年2月21日，区委常委、政法委书记杨烈，区委政法委常务副书记王安德 ，区信访办党组书记、主任梅兴华，经开区信访接待中心党支部书记、主任黄东海一行来我司就基层社会治理和平安建设工作开展专题调研。公司副总经理孙爱国接待并陪同调研。' },
          ],
          content_2: [
            { id: 1, title: '孙总详细介绍了我司关于基层社会治理、平安建设、企业安全生产、治安和警民协作政法等工作开展情况。调研组对我司取得的成绩给予了充分的肯定。' }
          ],
          content_3: [
            { id: 1, title: '杨烈指出，推动项目和企业春节后复工复产，事关一季度实现“开门红”、“开门稳”，事关经济社会高质量发展。要在做好安全生产、疫情防控等基础工作的前提下，加快复工复产，尽快释放产能，努力实现生产上规模、上水平。各企业要切实履行好疫情防控主体责任，严格落实常态化疫情防控措施，做到疫情防控和生产经营两不误。各相关部门要抢先机、抓早字、争主动，下好先手棋，主动靠前服务，全力纾困解难，多措并举助力项目、企业复工复产，稳企业、增动能、促发展，确保全年各项工作高点起步、精彩开局。' }
          ],

        }
      } else if (route.query.id == 3) {
        state.dataList = {
          title: '以科技加持，以可靠助力，盛泰公司可靠性实验分析中心剪彩仪式圆满举行',
          date: '2022-1-12',
          img_1: require('@/assets/news/3/1.jpg'),
          img_2: require('@/assets/news/3/2.jpg'),
          img_3: require('@/assets/news/3/3.jpg'),
          content_1: [
            { id: 1, title: '2022年1月12日上午10：00，盛泰光电科技股份有限公司可靠性实验分析中心正式启用剪彩仪式在公司顺利举行。' },
            { id: 2, title: '传音公司邹卓先生、李金智先生莅临现场，盛泰光电科技股份有限公司董事长赵伟先生，总经理苏启雄先生（视频连线）、副总经理张伟先生、公司员工代表等40余人齐聚一堂，共同见证了具有里程碑意义的重要时刻。董事长赵伟、总经理苏启雄、传音公司邹卓先生分别致辞表达了祝贺。' },
            { id: 3, title: '传音研发公司为盛泰颁发“最具价值合作伙伴”奖杯，本次颁奖代表着传音公司对我司产品和团队的认可和的信任，希望双方的友谊蒸蒸日上，未来的成就像火红的丝绸般夺目辉煌！' }
          ],
          content_2: [
            { id: 1, title: '1. 可靠性试验：是指通过试验测定和验证产品的可靠性。研究在有限的样本、时间和使用费用下，找出产品薄弱环节。可靠性试验是为了解、评价、分析和提高产品的可靠性而进行的各种试验的总称。产品在规定的条件下、规定的时间内完成规定功能的能力。' },
            { id: 2, title: '2. 失效分析：协助客户投诉、试产新项目及制程产品异常分析并找出真正原因，快速应对产品异常故障，促进产品良率的持续改善' },
            { id: 3, title: '3. 仪校：建立完善的监视与测量装置控制程序，规范处理对测量仪器的维护、保养及校正，维护测量仪器的准确性，确保产品检测符合要求。' }
          ],
          content_3: [
            { id: 1, title: '此次可靠性实验分析中心剪彩仪式的举行，标志着重庆盛泰光电科技股份有限公司步入发展新征程、开启发展新篇章。' },
            { id: 2, title: '盛泰光电秉承“立足光电行业、成为国际一流品牌客户的最佳选择”的企业使命，践行“以客户为本、以品质为核心、精细化作基础、靠技术和研发永续发展”的行为准则，以技术创新为基础，持续为客户创造更多价值，为构建一个安全、智能、便捷、高效的社会而不懈努力。' }
          ],

        }
      } else if (route.query.id == 4) {
        state.dataList = {
          title: '重庆新闻联播到我司进行专题采访',
          date: '2021-11-05',
          img_1: require('@/assets/news/4/1.jpg'),
          img_2: require('@/assets/news/4/2.jpg'),
          content_1: [
            { id: 1, title: '2021年11月5日，重庆新闻联播专题采访盛泰光电科技股份有限公司。' },
            { id: 2, title: '公司人力资源中心&行政部副总经理孙爱国带领栏目组一行分别参观了车间、展厅，同时介绍了盛泰的发展历程、生产流程、高质量经营、未来展望以及新厂房在建情况。' }
          ],
          content_2: [
            { id: 1, title: '随后双方开展座谈会，深入了解公司的发展情况。盛泰公司成立初期产值不到400万，在政府提供的供应链金融服务支持下，政府“代采代购”材料，极大的缓解了公司资金压力，产能快速扩大，今年产值增加到近40亿。' },
            { id: 2, title: '重庆新闻联播本次到盛泰光电进行实地采访，是对盛泰公司3年成长的肯定，同时对提升公司在光电行业内的影响力和社会知名度起到了促进作用。' },
            { id: 3, title: '未来，盛泰光电将继续秉承“立足光电行业、成为国际一流品牌客户的最佳选择”的企业使命，践行“以客户为本、以品质为核心、精细化作基础、靠技术和研发永续发展”的行为准则，以技术创新为基础，持续为客户创造更多价值，为构建一个安全、智能、便捷、高效的社会而不懈努力。' },
          ],
        }
      } else if (route.query.id == 5) {
        state.dataList = {
          title: '利剑出刃，2020年营收约30亿斩获2021重庆民营、制造双百强！',
          date: '2021-10-09',
          img_1: require('@/assets/news/5/1.jpg'),
          img_2: require('@/assets/news/5/2.jpg'),
          img_3: require('@/assets/news/5/3.jpg'),
          content_1: [
            { id: 1, title: '2021年10月9日，2021重庆民营企业100强峰会在渝举行。会上，重庆市工商联发布了2021重庆民营企业100强、制造业100强、科技创新指数100强榜单，重庆盛泰光电公司斩获民营企业、制造业双百强。市委常委、市委统战部部长李静出席峰会并讲话，副市长陈金山为上榜企业代表颁发证书。' }
          ],
          content_3: [
            { id: 1, title: ' 公司不仅建立了覆盖华东、华南、华中的销售网络，产品还远销海外，包含北美洲、南美洲、亚洲、非洲等地区和国家。      公司产品广泛应用于OPPO、vivo、传音、联想、海信、华为等手机产品和虹膜识别、车载、医疗、3Dtof、手表、笔记本电脑等非手机类产品，以及提供AI视觉解决方。有一流的客户服务，强大的研发资源和制造能力。' }
          ],
        }
      } else if (route.query.id == 6) {
        state.dataList = {
          title: '赋能发展，砥砺前行，大足区区委书记陈一清调研重庆盛泰光电有限公司！',
          date: '2021-10-09',
          img_1: require('@/assets/news/6/1.jpg'),
          img_2: require('@/assets/news/6/2.jpg'),
          content_1: [
            { id: 1, title: '2021年10月9日，大足区区委书记陈一清前往双桥经开区调研，区委副书记、代区长、双桥经开区党工委书记、管委会主任徐晓勇等同行参加，盛泰光电总经理苏启雄陪同调研。' },
            { id: 2, title: '在调研中，总经理苏启雄详细介绍了公司生产经营活动情况、产品广泛运用于城市管理运行、汽车智能驾驶、通讯和医疗服务等领域。近年来，盛泰发展迅速，有效带动了配套企业集聚。' }

          ],
          content_2: [
            { id: 1, title: '区委书记陈一清参观了SMT、前段FOL、后段EOL产品生产线，听取新技术研发进展情况介绍，他强调，要深入贯彻落实习近平总书记对重庆提出的系列重要指示要求和党中央决策部署，按照全市主城都市区工作座谈会要求和区委安排，把握新机遇，树立新目标，焕发新活力，作出新贡献，加快成为全区经济高质量发展的主引擎和重要增长极。' }
          ],
        }
      } else if (route.query.id == 7) {
        state.dataList = {
          title: '出货量全球第四！盛泰光电荣登人民日报头刊！',
          date: '2021-10-09',
          img_1: require('@/assets/news/7/1.jpg'),
          img_2: require('@/assets/news/7/2.jpg'),
          content_1: [
            { id: 1, title: '抓住风口,对企业有多重要?' },
            { id: 2, title: '“风口,我们抓住了!3年内年产值从0.2亿元增长到近43亿元。在重庆市大足区,重庆盛泰光电有限公司总裁苏启雄感慨。' },
            { id: 3, title: '3年前,正是摄像头产业扩大的关键期。“我们有专利有技术,就是缺资金。想要融资,但公司体量不够,导致信贷额度低、无法扩大产能，苏启雄只能望订单兴叹。' },
            { id: 4, title: ' 一次碰面,苏启雄就跟自己的链长、大足双桥经开区财务局局长欧国枫说起了企业经营的难处。' },
            { id: 5, title: '链长是个什么职务?原来，大足区梳理出17条重点产业链,由党委和政府相关负责人担任链长,围绕产业链服务，推动产业集群发展。' },
            { id: 6, title: '企业有难事，政府要帮忙。大足区经过深人调研和反复论证,针对优势显著、前景广阔、市场订单量大、资金需求量大、融资担保物不足的科技型企业，创造性地推出“代采代购代建”供应链金融服务模式,解决此类成长型企业的发展难题。' },
            { id: 7, title: '为此,大足国有平台公司向盛泰光电投入1.85亿元资金,用于原材料和设备代采代购,并投资1.8亿元修建科技企业孵化园,为盛泰光电提供扩大产能的基础设施。部分投资转化为股权，硬件设施则由企业分期付款回购' },
          ],
          content_2: [
            { id: 1, title: '在盛泰光电车间,各类高端摄像头源源不断下线,随之而来的是订单大幅增长。透过车间玻璃窗，一排机器只有1名操作员控制。“新设备投用后，我们集以前8道工序于一体,1名员工能顶以前的32名员工。"看着玻璃后的无尘生产车间,苏启雄眼里满是骄傲“产量是以前的5倍。' },
            { id: 2, title: '“‘代采代购代建’的供应链金融服务,不是单纯给有困难的企业投钱，而是在研判产业前景和困难的前提下,为上下游企业提供类金融支持，解决企业发展遇到的困难，甚至帮助他们再上台阶。"大足区委书记陈一清介绍说。' },
            { id: 3, title: '在大足区.已有多家企业受益于这种模式。重庆佳航公司一年内扭转颓势,2020年产值突破44亿元,2021年 1-8月产值达 41.2亿元,预计年底达 60亿元。重庆德能公司主营业务收入同比增长超过8%。同时，地方国企通过投资实现健康转型。2020年,大足主要国企开投集团实现营收超过 40亿元,同比增长近1倍。' },
            { id: 4, title: '作为链长,不仅要把“代采代购代建”这样的好政策送到企业的手上，更要根据产业链需求招商引资,促进产业集群的汇聚和高质量发展。大足区政府代区长徐晓勇介绍:“在大足,引进一家企业,要带活一个链条，汇聚一个产业。”' },
          ],
          content_3: [
            { id: 1, title: '2015年,彭晖到大足高新技术产业开发区成立了施密特电梯公司.“没有上下游企业,园区里就我们一家电梯公司,物流和仓储成本也是不小的开支。”' },
            { id: 2, title: '没几天，“电梯链”链长、大足高新区管委会常务副主任罗登才找到彭晖,拿出一张纸……仔细一瞧,上边密密麻麻地手写着电梯的配套产品“现在最紧缺哪些?我去给你找。”随着电梯配套企业陆续进驻大足,彭晖心里有了底,“左邻右舍都是上下游企业,现在出货都比以前快了10多天。”' },
            { id: 3, title: '2020年,施密特公司产值7.9亿元,同比增长 14.8%。大足区总共生产电梯整机约 7100台,产业产值27.4亿元、同比增长超过 50%。' },
            { id: 4, title: '在大足,产业集聚抱团,走上产业升级、高质量发展的路子。今年 1-8月,当地五大特色产业规上工业产值达 462.8亿元、增长 27.7%。现在的彭晖,经常给外地的同行朋友打电话:“来大足吧,咱们一起把蛋糕做大。' },
            { id: 5, title: '“风口，我们抓住了！3年内年产值从0.2亿元增长到近43亿元。”在重庆市大足区，盛泰光电科技股份有限公司总裁苏启雄感慨。' }
          ]
        }
      }
      else if (route.query.id == 9) {
        state.dataList = {
          title: '团市委一行莅临公司参观考察',
          date: '2022-06-07',
          img_1: require('@/assets/news/9/1.jpg'),
          img_2: require('@/assets/news/9/2.jpg'),
          content_1: [
            { id: 1, title: '2022年6月7日下午，团市委张珂一行莅临公司考察调研，公司贺经理陪同调研。领导一行依次来到公司展厅、生产车间等核心区域，了解到产品制程、公司发展、以及未来规划等相关情况，贺经理汇报了公司近年来的团委工作开展情况。' },
            { id: 2, title: '到展厅观看公司自主研发的科技产品，在贺经理的讲解下，领导一行对公司智能化设备、给予高度赞许，并希望盛泰不断提升研发实力，提高行业地位，同时张书记对公司团委工作给予了充分肯定，他们希望公司团委要继续做好服务青年、服务企业、服务社会等工作。' }
          ],
          content_3: [
            { id: 1, title: '调研人员' },
            { id: 2, title: '张珂共青团重庆市委副书记' },
            { id: 3, title: '陈雪重庆市青年创新创业服务中心党支部书记主任' },
            { id: 4, title: '郭伟重庆市青年创新创业服务中心副主任' },
            { id: 5, title: '梁琨重庆市青年创新创业服务中心综合科副科长' },
          ],
        }
      } else if (route.query.id == 10) {
        state.dataList = {
          title: '盛泰光电进入“中国创翼”创新创业大赛重庆赛区制造业专场10强',
          date: '2022-06-10',
          img_1: require('@/assets/news/10/2.jpg'),
          img_2: require('@/assets/news/10/1.jpg'),
          img_3: require('@/assets/news/10/3.jpg'),
          content_1: [
            { id: 1, title: '第五届“中国创翼"创业创新大赛重庆市选拔赛（主体赛制造业组）暨重庆市第六届“渝创渝新"创业创新大赛市级专项初赛在江北区顺利举行。' },
            { id: 2, title: '比赛期间，30个优秀项目通过精彩纷呈的比拼，角逐出了前10强进入市级决赛。' }
          ],

          content_3: [
            { id: 1, title: '从参赛项目来看，本次大赛呈现出高精尖的特点，现场，他们的精彩角逐，展现出了重庆新制造业的新气象。' },
            { id: 2, title: '由盛泰光电科技股份有限公司带来的“基于微型摄像头模组Flip chip封装技术研究与应用"项目也备受瞩目，该项目负责人周锋说:“我们的微型摄像头，最小零部件的制造精度+/-5微米，在全国同行业来说也处于第一梯队。"周峰表示，这也是公司第一次参加类似比赛，主要目的是希望能加强沟通，展示产品，相互学习，同时也把企业的新技术介绍给更多同行，实现科技企业的责任担当。' },
            { id: 3, title: '该项目负责人袁博说:“我们参加本次大赛，主要是想看看新制造业的同行们目前的技术水平和技术的发展趋势。"袁博说，希望人社部门也多多搭建这样的平台，给科技企业更多的交流机会。' },
          ],
        }
      } else if (route.query.id == 11) {
        state.dataList = {
          title: '盛泰资讯|成都市新津区税务局和商务会展局一行到访',
          date: '2022-06-25',
          img_3: require('@/assets/news/11/1.jpg'),

          content_1: [
            { id: 1, title: '6月23日下午，新津区税务局和商务会展局一行召开了“高端微型摄像头高科技"驱动高质量发展座谈交流会，盛泰董事长赵伟先生出席了交流会议。' },
            { id: 2, title: '成都市新津区税务局和商务会展局代表团参观了盛泰展厅、盛泰可靠性实验分析中心，SMT车间、EOL车间并进行座谈交流。赵伟先生对来访客人表示热烈欢迎，介绍了公司在“高端微型摄像头"领域的发展情况和未来的战略规划。' },

          ],
        }
      } else if (route.query.id == 12) {
        state.dataList = {
          title: '盛泰资讯|大足区人大常委机关离退休老干部一行莅临盛泰公司参观',
          date: '2022-06-16',
          img_1: require('@/assets/news/12/1.jpg'),
          img_2: require('@/assets/news/12/2.jpg'),
          content_1: [
            { id: 1, title: '2022年6月16日上午10点，大足区人大常委会机关离退休老干部在双桥经开区管委会周树云书记陪同下莅临盛泰参观，盛泰公司副总孙爱国先生陪同介绍。离退休老干部一行参观了公司展厅、生产车间和可靠性实验室，孙爱国副总介绍盛泰光公司发展历程、管理制度、未来发展规划，大家亲身感受着近年来大足双桥经开区高速发展以及经济腾飞带来的巨大变化。' },
          ],
          content_3: [
            { id: 1, title: '盛泰光电将着手从提升产品品质、研发新产品、增强专利、车间智能化和数字化、提高销售量等方面提升实力，努力提升在国内和全球行业中的影响力。老干部们希望盛泰在区委区政府的坚强领导下，攻坚克难，发挥自身经验优势，取得更多的成果，继续为地方经济发展和当地居民提供更多就业机会，为美丽大足幸福大足作出新的贡献。' },
          ],
        }
      } else if (route.query.id == 13) {
        state.dataList = {
          title: '盛泰资讯|盛泰光电成功跻身“2021重庆制造业民营企业100强”第28名',
          date: '2022-07-05',
          img_1: require('@/assets/news/13/1.jpg'),
          content_1: [
            { id: 1, title: '去年10月，盛泰光电科技股份有限公司成功跻身“2021重庆制造业民营企业100强"第28名。' },
            { id: 2, title: `如今，盛泰光电每月生产摄像头模组已超过2500多万颗，全球市场占有率达6.5%。大足区经信委相关负责人介绍说，"按照’一条产业链、一套领导体系、一个专家团队，一个工作方案，一个支持政策’的方针，我们围绕现代五金，农机装备等18条先进制造业产业链逐一制定实施方案，研究产业链全景图、发展路线图、升级重点工程施工图，集链成群，释放产业集聚效应。"发挥由区领导担任产业链“链长”，龙头企业担任产业链“链主”的“双链”机制，加速产链和创新链的深度融合，推动产链高度细分，从研发，生产，流通，营销各环节提升价值链。` },
          ],
          content_3: [
          ],
        }
      } else if (route.query.id == 14) {
        state.dataList = {
          title: '2022大足区新任处级领导干部一行参观我司',
          date: '2022-07-07',
          img_1: require('@/assets/news/14/1.jpg'),
          img_2: require('@/assets/news/14/2.jpg'),
          content_1: [
            { id: 1, title: '2022年7月7日，大足区新仟处级领导干部一行参观我司，盛泰光电科技股份有限公司公共关系部贺毅经理陪同接待，领导干部一行依次来到公司展厅，生产车间和可靠性实验分析中心等核心区域，了解到产品制程、公司发展、以及未来规划等相关情况，在展厅观摩了公司自主研发的科技产品，在贺经理的讲解下，领导一行对公司智能化设备，给予高度赞许，给予了充分肯定。' },
            { id: 2, title: '盛泰实验室是集产品失效异常分析，仪器校准，RA可靠性测试为一体的全方位综合实验室.发现产品在设计，材料和工艺等方面的各种缺陷，经分析和改进，使产品可靠性逐步得到增长，最终达到预定的可靠性水平。' },
            { id: 3, title: '盛泰光电将继续优化营商环境，提升产品品质，从战略和全局高度出发，增强工作的责任感、使命感和紧迫感，为全方位推动经济社会高质量发展提供坚实支撑。' },
          ],
          content_3: [
          ],
        }
      } else if (route.query.id == 15) {
        state.dataList = {
          title: '盛泰光电获“川渝聚力，创汇大足”创业创新大赛决赛第一名',
          date: '2022-07-13',
          img_1: require('@/assets/news/15/1.jpg'),
          img_3: require('@/assets/news/15/2.jpg'),
          content_1: [
            { id: 1, title: '7月13日，以“川渝聚力 创汇大足”为主题的2022年大足区创业创新大赛决赛在大足区举行。' },
            { id: 2, title: '本次大赛由重庆市大足区人民政府主办，四川省攀枝花市、泸州市、遂宁市、内江市、乐山市、宜宾市、都江堰市和重庆市大足区、荣昌区、潼南区、铜梁区、忠县等川渝“四区八市一县”人力社保局联合承办，旨在加快推进成渝地区双城经济圈建设，协同川渝“四区八市一县”实现一体化融合发展，以创业带动就业，实现更加充分更高质量就业。' },
            { id: 3, title: '本次大赛参赛项目数创新高，“四区八市一县”共有176个项目报名参赛，其中16个项目进入决赛。参赛项目行业分布广泛，覆盖农业、贸易服务、文化旅游、劳务品牌等五大类别。参赛人员类型众多，包括高校学生、退役军人、返乡创业农民工等各类创业群体。此次大赛决赛采用“6+5”现场路演的方式进行，即6分钟现场陈述、5分钟评委问答，通过专家评委现场打分，评选出一等奖、二等奖、三等奖及优胜奖。' },
          ],
          content_3: [
            { id: 1, title: '经过激烈的角逐，大足区的“基于微型摄像头模组 Flip chip 封装技术研究与应用”项目获得一等奖，大足区的“齿轨山地列车”项目与忠县的“中国粮”项目获得二等奖，潼南区的“呵护生命之源——污水治理”项目、泸州市的“焊接土工格栅一体化服务商”项目、铜梁区的“艾草全产业服务平台”项目获得三等奖，其他项目分获优胜奖。' },
            { id: 2, title: '比赛结束后进行了颁奖和川渝“四区八市一县”协同创业服务导师库揭牌、导师定向辅导签约等仪式。重庆市人力社保局、大足区人民政府、川渝“四区八市一县”人力社保局有关领导出席了仪式，并为获奖项目代表颁奖。' },
            { id: 3, title: '大足区人力社保局相关负责人表示，本次大赛对于成渝双城经济圈地区来说，不仅是一场创业创新竞赛，更是一场创业创新交流盛会。通过本次活动将逐步建立起川渝“四区八市一县”创业创新服务协同机制，纵深推进成渝地区双城经济圈建设，从而实现共建创业服务平台、共促创业人才交流、共享创业导师资源。' },
          ],
        }
      } else if (route.query.id == 16) {
        state.dataList = {
          title: '第六届“渝创渝新”创业创新大赛决赛我司项目荣获大赛制造业组三等奖',
          date: '2022-07-20',
          img_1: require('@/assets/news/16/1.jpg'),
          img_2: require('@/assets/news/16/2.jpg'),
          content_1: [
            { id: 1, title: '7月20日，第五届“中国创翼”创业创新大赛重庆市选拔赛暨重庆市第六届“渝创渝新”创业创新大赛决赛颁奖仪式举行。13个项目在决赛中脱颖而出，将代表重庆征战第五届“中国创翼”创业创新大赛。本届大赛以“近悦远来·渝创渝新--创响新时代 共圆中国梦”为主题。按照人社部统一部署，设置了制造业、服务业、劳务品牌、乡村振兴、青年创意5个赛道，共挖掘1666个优质创业项目参赛。最终，42个优质创业项目脱颖而出，进入本次市级决赛暨“中国创翼”选拔赛。' },
            { id: 2, title: '参加决赛的42个项目涉及互联网TMT、人工智能、新材料新能源、医疗健康、现代服务业、现代农业等领域，参赛选手覆盖高校毕业生、退役军人、返乡农民工、海外留学生等群体。经过激烈比拼，13个项目脱颖而出，我司项目“基于微型摄像头模组Flip chip封装技术研究与应用项目”获得三等奖。' },
            { id: 3, title: ' 据介绍，重庆市人力社保局以“渝创渝新”创业服务品牌为引领，通过实施政策助创、雏鹰培育、金融助力、服务助航、会员联动、文化厚植六大行动，让创业者们在创业路上有保障、有能力、有资金、有场地、有联盟、有平台。' },
          ],
          content_3: [
          ],
        }
      } else if (route.query.id == 17) {
        state.dataList = {
          title: '江西盛泰与英恒达成战略合作，共同推进中国智能汽车产业链发展',
          date: '2022-08-12',
          img_1: require('@/assets/news/17/1.jpg'),
          img_3: require('@/assets/news/17/2.png'),
          content_1: [
            { id: 1, title: '8月12日，江西盛泰精密光学有限公司（下称“盛泰”）二期车载智能工厂投产，并与上海英恒电子有限公司签订战略合作协议。江西盛泰二期车载智能工厂投产暨盛泰&英恒战略合作签约仪式。分宜县县长谢淘，副县长李建军、黄燕飞，分宜县工业园区党工委副书记、纪工委书记赵伍根，上海英恒电子有限公司董事长陆颖鸣、副总经理秦晨，江西盛泰董事长赵伟、CEO苏启雄、总经理李响林、副总经理赵龙效等出席。' },
          ],
          content_2: [
            { id: 1, title: " 根据协议，双方将在车载产品和和智能汽车视频等方面展开合作，打造可持续发展的战略合作伙伴关系。盛泰成为英恒集团车载产品战略合作伙伴，英恒旗下部分产品将使用盛泰提供的车载摄像头等产品。据悉，盛泰光电作为领先的摄像头模组制造企业，手机摄像头模组出货量位居全球第五，并已从手机摄像头领域延伸至车载摄像头。在签约现场，英恒副总经理秦晨展示了GDCU34B自动驾驶域控制单元，面向L2+的领航辅助驾驶应用开发，支持接入1路8MP的摄像头、9路2MP的摄像头、5路毫米波雷达以及12路超声波传感器的接入。" },
          ],
          content_3: [
            { id: 1, title: "  对江西盛泰来说，车载产品和智能汽车视频解决方案是其未来主要发展方向。在智能座舱领域，其提供DMS、OMS、DVR、娱乐摄像模组等产品；在自动驾驶/辅助驾驶领域，有1M/2M-360环视模组、2M ADAS前视一体机、8M ADAS等系列产品。其已向比亚迪、广汽、沃尔沃等车企大规模量产交付，并与长城、小鹏、蔚来、丰田等车企深入合作。二期车载智能工厂的投产，将使其有更大的产能满足客户需求。除了扩产，与英恒签约战略合作也是江西盛泰在车载领域延伸、拓展智能汽车视频解决方案的重要里程碑。" },
          ],
        }
      } else if (route.query.id == 18) {
        state.dataList = {
          title: "国际瑜伽日2022 | IDY2022 Curtain Raiser events continue in South China",
          date: "2022-06-17",
          img_1: require('@/assets/news/18/1.png'),
          img_2: require('@/assets/news/18/2.png'),
          content_1: [
            { id: 1, title: "盛泰集团（Sensetek ）瑜伽俱乐部组织了一场迎接#国际瑜伽日2022# 的活动。以下视频图片由盛泰集团提供。" }
          ],
          content_2: [
            { id: 1, title: "国际瑜伽日旨在提高全世界对于练习瑜伽可以带来诸多益处的意识。瑜伽体现了心灵和身体的统一、思想与行动的统一，这种整体方法有益于我们的健康和福祉。瑜伽不仅仅是锻炼，它是一种发现自己、世界与自然三者合为一体的方式。" },
            { id: 2, title: "盛泰集团在6月的国际瑜伽日，组织一场迎接“世界瑜伽日”的活动，旨在让员工，放松身心，感受瑜伽的带给身体和心灵的福祉，远离都市疾病困扰。" }
          ]
        }
      } else if (route.query.id == 0) {
        state.dataList = {
          title: "盛泰有约·家庭趣欢享||家庭开放日活动",
          date: "",
          img_1: require('@/assets/news/0/1.png'),
          img_2: require('@/assets/news/0/2.png'),
          img_3: require('@/assets/news/0/3.png'),
          content_1: [
            { id: 1, title: "盛泰光电迎来首个家庭开放日活动，在这新风和暖的下午，彼此与家人轻松玩乐，融洽团队和各位家人关系，增进对公司的了解，创建和谐发展的公司氛围，与家人们相聚一起，共享在这里的心路历程，与爱同在，共享美好时光。公司过往的成长依赖于所有员工的支持，更离不开所有员工家人的无私关怀每天我们从一个家来到另一个“家”，在工作中不断地践行自己最初的梦想。我们希望在未来的发展中，公司与所有的员工和家人一起共同成长。" }
          ],
          content_2: [
            { id: 1, title: " 公司一直提倡工作与健康并行，为宣扬强身健体理念，本次活动为大家带来了和谐瑜伽秀表演。通过呼吸和体位的结合，冥想并集中意识，让身体和心灵都得到锻炼。可调整人体亚健康状态，矫正体形、体态的同时还能保持心境平和，苏总也加入了本次瑜伽秀表演，和大家一起体验瑜伽的乐趣。" },
          ],
          content_3: [
            { id: 1, title: "热爱游戏几乎是每个孩子的天性。这一天，我们和孩子一起有了一次深度陪伴。家长和孩子一起度过一段美好的亲子时光。" },
            { id: 2, title: "经过一个多小时的游戏时间大家都稍显疲惫，我们为大家准备了各种面包糕点，时令水果、特色饮品供大家品尝，让大家在欢乐之余，稍作休息。" },
            { id: 3, title: "为感谢员工们的付出，公司特意为入职盛泰光电五周年、十周年员工定制纪念币，由总经理苏启雄先生颁发，并向他们表示祝贺和感谢，感谢他们对盛泰的发展做出卓越贡献和认同。" },
          ]
        }
      } else if (route.query.id == 20) {
        state.dataList = {
          title: "盛泰资讯|盛泰光电捐资助学，情暖高升莘莘学子",
          date: "2022-08-30",
          img_1: require('@/assets/news/20/1.jpg'),
          img_2: require('@/assets/news/20/2.jpg'),
          img_3: require('@/assets/news/20/3.jpg'),
          content_1: [
            { id: 1, title: "8月24日，在高升镇举行的2022年秋季捐资助学仪式上，盛泰光电科技股份有限公司和重庆双足惠科技有限公司两家企业为该镇52名贫困学子共捐助6万元，用以帮助他们顺利完成学业，助力乡村人才振兴。" }
          ],
          content_2: [
            { id: 1, title: "为确保捐赠物资能帮助到贫困学子，我司负责人在高升镇、驻该镇乡村振兴工作队相关人员陪同下，前往旭光村5组瞿锐和胜光村2组袁莹植两名贫困学生家中进行了实地走访，详细了解他们的家庭、学习以及生活情况，对他们在逆境中表现出来的积极向上、不屈不挠的拼搏精神给予充分肯定，将以实际行动帮助贫困学子渡过难关，顺利完成学业。" },
            { id: 2, title: "捐助仪式上，高升镇负责人对捐助企业表示感谢，他说：“青少年学生是十分宝贵的人才资源，是民族的希望，是祖国的未来。帮助贫困学生顺利完成学业，关系到一代人的成长，其意义重大而深远。”" },
          ],
          content_3: [
            { id: 1, title: "我司负责人代表捐助企业做了发言，企业离不开当地政府和群众的全力支持，捐资助学只是盛泰光电回报社会的一个小小举措，帮助困难学生顺利完成学业是企业应尽的义务。孩子们的健康成长是社会文明进步的重要标志，是乡村人才振兴、构建和谐社会的必然要求。社会多一分关爱，孩子就多一分信心，未来就多一分希望。" },
            { id: 2, title: "受资助的贫困学生代表对政府、企业和乡村振兴工作队表示衷心感谢，在未来的人生道路上，他们会通过顽强拼搏、跨过每道坎坷、超越每次挫折，刻苦学习，永远怀着一颗感恩的心，用青春与热情回报社会。" },
         ]
        }
      } else if (route.query.id == 19) {
        state.dataList = {
          title: "盛泰资讯|大足区经信委开展复工复产调研工作，访企业、听心声、问需求、话发展",
          date: "2022-08-30",
          img_1: require('@/assets/news/19/1.jpg'),
          content_1: [
            { id: 1, title: "2022年8月30日，大足区经信委覃勇主任一行探访盛泰光电，开展“受新冠疫情、高温天气、有序用电的复工复产调研服务工作”，深入了解企业复工复产期间需协调帮助的困难，帮助并指导企业用好“企业吹哨·部门报到”平台，企业通过平台反映当前的突出问题和瓶颈问题，政府做到“一事一议”有效回应企业诉求，确保电力恢复后产线开得起、产品产得出，把时间抢回来，把工期赶上来，把损失补回来。" },
            {id:2,title:"面对极端高温天气、有序用电限制、新冠疫情严峻等各项困难，企业响应政府号召让电于民，为灾区捐赠物资，政企一心共度难关，各级政府已出台各类惠企助企政策措施能，帮助企业解决燃眉之急."}
          ],

        }
      }else if (route.query.id == 21) {
        state.dataList = {
          title: "盛泰资讯|吉林大学重庆研究院到盛泰光科走访交流",
          date: "2022-09-06",
          img_1: require('@/assets/news/21/1.jpg'),
          img_2: require('@/assets/news/21/2.jpg'),
          content_1: [
            { id: 1, title: "为加强学校与用人单位的交流，积极为学生拓展优质的创业实践机会和更多的就业岗位，促进校企协同创新育人，不断实现人才培养与产业需求的无缝对接，9月2日上午，大足区科技局党组成员、科创中心主任唐政先生带领吉林大学重庆研究院罗怀勇副院长 、应用推广部朱雄主任来到盛泰光科进行走访调研。" },
          ],
          content_2: [
            { id: 1, title: "盛泰负责人带领来访领导参观了盛泰公司展厅、生产车间和可靠性实验分析中心，详细介绍了公司生产摄像头模组系列产品，唐政主任讲解了产学研政策，与吉林大学重庆研究院进行产学研合作交流，希望公司与学校在人才招聘、协同育人、就业创业实习实践基地等方面加强联系和合作。" },
            { id: 2, title: "学院通过与企业的走访交流，了解新形势下企业的用人需求，毕业生在企业的工作、生活和职业发展等情况，为未来校企双方在科研对接、人才培养、毕业生推荐、就业创业实践等方面深度合作打下良好的基础。校企双方将继续深化“走出去、请进来、深合作”的合作模式，积极为毕业生拓展高质量就业岗位，多渠道打造用人单位与毕业生的对接平台，充分挖掘学生潜能，创造广阔平台，力求做到“人人”成才，实现产、学、研的良性互动与校、企、生的“多方共赢”。" },
          ],

        }
      }else if (route.query.id == 22) {
        state.dataList = {
          title: "盛泰资讯|大足区组织部副部长、老干局局长张玛莉一行领导莅临我司调研",
          date: "2022-09-27",
          img_1: require('@/assets/news/22/1.jpg'),
          img_2: require('@/assets/news/22/2.jpg'),
          img_3: require('@/assets/news/22/3.jpg'),
          content_1: [
            { id: 1, title: "2022年9月26日下午，大足区组织部副部长、老干局局长张玛莉，双桥非公党建尹东洋主任及园区相关领导莅临盛泰光电调研，我司孙爱国副总经理主持接待。" },
            {id:2,title:"张局长一行认真听取了盛泰公司简介、成长经历、产品类型、终端应用等介绍，并对生产现场展开实地探访，了解生产全制程工艺及主要生产设备介绍，高端、精密产品的生产过程及严苛要求。各位领导对公司的发展给予肯定，鼓励公司克服外界因素，持续做强做大。"}
          ],
          content_2:[
          { id: 1, title: "盛泰公司的“党群活动中心”位于职工之家三楼，总面积约1000㎡，在上级党委、公司领导的大力支持下，于2022年年初完成整体装修工作，是集党建工作、员工活动于一体的活动中心。" },
          { id: 2, title: "领导一行在“党群活动中心”进行参观，检查指导党建工作，并给予高度肯定评价，表示上级党组织将不遗余力的从硬件、软件支持企业党建工作发展，助力企业持续提高党建工作质量及水平；同时指出企业开展党建工作，要让“扎根于基层，服务好基层”的理念贯彻始终，充分发挥党员先进性，有计划、有组织的展开党建工作，让更多的人参与进来，感受到党的温暖；也指示党建工作不能马虎，不搞四风之事，坚持以实事求是为原则，基层党建工作要带好方向，要仔细听，也要仔细看，了解民生意愿，积极落实处理。" },
          ],
          content_3:[
          { id: 1, title: "各位领导对公司发展的肯定，激励我们继续努力，对党建工作的叮嘱也督促着我们继续深化学习。政企良好的互动更利于公司听取意见，落到实处，助力公司变得更美好，发展壮大。" },
         ]

        }
      }else if (route.query.id == 23) {
        state.dataList = {
          title: "盛泰资讯|盛泰光电上榜2022重庆制造业企业100强",
          date: "2022-09-28",
          img_1: require('@/assets/news/23/1.jpg'),
          img_2: require('@/assets/news/23/2.jpg'),
          content_1: [
            { id: 1, title: "9月26日，由市经信委、重庆市企业联合会（企业家协会）主办的，2021年度重庆市优秀企业家表彰会暨2022重庆百强企业发布会举行，会上公布了2021年度全市40名优秀企业家及2022年度全市百强企业名单，盛泰光电科技股份有限公司上榜2022重庆制造业企业百强名单，位列第53位。" },
          ],
          content_1: [
            { id: 1, title: "近年来，盛泰光电科技股份有限公司已经快速发展成为摄像模组国内第四大企业，在车载、医疗、智能家居等非手机行业也有所突破。盛泰产品有多摄摄像头、一亿像素摄像头、超广角和潜望式摄像头等，年产能达3亿颗，摄像头占全球生产额的5%，广泛应用于oppo、vivo、华为、三星等手机产品和虹膜、车载、医疗等非手机类产品。" },
            { id: 2, title: "作为一个科技创新型企业，盛泰光电拥有专利160项，发明专利42项，获得“科技型企业”、“技术创新中心”、“双百企业”、“大足区工业20强”、“知识产权优势企业”等多项荣誉。" },
            { id: 3, title: "盛泰光电在快速成长中秉承强化中国制造、壮大民族产业的初心，不断奋勇前进，实现做强、做大、走远，做百年光电企业的愿景，立足光电行业，成为国际一流品牌客户的最佳选择。" },
          ],

        }
      }else if (route.query.id == 24) {
        state.dataList = {
          title: "",
          date: "2022-08-22",
          img_1: require('@/assets/news/24/1.png'),
          img_2: require('@/assets/news/24/2.png'),
          content_1: [
            { id: 1, title: "丰富广大员工业余文化生活，大力营造“亲近自然、健康生活、快乐工作”的和谐氛围，培养和激发广大职工的兴趣爱好，盛泰光电垂钓社团举办了2022第一届“盛泰杯”钓王大赛。钓鱼比赛场地在盛泰园区的景观湖举行，来自各部门的20余名钓鱼爱好者参加了本次活动。" },
          ],
          content_2: [
            { id: 1, title: "上午七时许，随着一声令下，钓鱼比赛正式开始。只见大家尽显高手风范，一根钓竿、几卷钓线，那恰到好处的抛竿、收杆，钓线在空中划出一道道漂亮的弧线。静待一段时光之后，一尾尾鲜活的鱼儿被利落干净的收进鱼护，伴着一阵此起彼伏的叫好声，灿烂喜悦的笑容在选手们脸上不断绽放。比赛开始不到5分钟，PE杨修惠就上鱼收获了一条近3.5斤重的草鱼，以此获得了“钓鱼最快奖”。比赛经过半个小时，大家鱼获很少，故延迟了30分钟的比赛时间，陆续有人开始上鱼，一条条大青鱼接连咬钩。钓鱼高手彭常超也不甘示弱，在接下来半个小时的时间内，连杆钓上多条2斤以上的草鱼。经过一个小时的比赛，最终EOL彭常超以鱼获总重量9.8斤获得“钓鱼最重奖”。" },
          ],
          content_3: [
            { id: 1, title: "参加活动的职工们纷纷表示，垂钓比赛不仅仅是技艺和耐力的比拼，更让大家享受了垂钓的乐趣，饱览了自然风光，陶冶了情操，为今后潜心工作，带来更多动力。" },
          ],

        }
      }else if (route.query.id == 25) {
        state.dataList = {
          title: "2022年内部讲师交流会圆满举行",
          date: "2022-09-13",
          img_1: require('@/assets/news/25/1.png'),
          img_2: require('@/assets/news/25/2.png'),
          content_1: [
            { id: 1, title: "春华秋实，金风玉露，秋风送爽，岁月如诗。又是一年人间团圆日，恰逢桃李硕果教师节。师者，传道授业解惑，如春风化雨，润物无声。企业内部讲师，作为企业自生的师资力量，为企业注入传递智慧的血液。人才的发展与培养离不开公司的栽培，也离不开讲师的辛勤付出。为了感谢在公司发展中不吝赐教的园丁，9月8日下午，人力资源部组织开展了2022年内部讲师交流会，答谢师恩，活动邀请了2021年和2022年内部选聘的31位讲师以及相关领导嘉宾参与。" },
            { id: 2, title: "盛泰内部讲师总教练Tony老师在线上为所有讲师带来了教师节寄语，感谢讲师们为盛泰内部培训事业做出的贡献，也期望他们能一如既往的充当好教练的职责，为学员分享更多的知识与经验。" },
          ],
          content_2: [
            { id: 1, title: "在2021年10月以及2022年5月，人力资源部组织了TTT讲师训以及试讲考核评选，选拔出来了30余位内部讲师们，在今年教师节即将来临之际，运营部梅总监和研发部袁总监为讲师们颁发了聘书以及教师节小礼品，以此表示公司对内部讲师的寄托与感恩。" },
            { id: 2, title: " 要成为一位优秀的讲师，需要不断学习与提高自己的授课技巧，在内部讲师交流会上，大家分成3个小组以企业文化为主题进行讨论，在20分钟的时间里，需要讨论出课程的框架以及课堂中应该用到的教学技巧与方法。经过各小组激烈的讨论，选出了3位代表进行试讲，他们展示了团队合作的成果，对企业文化里面的行为准则、企业精神进行了课堂展示，让我们看到了不同的教学方法与课堂设计，也看到了各位讲师积极、用心的态度。" },
          ],
          content_3: [
            { id: 1, title: "我们一样来自远方，做着普通的工作，在外漂泊思乡，因为缘分，让我们成为同事，因为职责，让我们成为讲师，我们相聚在盛泰，同为一家人，为了共同的目标与心声，一起奋斗前行。在双节到来之际，让我们致敬所有的内部讲师，感谢他们甘愿分享自己的经验与技巧，期待大家能一直以企业讲师的身份，承担起对企业内部传播知识的责任，让盛泰大学堂栉风沐雨，顾往追新，海纳百川、蒸蒸日上，培养更多内部人才，共同打造盛泰更加美好的未来。" },
          ],
        }
      }else if (route.query.id == 26) {
        state.dataList = {
          title: "江西省人大常委会副主任张小平来江西盛泰精密光学有限公司开展调研",
          date: "2022-10-15",
          img_1: require('@/assets/news/26/1.png'),
          content_1: [
            { id: 1, title: "10月9日，省人大常委会副主任张小平来我司调研，省人大环资委主任委员吴治云，省应急厅、省工信厅、省能源局等部门有关负责同志参加调研。市领导徐鸿、赖国根、马小红、舒永忠、张晓明，县领导邹家洪、谢淘、胡瑜瑞、罗艳兵陪同调研。江西盛泰精密光学有限公司总经理李响林陪同接待。" },
            { id: 2, title: "张小平对今年以来园区和我县经济社会发展所取得的成绩予以充分肯定，对省直相关部门给予园区及企业的帮助和支持表示感谢。他强调，分宜县要深入学习贯彻党中央“疫情要防住、经济要稳住、发展要安全”重要要求，认真落实全省稳增长、防风险、保稳定、惠民生工作部署会议精神，保持“咚咚响、嗷嗷叫”的精气神，以更强的责任担当和创先争优的浓厚氛围，确保全年各项目标任务圆满收官。要坚持大抓项目、抓大项目，持续加强与上级部门沟通对接，全力以赴攻坚克难，推动项目早日投产见效，为经济社会高质量发展提供有力支撑。要守牢安全生产、疫情防控、抗旱救灾、社会稳定底线，抓紧抓实抓好稳增长、防风险、保稳定、惠民生等各项工作，以优异成绩迎接党的二十大胜利召开。县领导李建军、黄燕飞陪同调研。" },
          ],


        }
      }else if (route.query.id == 28) {
        state.dataList = {
          title: "创新是第一动力 人才是第一资源",
          date: "2022-10-27",
          img_1: require('@/assets/news/28/2.png'),
          img_2: require('@/assets/news/28/1.png'),
          content_1: [
            { id: 1, title: "中国共产党第二十次全国代表大会于2022年10月16日在北京召开。习近平总书记代表第十九届中央委员会向党的二十大作报告。盛泰光电广大党员干部组织观看了党的二十大会议开幕式，公司上下深受鼓舞，纷纷表示将踔厉奋发、勇毅前行，在新征程上展现新作为。" },
            { id: 2, title: "盛泰光电科技股份有限公司，目前已快速发展成为摄像模组国内第四大企业，年产能达3亿颗各类摄像头，公司整合资源集中优势，再次出击新产品、新市场、新领域；研发并顺利量产了1亿像素和2亿像素等高像素摄像头、倒装工艺摄像头，车载、笔记本摄像头以及智能物联网摄像头等产品，盛泰光电产品在不断迭代的当下，保持着全球出货量份额6.5%，总产值40亿元。" },
          ],
          content_2: [
            { id: 1, title: "研发部总监谢演军介绍说，1亿、2亿像素摄像头可以极大限度地把拍摄物拍得更清晰，呈现更丰富的细节；倒装工艺让摄像头变得更小更薄，极大地满足了客户对于边框更窄、屏幕更大的极致追求；车载、笔记本摄像头以及智能物联网摄像头等产品很好地迎合了蓬勃发展的视讯会议、新能源汽车、智慧汽车、智能家居等市场需求。党的二十大报告提出要集聚力量进行原创性引领性科技攻关，坚决打赢关键核心技术攻坚战，我们有信心有决心，不断攻坚克难，插上创新和研发这对翅膀，飞出“加速度”。" },
            { id: 2, title: "党的二十大报告中提出，必须坚持科技是第一生产力、人才是第一资源、创新是第一动力，深入实施科教兴国战略、人才强国战略、创新驱动发展战略，开辟发展新领域新赛道，不断塑造发展新动能新优势，将创新放在更加重要的位置，这让公司上下倍感鼓舞、催人奋进。大家纷纷表示，将以党的二十大胜利召开为契机，以更加饱满的热情投入到产品研发创新、提升工艺技术的工作中去；将继续秉承强化中国制造、壮大民族产业的初心，不断奋勇前进，实现做强、做大、走远。" },
          ],

        }
      }else if (route.query.id == 29) {
        state.dataList = {
          title: "盛泰光电举办“凝心聚力，犇向新征程”拔河比赛圆满结束",
          date: "2022-11-15",
          img_1: require('@/assets/news/29/1.png'),
          content_1: [
            { id: 1, title: "当哨声在耳畔响起，当手臂在眼前挥下，一根绳，两队人，怀着相同的目标，朝着努力的方向，奋力而冲。" },
            { id: 2, title: "为培育“团结、互助、和谐、温馨”的工作团体，  营造活跃的企业文化氛围，引领公司员工凝心聚力，发扬团队精神，使参与人员结合集体的力量，提高团队合作和坚韧不拔的精神。公司举办了2022年盛泰光电”凝心聚力，犇向新征程“拔河比赛。" },
          ],
          content_2: [
            { id: 1, title: "比赛开始前，裁判员做赛前动员并宣读比赛规则。随后，各参赛小组按抽签顺序依次进行比赛。比赛期间，各参赛队队员脚蹬脚、肩靠肩，压低重心，卯足劲往一处使，将集体荣誉感和团队协作精神展现得淋漓尽致。赛场上加油声、呐喊声、喝彩声响成一片，整个比赛高潮迭起、紧张激烈，赛出了风格，赛出了友谊。FOL组作为今年的黑马赢得了本届的冠军，品质部组夺得了亚军，移动终端事业部研发+财务中心组获得了季军。" },
            { id: 2, title: "赛后，参赛队员纷纷表示，此次活动即放松了身心，锤炼了意志品格，也将会以此次拔河比赛为契机，把在比赛中的团结协作、争先创优、顽强拼搏的精神带入到日常工作中去，以后各项工作中再创佳绩。" },
            { id: 3, title: "一根绳，一条心，迸发的是身体的力量，拉近的是心与心的距离，此次拔河比赛充分展现了团结协作的精神，希望在接下来的工作和生活中大家都能秉持这种精神，继续凝心聚力，勇敢前行！" },
          ],

        }
      }else if (route.query.id == 30) {
        state.dataList = {
          title: "大足 盛泰光电入选国家知识产权优势企业",
          date: "2022-11-24",
          img_1: require('@/assets/news/30/1.png'),
          content_1: [
            { id: 1, title: "11月24日国家知识产权局公布了2022年新认定国家级知识产权优势企业名单，盛泰光电科技股份有限公司成功入选，榜上有名。" },
            { id: 2, title: "近年来，盛泰光电科技股份有限公司已经快速发展成为摄像模组国内第四大企业，在车载、医疗、智能家居等非手机行业也有所突破。盛泰产品有多摄摄像头、一亿像素摄像头、超广角和潜望式摄像头等，年产能达3亿颗，摄像头占全球生产额的5%，广泛应用于oppo、vivo、华为、三星等手机产品和虹膜、车载、医疗等非手机类产品。" },
          ],
          content_2: [
            { id: 1, title: "作为一个科技创新型企业，盛泰光电拥有专利160项，发明专利42项，获得“科技型企业”、“技术创新中心”、“双百企业”、“大足区工业20强”、“知识产权优势企业”等多项荣誉。" },
            { id: 2, title: "据悉，“国家知识产权优势企业”是国家知识产权局对认定企业在知识产权管理领域获得国家认可，具备自主知识产权能力，积极开展知识产权保护和运用，建立全面的知识产权管理制度和机制，具有知识产权综合实力的企业。近年来，大足区市场监督管理局（知识产权局）贯彻落实《重庆市知识产权保护和运用“十四五”规划（2021-2025年）》和《重庆市高价值发明专利质量提升行动方案（2022-2024年）》，积极发挥知识产权管理职能，引导企业提升知识产权创造、运用、保护和管理水平，着力打造知识产权强企建设方阵，并推进以价值实现为导向的成果转化。" },
            { id: 3, title: "盛泰光电在快速成长中秉承强化中国制造、壮大民族产业的初心，不断奋勇前进，实现做强、做大、走远，做百年光电企业的愿景，立足光电行业，成为国际一流品牌客户的最佳选择。" },
          ],

        }
      }else if (route.query.id == 31) {
        state.dataList = {
          title: "同心“战”疫，共克时艰|爱护宿舍环境，从你我做起",
          date: "2022-11-26",
          img_1: require('@/assets/news/31/1.png'),
          content_1: [
            { id: 1, title: " 宿舍和园区环境卫生和我们每个人都息息相关，所以我们在此倡议大家请把垃圾扔进垃圾袋或者垃圾箱里，垃圾事小，乱扔事大，您随手丢弃的一团纸巾，一块橘子皮，都会是在美丽园区的环境上的污点，就像美丽的衣衫粘上了草渣泥点，同样也给辛苦维护的的保洁人员增加额外工作。新宿舍新环境，大家注意保持，爱护家园从自己做起，让我们生活环境更加整洁美好。" },
            { id: 2, title: " “疫”字当头，同心协力，维护宿舍环境干净整洁成为你我共同的责任。我们每个人都要重视起来，做好个人防护工作，让病毒无处遁形，共同等待疫情结束！" },
          ],
        }
      }else if (route.query.id == 32) {
        state.dataList = {
          title: "青春无畏·逐梦扬威，盛泰光电2022年冬季职工篮球赛圆满结束",
          date: "2022-11-27",
          img_1: require('@/assets/news/32/1.png'),
          content_1: [
            { id: 1, title: "  “矫健身法迈步冲，盛泰男篮飒英风。”为丰富职工的业余生活，锻炼强健的体魄，增强各部门的凝聚力，激发职工的拼搏精神与竞技精神，盛泰光电于11月25日举办了“青春无畏·逐梦扬威”主题篮球赛，本次比赛有18名队员参加，通过积分制决出比赛胜负。" },
            { id: 2, title: "随着哨声响起，宣告了本次篮球赛正式拉开帷幕，红队和蓝队两支队伍对战球场，各位队员都全力以赴，积极防守，愈战愈勇，精妙的传球、漂亮的配合、飘逸的投篮、灵活的脚步，无一不收获了场下阵阵欢呼。赛场上的运动健儿你追我赶，紧咬比分。" },
            { id: 3, title: "跟随镜头来到比赛下半场，双方运动员迅速进入状态，以矫健熟练的动作迅速传球，直逼篮板。各位队员配合默契，抢断进取，比分僵持不下，在场观众无不为之热血沸腾，喝彩呐喊。最终，红队以3分之差率先赢得了比赛。" },
          ],

        }
      }else if (route.query.id == 33) {
        state.dataList = {
          title: "同心抗“疫”，感谢有你----致奋战在盛泰光电疫情防控一线全体勇士的感谢信",
          date: "2022-11-29",
          img_1: require('@/assets/news/33/1.png'),
          img_2: require('@/assets/news/33/2.png'),
          content_1: [
            { id: 1, title: "微光成炬，勇毅前行。面对突如袭来的疫情，大家闻令而动，主动请缨，冲锋一线，踊跃投身于疫情防控最前沿，用实际行动构筑起疫情防控的铜墙铁壁，充分展示了“团结奋斗、共克时艰”的盛泰精神。" },
            { id: 2, title: "身着防护服，手持采样管，医护人员冒着秋冬的冷风坚守一线，有条不紊地开展采样工作，勇做抗疫斗争的“先遣队”他们用行动诠释着白衣天使的责任与担当。" },
            { id: 3, title: "坚持外防输入，严把重点关口，公司安保人员驻守南门岗等重点区域，公共事务室负责在高速接送外来货物，严格查验过往车辆和司乘人员信息，做好防疫消毒工作，坚持做到不漏一车，不漏一人，从严从紧筑牢外防输入防线。" },
          ],
          content_2: [
            { id: 1, title: "疫情来得突然，生产不能停，职工的孩子也需要照顾，公司为有需求的职工家庭提供场地，保障职工驻场期间能兼顾孩子的生活起居和学习，把职工的需求放在第一位。特别感谢品质部、运营部、NB、供应链管理部、行政部这些单位的支援。" },
            { id: 2, title: "井然有序的“一米线”，主动出示的“健康码”，盛泰光电的职工们用自己的实际行动默默支持、无言付出，你们的理解和配合，就是我们工作的最大动力。" },
            { id: 3, title: "各部门落实保障生产所需的返厂人数，行政部紧急协调住宿和生活必需品，切实做好返厂人员的食宿保障工作，此外，疫情期间一日三餐的物资采购成为一大难题，公司餐厅主动出击，多方联系供应商，保证米面油菜肉蛋等必需物资供应，保证采购供应不断档，司机和保洁人员等行政部人员也在用餐高峰期到食堂进行支援，保障职工用餐有序进行。" },
          ],
          content_3: [
            { id: 1, title: "众志成城，高山可平；勠力同心，难关必克。广大的盛泰工作人员们，你们在战疫中挥洒的每一滴汗水、付出的每一份努力，我们会铭记在心，感恩于怀！最后，衷心祝愿你们和家人身体健康、工作顺利、阖家幸福、万事如意!" },
            ],
        }
      }else if (route.query.id == 34) {
        state.dataList = {
          title: "盛泰活动|守护双桥生态·绿色环保盛泰行 捡拾活动顺利举行",
          date: "2022-12-5",
          img_1: require('@/assets/news/34/1.png'),
          content_1: [
            { id: 1, title: "为进一步增强大家生态环境保护意识，动员全公司积极参与生态文明建设，树立不乱丢垃圾、保护生态环境的意识，巩固文明城市创建成果，共同建设美丽中国，盛泰光电科技股份有限公司开展了公益捡垃圾的公益活动，用实际行动倡导着文明理念“守护双桥生态，绿色环保盛泰行”。" },
          ],
          content_2: [
            { id: 1, title: "为进一步增强大家生态环境保护意识，动员全公司积极参与生态文明建设，树立不乱丢垃圾、保护生态环境的意识，巩固文明城市创建成果，共同建设美丽中国，盛泰光电科技股份有限公司开展了公益捡垃圾的公益活动，用实际行动倡导着文明理念“守护双桥生态，绿色环保盛泰行”。" },
            { id: 2, title: "在温暖阳光的照耀下，志愿者的身影成了一道亮丽的风景线。 " },
            { id: 3, title: "经过志愿者们的地毯式搜索，所到之处被拾捡得干干净净。集合时，每个人的手中都提着满满的一袋垃圾，经过志愿者们的辛勤劳动，个个战果累累。虽然都气喘吁吁，满头大汗，但没有一个人喊累，大家纷纷表示，能为环保事业做出自己的一份贡献，是十分乐意的事情。期待与你相约，我们下一次的活动。" },
          ],
        }
      }else if (route.query.id == 35) {
        state.dataList = {
          title: "秋招季|高校宣讲会圆满收官，场场精彩",
          date: "2022-12-22",
          img_1: require('@/assets/news/35/1.png'),
          content_1: [
            { id: 1, title: "2022的求职季如期而至，“求贤访才，虚位以待，成就梦想，“职”等你来！”成为金秋的招聘旋律，一起来回顾盛泰光电科技股份有限公司的校招现场吧！" },
            { id: 2, title: "经过前期紧张的筹备，2022年11月-12月，我们进行了四场校园招聘会：分别为重庆工程学院线下专场招聘会，重庆师范大学线上专场宣讲会，重庆高校联合线上宣讲会：包含重庆工商大学、重庆第二师范学院、重庆科技学院、重庆大学、重庆三峡学院，重庆电信职业学院线上专场宣讲会。" },
          ],
          content_2: [
            { id: 1, title: "首发站为重庆工程学院，招聘现场座无虚席，同学们热情高涨，为我们秋季校招开了个好头！尽管后期受疫情影响，只能通过线上的方式进行，但同学们求职的热情依旧不减，我们的校招最终取得了圆满成功。本次面向2023届毕业生秋季校招，我司共计收到简历210余份，经过初步面试，有40名同学进入复试阶段，又经公司高层领导、用人部门一对一面试后，最终有15名优秀应届生被我司录用！对比去年，除了之前紧密合作的重庆工程学院外，我们不断提升今年合作招聘目标院校，今年校招的重点主要是重庆本地二本及以上的高等院校，这也更符合我司未来业务发展需要，以及技术和管理团队对于人才的需求，为我司人才培养奠定了坚实基础。" },
            { id: 2, title: "秋季校招为我司未来发展注入了新鲜血液，增添了新的动力源，我们期待这群年轻人的到来，以及这些人带来的新的活力，海阔凭鱼跃，天高任鸟飞，愿他们在盛泰这片沃土上，学有所成，终有所长！" },
          ],
        }
      }
      else if (route.query.id == 36) {
        state.dataList = {
          title: "铆劲抓生产·打好“收官战”",
          date: "2022-12-22",
          img_1: require('@/assets/news/36/1.png'),
          content_1: [
            { id: 1, title: "在盛泰光电科技股份有限公司生产车间，技术工人们正有条不紊地忙着手中的工作，COB芯片封装线等各道工序生产线高效运转，快马加鞭赶制订单。年关将至，从11月中旬开始，盛泰光电迎来订单量的“小高峰”。" },
          ],
          content_2: [
            { id: 1, title: "作为国内第一批手机摄像头模组制造商，盛泰光电专注CCM摄像模组的研发、制造、销售、服务，产品广泛应用于手机、平板、电脑、数码相机、汽车、医疗以及智能家居、智慧城市、航天航空等领域。" },
            { id: 2, title: " “我们目前的订单量在今年整个年度来讲，处于一个次高峰的状态，仅12月就接了不少的产品订单。”公司运营部总监梅龙龙说。为了抓生产、保订单，盛泰光电在做好疫情防控的同时，精细组织生产，全面梳理业务订单，合理排产，确保各产品、项目等生产任务按期保质保量完成，奋力冲刺全年各项目标任务。“现在单日产量可以达到50万颗。”梅龙龙说，订单小高峰将一直持续到明年1月上旬，接下来，公司还将通过招聘技术工人等措施，进一步提升产能。全国疫情防控政策调整，公司将继续开足马力、保质保量抓生产，同时，全力做好服务保障工作，稳产满产。" },
          ],
        }
      }
      else if (route.query.id == 37) {
        state.dataList = {
          title: "与客户共谋可持续高质量发展，盛泰再次荣获传音控股“最具价值合作伙伴 “奖",
          date: "2023-1-8",
          img_1: require('@/assets/news/37/1.png'),
          content_1: [
            { id: 1, title: "近日，手机界的“非洲之王”传音控股为盛泰光电颁发“2022最具价值合作伙伴“奖，盛泰光电已成为连续两年获得该项殊荣的唯一摄像头模组供应商，这代表了传音控股对盛泰光电的高度认可。" },
            { id: 2, title: "作为传音控股摄像头领域的战略合作伙伴，自双方合作开展以来， 盛泰始终秉承“立足光电行业、成为国际一流品牌客户的最佳选择”的企业使命,践行“以奋斗者为本、以客户为核心、以品质作基础、靠技术和研发永续发展”的行为准则,持续为客户全面赋能，致力成为客户最具价值的合作伙伴。盛泰光电生产的4800万像素、5000万像素、6400万像素、1亿像素等高阶模组已在传音控股中高阶机型批量应用，为产品打造优良的性能优势，提升消费者的拍摄体验。" },],
          content_2: [
            { id: 1, title: "成为客户的最佳选择是盛泰人不变的初心。客户的信任和支持是对盛泰最大的认可，也是盛泰持续为客户创新发展赋能的重要动力源泉。2023年将坚守初心、砥砺前行，持续为影像需求客户赋能，为客户提供影像一体化系统解决方案，与客户共谋可持续高质量发展，共创光明未来，共筑长青基业。" },
            
          ],
        }
      }
      else if (route.query.id == 40) {
        state.dataList = {
          title: "盛泰光电荣获中兴通讯“2022年度质量优秀奖 ”",
          date: "2023-1-12",
          img_1: require('@/assets/news/40/1.png'),
          content_1: [
            { id: 1, title: "1月11日，全球领先的ICT综合通信与信息技术解决方案提供商中兴通讯股份有限公司为盛泰光电颁发“2022年度质量优秀奖“，中兴通讯已连续多年为盛泰颁发此奖项，代表了中兴通讯对盛泰光电在产品质量方面的高度肯定。" },
          ],
          content_2: [
            { id: 1, title: "作为中兴通讯摄像头模组的长期合作伙伴， 盛泰始终坚持以“质量第一,信誉至上,诚信为本,服务客户”为企业宗旨，持续为客户提供优质的产品。正是基于对盛泰质量把控能力的高度认可，盛泰生产的一亿像素超清摄像头模组顺利“进驻”中兴旗舰机。质量之魂,存于匠心，盛泰始终秉持“质量是企业的立身之本、发展之基，更是信誉之源“的质量理念，在公司内部实施全面质量管理，建立了完善的质量保证体系，盛泰品质已赢得业界主流客户的高度认可。" },
            { id: 2, title: " 未来，盛泰人将继续坚守品质为先，坚持守正创新，狠抓精细管理，促进提质增效再升级，为客户提供更高质量产品，助力客户高质量可持续发展。" },
          ],
        }
      }
      else if (route.query.id == 41) {
        state.dataList = {
          title: "技术赋能，匠心品质，实力呈现--盛泰光电荣获麦博韦尔双料大奖",
          date: "2023-1-13",
          img_1: require('@/assets/news/41/1.png'),
          img_2: require('@/assets/news/41/2.png'),
          content_1: [
            { id: 1, title: " 2023年1月12日，行业知名移动电话制造商宁波麦博韦尔移动电话有限公司为盛泰光电颁发了“2022年度优秀技术支持奖“及”2022年度优秀质量奖“两个重量级的奖项。盛泰连续多年斩获麦博“年度优秀质量奖后”，新添“优秀技术支持奖”，代表双方合作更进一步，也是对盛泰最好的肯定和鼓励，感谢麦博对盛泰的长期信任与支持。" },
          ],
          content_2: [
            { id: 1, title: " 作为麦博摄像头领域的长期合作供应商，盛泰光电将一如既往践行“以奋斗者为本，以客户为核心，以品质作基础，靠技术和研发永续发展“行业准则，用心做技术，以质量赢客户，用高质量的产品及服务、为客户创造出更高的价值，为客户可持续高质量发展赋能。未来，双方将继续携手同行，同心筑盟，共谋发展，砥砺奋进，共赢得未来。" },
          ],
          content_3: [
            { id: 1, title: "宁波麦博韦尔移动电话有限公司是一家专业从事移动电话及电子产品研发、生产、销售、服务的外商独资企业。麦博韦尔移动电话总投资为7500多万美元，拥有国际20条先进的SMT生产线和30条装配线。主厂房占地22000平方米；拥有高级管理和技术人员400多人，总员工数近2000人。并在上海和宁波分别设立移动电话研发中心，在深圳设立营销采购中心。公司手机年产量超过2000万台，年产值突破13亿!" },
            { id: 2, title: " 麦博韦尔是麦博韦尔移动电话的全球生产基地，累计生产移动电话8000余万台，产品销售到全球几十个国家，同时与国际著名网络运营商沃达丰、AT&T、法国电信、Orange、T-Mobile、TIM、墨西哥电信、菲律宾电信等强强合作，为以上运营商提供国际品质的产品，并提供定制手机的生产服务。" },
          ],
        }
      }
      else if (route.query.id == 42) {
        state.dataList = {
          title: "卯兔迎新 新岁启封 | 盛泰光电新年礼品已送达",
          date: "2023-1-14",
          img_1: require('@/assets/news/42/1.png'),
          img_2: require('@/assets/news/42/2.png'),
          content_1: [
            { id: 1, title: "金虎辞岁寒风尽，玉兔迎春喜气来。我们告别了卓尔不凡的2022，迎来了充满希望的2023，在这辞旧迎新的时刻，为感谢全体员工一年来的辛勤付出与贡献，公司为所有的员工准备了新年礼品。" },
            { id: 2, title: "一转眼2022年就结束啦！去年立下的FLAG完成了吗?新的一年到了，愿你在新的一年里，能遇见更好自己，旦愿美好在，岁月常如新，新年新气象。" },
          ],
          content_2: [
            { id: 1, title: " 每逢佳节，盛泰光电永远比别人做的多一点。新年礼品是公司对员工及家属的关心与祝福，礼品捧在员工的手里却暖在心里，公司上下洋溢着一片温馨和谐的氛围。迎新春，贴福字，盛泰与员工共同送上新年祝福。春节是中华民族祈福迎祥的传统节日，更是弘扬中华民族优秀传统文化的良好形式。为营造欢乐、和谐、的节日气氛，公司行政部开始 “迎新春、贴福字”。" },
           ],
          content_3: [
            { id: 1, title: " 在这辞旧迎新的时刻，盛泰光电科技股份有限公司向全体员工及家属和所有新老客户及合作伙伴，拜个早年，恭祝大家新年快乐，身体健康，万事如意，阖家欢乐！ “迎新春、贴福字”。" },
           ],
        }
      }
      else if (route.query.id == 43) {
        state.dataList = {
          title: "【盛泰资讯】慰问台胞送温暖 两岸联谊有温情",
          date: "2023-1-15",
          img_1: require('@/assets/news/43/1.png'),
          content_1: [
            { id: 1, title: "每逢佳节倍思亲，两岸同胞一家亲，1月14日，在新春佳节即将到来之际，大足区委统战部副部长、区委台湾工作办公室主任范树华带队组织慰问组，来到盛泰光电科技股份有限公司开展走访慰问台胞台属活动，为台胞台属送去新春祝福和节日问候，同时也送上了新春礼物。慰问组范主任与盛泰光电科技股份有限公司台胞负责人进行交流，详细询问了台胞台属在大足区的生活状况。" },
          ],
          content_2: [
            { id: 1, title: "范树华主任与盛泰光电台胞负责人交流谈到，在今后的工作中，大足区委统战部、区台办、将继续努力建设一个台胞台属温暖的家园，积极协调辖区文化、经济资源，在巩固现有成绩的基础上，搭建更多学习交流、联谊活动平台，开展更多有内涵有深度的活动，在筑牢思想阵地、广聚共识的同时，为大足区广大台胞台属办实事、送温暖，把“两岸一家亲”深深刻入两岸同胞的血脉之中。" },
            ],
        }
      }
      else if (route.query.id == 44) {
        state.dataList = {
          title: "人大代表实地走访企业 助力企业发展",
          date: "2023-2-3",
          img_1: require('@/assets/news/44/1.png'),
          content_1: [
            { id: 1, title: "为切实了解企业需求，协调化解企业困难，营造服务企业的营商环境，2月2日，大足区人大常委会和大足区政协委员组织辖区人大代表近400人深入企业，开展集中视察重点项目建设，人大代表实地走访调研，积极出谋划策，助力企业更好发展。" },
          ],
          content_2: [
            { id: 1, title: "大足区人大代表通过现场参观，深入了解企业生产经营及今后发展计划等情况，并就产业升级、科技创新、人才团队建设等方面的问题，进行了交流和探讨；在走访过程中，代表询问了企业面临的问题和困难，针对企业在当前生产经营过程中亟需相关部门协调解决的有关事宜，并结合实际情况提出了指导建议。" },
            ],
          content_3: [
            { id: 1, title: "大足区人大代表表示会积极履职尽责，进一步发挥人大代表联系广泛的优势，深入商圈、企业、社区，深入基层一线进行走访。面对时艰，将进一步深入调研企业经营发展面临的突出问题和生产生活面临的实际困难，更好地倾听民声、反映民意、汇集民智，积极建言献策。大足区将打造人大代表特色履职平台，深入辖区企业，架起沟通桥梁，通过倾听民意、服务企业，助力工作落到实处，为经济发展贡献力量。" },
            ],
        }
      }
      else if (route.query.id == 45) {
        state.dataList = {
          title: "精准对接，“爱心托管”解民忧",
          date: "2023-2-6",
          img_1: require('@/assets/news/45/1.png'),
          content_1: [
            { id: 1, title: "为深学笃用习近平新时代中国特色社会主义思想，落实“双减”政策要求，满足广大员工需求，助力公司发展，凝聚企业人心，盛泰公司团委响应团区委于2023年2月1日-3日开展为期三天的公益看护、课业辅导、兴趣培养、文化教育等托管公益服务。" },
          ],
          content_2: [
            { id: 1, title: "为将“爱心托管班”这件好事办好，实事办实，团区委组织下派3名大学毕业生兼职老师助力开展，从签到到安排课程、课间陪伴、接送孩子，小课堂每天都在团员青年的带领下顺利运转，真正做到让学生开心，让家长放心。" },
            ],
          content_3: [
            { id: 1, title: "托管班虽短短三天，但课程内容丰富多彩。有小蜜蜂趣味绘画，引导小朋友们学习蜜蜂辛勤劳作、无私奉献的品格；有益智益脑的围棋、魔方、竞技竞赛，从中锻炼孩子们的脑力，考验思维的敏捷性，并让他们在合作和竞争中发展团队精神和学习与人分享的习惯；有课业辅导，耐心解答孩子们不会的知识点；还有在紧张的学习之余，进行跳绳等体育运动，促进身体健康、充分发展身体素质，让学生们在身体和精神上都有良好的发展。" },
            ],
          content_4: [
            { id: 1, title: "下一步，盛泰公司团委将进一步贯彻落实上级团组织工作安排，做青年朋友的知心人、青年工作的热心人，建好用好团属青年活动阵地，延伸服务触角，创新服务项目，拓展青春建功平台，在服务大局中体现青年的责任担当。" },
            ],
        }
      }
      else if (route.query.id == 46) {
        state.dataList = {
          title: "盛泰光电荣获“民营企业十强”和“优秀民营企业家”两项殊荣",
          date: "2023-3-4",
          img_1: require('@/assets/news/46/1.png'),
          content_1: [
            { id: 1, title: "3月2日上午大足区委副书记、区长徐晓勇主持《推动民营经济高质量发展暨优化营商环境大会》，以一城之名，向企业家们致最高礼遇，给与优秀企业家表彰鼓励和崇高荣誉。" },
          ],
          content_2: [
            { id: 1, title: "区委书记陈一清出席会议讲话并强调：打造一流营商环境，全力推动新时代大足民营经济实现新发展，加快做靓享誉世界的文化会客厅、建强链接成渝的“两高”桥头堡。大足区人大常委会主任王志，区政协主席廖文丽，区委副书记罗晓春和区委常委和相关市管领导悉数出席。" },
            ],
          content_3: [
            { id: 1, title: "大会上，盛泰光电赵伟董事长被评为大足区2022年度'优秀民营企业家'、盛泰光电科技股份有限公司被评为“民营企业十强”，企业家代表们在发言中表示：大足是投资兴业的热土，企业发展的春天已经来临。盛泰光电也一定会把握好区委、区政府创设的优越营商环境，奋勇拼搏赢未来，为大足经济社会发展作出新的贡献。" },
            ],
        }
      }
      else if (route.query.id == 47) {
        state.dataList = {
          title: "联通（重庆）5G融合创新中心领导莅临盛泰考察",
          date: "2023-3-17",
          img_1: require('@/assets/news/47/1.png'),
          content_1: [
            { id: 1, title: "2023年3月15日上午，中国联通指导军团军团长，联通（重庆）5G融合创新中心总经理，重庆市人大代表周华领导等一行莅临我司考察，盛泰公司总经理苏总进行了热情的接待。" },
          ],
          content_2: [
            { id: 1, title: "苏总带领周总一行参观公司展厅、生产车间和可靠性实验室，同时介绍了公司的发展历程、管理制度以及发展规划，后续公司将持续进行数字化建设，打造智能化车间，增加专利投入，不断研发创新，努力提升在国内和全球行业中的排名。周总认真听取苏总介绍，并进行了深入交流，他鼓励盛泰公司在区委区政府的领导下，能够攻坚克难，继续发挥自身经验优势，取得更多的成果。" },
            ],
          content_3: [
            { id: 1, title: "盛泰公司在各级各部门领导的关心厚爱中，将秉持初心，继续为当地经济发展发力，提供更多的就业机会，为建设双桥、建设大足作出更大的社会贡献。" },
            ],
        }
      }
      else if (route.query.id == 48) {
        state.dataList = {
          title: "盛泰光电受邀参加高通计算生态峰会",
          date: "2023-3-18",
          img_1: require('@/assets/news/48/1.png'),
          content_1: [
            { id: 1, title: "3月14日，由高通（Qualcomm）主办的骁龙计算生态峰会在台北市敦华南路二段香格里拉大饭店举行，主办方高通科技公司邀请了生态系合作伙伴和产业分析师，针对快速演变的行动运算领域进行了分析与讨论，以掌握产业关键趋势与机会，盛泰光电受邀参加此次峰会。" },
          ],
          content_2: [
            { id: 1, title: "会议上，多位高通主管与PC产业领导者，围绕生态系最新创新应用发展的主题进行了分享，主题涵盖运算平台的成长动能、装置上AI如何为产业带来突破等当下科技热点话题。" },
            ],
          content_3: [
            { id: 1, title: "盛泰光电研发副总经理林恭安先生进行了“高通相机模组合作与TaaS（ Tuning as a Service）合作计划”的经验分享。盛泰光电作为专业从事精密摄像头模组设计、研发、制造和销售的高科技企业，未来发展策略是向影像技术硬件制造和软件服务领域持续不断延伸，努力发展成为一个以科技创新为成长动力的企业。" },
            ],
        }
      }
      else if (route.query.id == 49) {
        state.dataList = {
          title: "建绿色工厂 树绿色理想” 盛泰植树周活动",
          date: "2023-3-20",
          img_1: require('@/assets/news/49/1.png'),
          content_1: [
            { id: 1, title: "三月万物生长，春风轻抚大地，又到了一年一度的植树季，春天的播种，是一年努力的开始，让我们种下希望的树苗，期待未来可以感受生机勃勃的盎然绿意。为此，公司组织为期一周的植树活动，鼓励大家一起参与进来，携手共建美丽盛泰，绿色双桥。" },
          ],
          content_2: [
            { id: 1, title: "秉承公司一直以来“共建美好家园，营造绿色工厂”的理念，提升员工的主人翁精神，强化大家爱护环境，保护环境的环保意识，公司准备了树苗和工具，让大家自己动手，栽种希望，灌溉理想，树木栽种后可以团体名义进行挂牌认领，让员工一起见证，春日的小树，生机勃勃，茁壮成长。" },
            ],
          content_3: [
            { id: 1, title: "活动在大家热情高涨的氛围中开始，各部门主管，员工积极性非常高，大家把对春日的喜爱，对绿色的向往，对环境保护的信心，都化为一锹一锹的力量，在互相扶助中，锹土，扶树，培土，浇水，忙碌的身影背后，是一棵棵新绿立在春日的暖阳里，仿佛在倾诉着感激。" },
            ],
          content_4: [
            { id: 1, title: "在大家的努力下，公司的全部树苗都栽种完成，期待它们茁壮成长，员工也纷纷给自己栽种的树木送上美好的祝福，在活动中体验自己动手的快乐，树立环保意识，为共建美丽盛泰，绿色盛泰，贡献力量。" },
            ],
        }
      }
      else if (route.query.id == 50) {
        state.dataList = {
          title: "重庆工商大学黄海燕老师一行来盛泰光电公司参观交流",
          date: "2023-3-28",
          img_1: require('@/assets/news/50/1.png'),
          content_1: [
            { id: 1, title: "为进一步推动院校与企业的更深层次合作，建立院校人才成长和企业协同发展机制，3月27日重庆工商大学老师黄海燕带领60多名同学到盛泰光电科技股份有限公司参观交流。" },
          ],
          content_2: [
            { id: 1, title: "盛泰光电负责人向来访的师生介绍了公司的发展历程，并带领他们参观了公司的展厅、智能化SMT车间、可靠性实验室等。随后，同师生们在展厅进行了深入交流，向师生们介绍了盛泰光电的产品构成、产品应用领域、产品生产工艺流程等。同学们纷纷提问，公司负责人进行了一一解答。此次访问参观，对于院校人才培养、企业发展具有积极和深远意义。" },
            ],
        }
      }
      else if (route.query.id == 52) {
        state.dataList = {
          title: "2023年第一季度IDL员工座谈会圆满结束",
          date: "2023-4-1",
          img_2: require('@/assets/news/52/1.png'),
          content_2: [
            { id: 1, title: "一年春作首，万事行为先。为进一步了解公司员工的工作情况和思想动态，让大家更好地参与到公司的管理和建设中，为2023年各项工作开好局、起好步，帮助大家迈出工作的“第一步”，盛泰光电于3月30日下午举办了2023年第一季度IDL员工座谈会，打造员工与公司领导面对面交流的平台。" },
            { id: 2, title: "2023年第一季度IDL员工座谈会在2#2楼培训室举行，本次座谈会邀请了入职半年内的IDL员工及各部门领导嘉宾，由人力资源部主持，首先领导发言，对参会员工表示诚挚的欢迎，希望通过本次座谈会，以面对面交流的方式与新员工们敞开心扉、真诚交流，站在2023年的新起点能共谋发展、再启新程。" },
          ],
          content_3: [
            { id: 1, title: "随后参会员工依次进行了自我介绍，进一步加深了大家对彼此的了解。游戏环节现场氛围热烈融洽，增强了员工的融入感，缓解了大家紧张的心情。随着座谈会逐步深入，大家畅所欲言，就停车场、员工培训、食堂菜品、产线系统等方面提出了自己的建议和问题，公司管理层就提出的建议和问题做了明确的解释和答复，部分员工也分享了来到盛泰后感受到的和谐融洽的团队氛围。" },
            { id: 2, title: "此次座谈会的圆满举办，不仅进一步搭建了公司与员工交流的平台，还展现了公司对人才的关怀重视，增强了新员工对公司的归属感。“等闲识得东风面，万紫千红总是春。”我们要保持谦逊的态度，养成终身学习的习惯，主动思考，要善于正视、发现问题，善于分析、解决问题，不断改进工作，努力实现个人进步。让我们以更饱满的热情昂首阔步、跑出加速度，为盛泰的高质量发展贡献自己的力量。" },
            ],
    
        }
      }
      else if (route.query.id == 53) {
        state.dataList = {
          title: "重庆市经信委金融处王逸飞处长到盛泰座谈交流",
          date: "2023-4-8",
          img_2: require('@/assets/news/53/1.png'),
          content_2: [
            { id: 1, title: "为进一步健全完善拟上市企业服务工作机制和重点项目金融服务工作，促进拟上市企业服务体系创新发展，扩展金融服务覆盖领域范围，有效助推拟上市企业成长和重点产业发展。" },
            { id: 2, title: " 2023年3月31日，由大足区经信委牵头，引领市经济信息委产业金融处、大足区金融中心领导一行到盛泰光电走访调研，同时组织金融服务工作座谈会，会议由重庆市金融处主要负责人王逸飞处长主持，市经济信息委产业金融处负责人、市中小企业发展服务中心副主任、市经济信息委产业金融处干部、重庆工业企业上市公益服务专家、重庆市专精特新基金投资负责人、大足区金融中心主要负责人、大足区经信委主要负责人、盛泰光电企业负责人参加座谈。" },
            ],
          content_3: [
            { id: 1, title: "会上，与会双方围绕了解企业当前经营业绩、治理结构、产品体系等方面情况，着力协调拟上市重点工业企业面临的财税法律、融资成本、要素保障等问题。深入进行了交流探讨。盛泰光电企业负责人介绍了集团公司发展历程，公司战略规划，以及下步公司金融服务实体经济和融入拟上市企业服务工作的发展思路。" },
            { id: 2, title: "会议认为，要充分抓住市经信委推进重点项目落地和盛泰光电在投融资领域的资源优势力量，进一步探索和建立市场主体梯度培育和金融服务工作机制，形成企业、项目、金融良性互动，助推产业招商，扶持带动产业发展。此次座谈会，为行业部门和拟上市企业务合作搭建了良好沟通平台，增进了双方的了解，为下一步工作指明了方向，也为推动拟上市企业服务模式改革创新和金融服务工作提出了新的要求，奠定了双方开启深度合作的良好基础。" },
            ],
        }
      }
      else if (route.query.id == 54) {
        state.dataList = {
          title: "群星之秀，川流不息——2023年第一季度“盛泰之星”颁奖仪式圆",
          date: "2023-4-19",
          img_2: require('@/assets/news/54/1.png'),
          content_1: [
            { id: 1, title: "4月19日下午，第一季度“盛泰之星”颁奖仪式在各部门领导及同事的掌声中落下帷幕。“盛泰之星”是公司授予员工的一枚闪亮勋章，也是员工个人职业生涯中一道耀眼的光环。本届“盛泰之星”通过人力资源部初审，各部门领导评选，总经理核定，重庆公司共诞生了6名获奖者。他们分别是一等奖运营部主管蒋晓隆、二等奖人力资源部罗其星、信息资讯部伍清红，三等奖移动终端研发工艺课NPI工程师张玉磊、行政部安全员陈英虎、运营部FOL-NB工程ME高级工程师张迁。" },
            { id: 2, title: "员工是企业的根基，优秀员工是企业的制胜法宝。正是多少个群星之秀，才换来了盛泰的川流不息，希望全体员工再接再厉，争创佳绩！携手盛泰共同成长！" },
          ],

        }
      }
      else if (route.query.id == 55) {
        state.dataList = {
          title: "大足区委书记陈一清率队观摩盛泰光电“5G＋工业互联网”项目",
          date: "2023-4-25",
          img_2: require('@/assets/news/55/1.png'),

          content_2: [
            { id: 1, title: "4月24日，大足区委书记陈一清率队观摩盛泰光电“5G＋工业互联网”项目。区委常委、办公室主任龙东阁、区委办公室、区改革促进中心副主任王尚英、副主任杨建、区委网信办主任冯梅、区科技局局长杜韧、区经济信息委主任晏大利、经开区经发局局长陈瑜、联通公司负责人等参加活动。" },
            { id: 2, title: "为了让一清书记一行人更好的了解盛泰光电公司5G+工业互联网智能制造国家级创新应用示范场景、数字经济产业园建设情况（包括芯片设计、封装、测试），在公司负责人带领下参观公司展厅、数字化生产车间、IT信息资讯部和可靠性实验室，公司负责人为领导们讲解了公司的发展历程、管理制度以及今后发展规划，与一清书记和大足联通公司王总亲切交流。一路走、一路看，一路议、一路赞。每到一处，书记认真听取盛泰光电负责人介绍5G+工业互联网智能制造国家级创新应用示范场景，亲身感受高质量发展，经济社会发展给企业带来的巨大变化。" },
            ],
          content_3: [
            { id: 1, title: "一清书记要求，要进一步提高对“5G+工业互联网”以及数字赋能建设的重视程度，主动研究“5G+工业互联网”实现规模发展的可行性和必要性，解放思想、开阔思路、创新举措，健全完善协调推进机制，创造性抓好各项工作落实，全力推动“5G+工业互联网”与实体经济在更广范围、更深层次、更高水平融合发展。" },
            { id: 2, title: "大足联通公司负责人汇报了盛泰光电“5G+工业互联网”、数字赋能建设工作。进一步推进“5G+工业互联网”应用，鼓励企业利用互联网平台开展研发设计、生产管理和运营优化，助力企业提质增效，实现企业从制造到“智”造的转型升级，为大足区经济高质量发展打造新引擎、注入新活力。" },
            ],
        }
      }
      else if (route.query.id == 56) {
        state.dataList = {
          title: "为未来深入合作奠定良好基础",
          date: "2023-5-24",
          img_2: require('@/assets/news/56/1.png'),

          content_2: [
            { id: 1, title: "5月23日上午，重庆康明斯发动机有限公司信息化部总监董江一行4人在联通公司大足分公司领导陪同下到盛泰光电科技股份公司参观交流，康明斯领导董江、陈建新、钟子愚、叶允锋，联通公司5G融合创新中心行业总监许刚、5G融合创新中心高级方案经理刁俊夫、5G融合创新中心行业客户经理谭丽雯、集团云网支撑专家韩春李，盛泰光电公司单位负责人热情接待并陪同参观交流。" },
            { id: 2, title: "在公司2号楼一楼展厅，董江一行听取了单位负责人关于盛泰公司生产经营、企业规划、发展历程等情况的介绍，并参观了展厅，对盛泰公司的产品、企业文化等相关情况进行了了解。当走到产品展柜面前，董江对盛泰公司小型化摄像头、业界最薄和最窄的笔电摄像头、像数达2亿的摄像头表现出了极大的兴趣，不仅走进驻足仔细观看，还主动发问，积极与单位负责人就产品应用、技术特点等展开了深入交流。" },
            ],
          content_3: [
            { id: 1, title: "随后，董江一行又先后参观SMT车间、EOL车间、信息资讯部，深入生产一线，隔着车间玻璃，近距离察看生产过程，详细了解生产技术、产能产量、现场管控等相关情况。并对盛泰光电整洁的生产环境、先进的生产技术、有序的生产流程、严格的质量管控等方面赞不绝口。" },
            {id:2,title:"参观结束后，双方高层管理人员在公司一楼会议厅召开了座谈交流会。双方围绕此行5G+专网应用5G应用场景展开了深入交流，并共同表示，希望未来加强交流，共同为幸福大足、美丽大足作出更多更大的贡献。"}
          ],
        }
      }
      else if (route.query.id == 57) {
        state.dataList = {
          title: "2023年“跃动篮球，绽放激情”盛泰光电夏季男子篮球赛完美收官！",
          date: "2023-5-25",
          img_2: require('@/assets/news/57/1.png'),
          content_2: [
            { id: 1, title: "5月23日，由公司举办的“跃动篮球，绽放激情”夏季篮球赛圆满落幕。公司各部门篮球爱好者踊跃报名参赛，群英荟萃，经过比赛的激烈角逐，上演了一场精彩纷呈的运动盛宴。“嘟——”随着裁判一声哨响，双方球员奋力一跃，为争夺球权拼尽全力，急停三分、抢断抄截，持球突破、巧妙包夹……激起了场边阵阵喝彩" },
            { id: 2, title: "双方球员你争我抢、你来我往，不断根据形势变更战术，利用防守、快攻制约对手，虽然体力急速下降，但球员们仍拼尽全力，坚持战斗到最后一秒。各代表队球员们严格执行比赛纪律，尊重对手、服从裁判，展现出优良的行为作风，体现出良好的体育道德风尚。" },
            { id: 3, title: " 经过激烈循环赛比拼，一队第一场以51：44的战胜二队；第二场以50：49的比分战胜三队，最终获得本次比赛的冠军。此次篮球赛，不仅是一次体育比赛，更是一次展现盛泰健儿团结拼搏的舞台。比赛中，球员们不断挑战自我，突破极限。未来，他们会以更加饱满的工作热情和更加健康的体魄投入到生活和工作中，以实际行动迎接盛泰光电重庆公司成立5周年的到来。" },
            ],
        }
      }
      else if (route.query.id == 59) {
        state.dataList = {
          title: "“民生情怀浓 惠民更有感”关爱大足困难残疾人公益活动",
          date: "2023-6-2",
          img_2: require('@/assets/news/59/1.png'),
          content_2: [
            { id: 1, title: "为了响应第三十三次全国助残日主题宣传及“民生情怀浓 惠民更有感”关爱大足困难残疾人公益，2023年5月26日，盛泰光电捐赠2万元给大足区残联，献爱心支持残疾人事业发展，盛泰光电代表团前往大足区特殊教育学校参加关爱大足困难残疾人公益募捐表彰活动。" },
            { id: 2, title: "活动于上午九点举行，现场到场有各单位以及社会各残疾人士、特殊教育学校的孩子及校长老师们。现场有小朋友们的手工作品，展现各自精湛的手艺，个个都是能工巧匠，充满了智慧与生机。活动最开始是颁奖环节，主持人为各爱心单位授予牌匾级证书。我司被评为“2023年助残爱心单位”并被授予该牌匾。在现场，我们参观了小朋友的现场表演。台上的小朋友虽然都是特殊人群，但是他们却通过自己的努力发出了耀眼的光芒，为我们带来了最精彩的表演。台下是老师们通过哑语及手势的指挥，每一个节目都让我看到了他们对生活的热爱，这就是我们献爱心，做公益的意义所在吧！" },
            { id: 3, title: "社会的爱，对于残疾人来说就像疲倦的人得以依靠；就像迷路的人，找到一盏明灯；就像炎热的人找到浓浓的绿荫；就像心灵干枯的人们找到一泓清澈山泉。通过本次活动弘扬志愿精神，对需要帮助的社会弱势群体给予帮助，传递爱的符音，让他们真切感受到社会的温暖关怀。 " },
            ],
        }
      }
      else if (route.query.id == 60) {
        state.dataList = {
          title: "【盛泰资讯】重庆市检察院检察长时侠联来盛泰公司调研",
          date: "2023-6-5",
          img_2: require('@/assets/news/60/1.png'),
          content_2: [
            { id: 1, title: "6月1日，重庆市检察院党组书记、检察长时侠联一行在大足区区委副书记、区政府区长徐晓勇、区委常委、政法委书记杨烈和区检察院党组书记、检察长孙琳的陪同下，来到盛泰光电进行调研，重庆市第六届人大代表、盛泰光电科技股份有限公司董事长赵伟进行热情接待。" },
            { id: 2, title: "在展厅，盛泰公司单位负责人详细介绍了公司发展历程和管理制度以及今后发展规划的相关情况。时侠联检察长一边听取介绍，一边询问相关产品的原料来源和应用领域。" },
            { id: 3, title: "在盛泰可靠性实验分析中心，时侠联检察长听取了盛泰单位负责人对摄像模组的生产工艺流程、主要产品相关情况的介绍，称赞道：“大足区政府能招商引资这样的一个实打实的企业，真是大足的褔气”。" },
            ],

        }
      }
      else if (route.query.id == 61) {
        state.dataList = {
          title: "观念转换，科学推进——2023重庆公司六西格玛项目顺利启动",
          date: "2023-6-13",
          img_1: require('@/assets/news/62/1.png'),
          img_2: require('@/assets/news/62/2.png'),
          content_1: [
            { id: 1, title: "六西格玛是一种系统的、以客户为中心的，基于事实和数据的企业管理方法论。根据公司战略目标，为了稳定而高效地满足顾客需求，促进精益生产，带动质量大幅提高、成本大幅度降低，实现企业竞争力的突破，重庆工厂端HR协同运营部，特邀外训机构专业老师张峰作为讲师，指导六西格玛项目培训。此项目2023年6月初启动，将持续半年时间至2024年初完成。6月9日-6月10日，公司顺利开展倡导者启动会，并完成D阶段理论知识的培训。" },
            ],
          content_2: [
            { id: 1, title: "启动会为期一天，在正式开始前，由运营部副总经理李响林作为倡导者代表进行发言，呼吁号召各部门管理者积极动员希望通过此项培训，采用科学的方法，进一步提高顾客满意度，降低组织的资源成本。李总的发言很快与大家达成共识。启动会中，张老师从六西格玛理论知识和项目实施流程入手，主要介绍了六西格玛培训的必要性、意义、优势和关键概念；重点针对界定阶段的任务目标和常见问题进行详细讲解，包括认识及理解活动角色与责任、投入及时间分配、人员选择、收益构成与计算等。老师通过系列案例与现场互动充分调动各成员的学习兴趣，有效地增进了各学员对知识点的理解。" },
            ],
            content_3: [
              {id:1,title:"经过老师的指导及建议，学员们结合自身能力及强项，共组建8个小组，12个项目，小组成员涵盖制造、PE、ME、IE、品质等部门的核心人才。各小组选定各自项目并按规定完成项目的规划、分析及报告，为后续阶段的计划实施做好充足准备"},
              {id:2,title:"在6月10日的D阶段理论知识学习中，张老师展开叙述了客户之声VOC和项目定义的相关内容，介绍并分析树形图、亲和图等工具的使用时机、优缺点、注意事项、步骤及范例，以及如何运用5W2H、SMART评估等方法。通过把运行数据作为分析和解决问题的关键依据，六西格玛让各学员在日常工作中初步养成精细管理的意识和思维，形成注重事实和数据的氛围；通过实施项目，学员对项目流程采用量化的方法分析影响因素，充分运用六西格玛的一系列工具，按照由外及内、由粗到细、层层过筛、层层剥茧的思路，找出最关键的因素加以改进从而达到更高的客户满意度。"},
              {id:3,title:"六西格玛目前已经被广泛引入到许多世界领先的公司，六西格玛方法已经成为企业管理的基本工具，只有企业中掌握方法、理解理念的人多了，才能真正使企业有脱胎换骨的提升。期待项目的各小组成员通过理论培训及项目辅导，将成果转化为公司实际的项目运作，真正地将六西格玛的改善收益完美体现，为企业做大、做强的愿景做出贡献。"},
            ]
        }
      }
      else if (route.query.id == 62) {
        state.dataList = {
          title: "【盛泰团建】打造优秀团队，共创盛泰辉煌",
          date: "2023-6-5",
          img_1: require('@/assets/news/61/1.png'),
          img_2: require('@/assets/news/61/2.png'),
          content_1: [
            { id: 1, title: "为进一步增强团队凝聚力，有效促进同事间的相互了解和团队合作精神，重庆盛泰光电移动终端事业部研发、PM、自动化三个部门联合组织开展了以“打造优秀团队，共创盛泰辉煌”为主题的团建活动。让小伙伴们在忙碌的工作之余相聚在一起，劳逸结合，放松心情，感受大自然的魅力，欢享初夏的美好时光。" },
            { id: 2, title: "工作全力以赴，生活不可辜负。6月3日早上9点半，小伙伴们轻装出行，结伴前往玉龙镇宁翠山庄，开启了今天的团建活动。首先是主持人带领大家做个“花开花落”的破冰游戏，让大家把所有疲惫抛之脑后，所有烦恼也随之烟消云散。先热热身，为后面的比赛游戏做准备……" },
            ],
          content_2: [
            { id: 1, title: "同心鼓游戏考验的是团队的合作的能力，情感凝聚，思想统一，行动一致。心往一处想，力往一处使才能取得胜利。踩气球游戏利用个人或团体对抗的方式，以增加小队间的灵活性，活动中队员们可谓是八仙过海各显神通，使出浑身解数只为团队胜利。蒙眼取水游戏把整场活动推进了高潮，场面一度失控，队员们为了自己队能取得胜利而努力拼博着。“蒙眼取水”充分的展现了团队的默契度与信任度，相信自己的团队不被外界所干扰，旨在提高团队的协作力，当队员有走“偏”时应予以及时制止纠正，因为是同一个团队。扑克牌运面粉中，大家都不怕面粉弄花了脸，人人都勇往直前敢于挑战，虽然面粉弄花了脸，但是喜悦和兴奋之情溢于言表。运面粉也不是靠某一个人就能取得胜利，而是要大家齐心协力。话说一人强，不是强，再强也是一只羊；团队强，才是强，团结起来就是只狼。只有团结才有强大的能量，凝聚在一起才是团队。" },
            { id: 2, title: "最好的生活方式，是和一群志同道合的人，一起奔跑在理想的路上，回头有一路的故事，低头有坚定的脚步，抬头有清晰的远方。加油吧，盛泰人，末来可期" },
            ],
        }
      }
      else if (route.query.id == 63) {
        state.dataList = {
          title: "“重庆市企业创新奖”出炉，盛泰光电科技股份有限公司榜上有名",
          date: "2023-6-17",
          img_2: require('@/assets/news/63/1.png'),
          content_2: [
            { id: 1, title: "近日，重庆市企业创新奖出炉，盛泰光电科技股份有限公司榜上有名。重庆市企业创新奖评选工作由市经济信息委、市人力社保局、市商务委、市文化旅游委联合开展。经逐级推荐遴选，在广泛征求意见、集体研究的基础上，确定了150家企业为重庆市企业创新奖拟表彰对象。" },
            { id: 2, title: "企业是科技创新的主体，也是推动创新创造的生力军。近年来，双桥经开区大力实施创新驱动核心战略，优化完善科技创新体制机制，加快平台打造、人才引进、项目申报、成果转化等工作进度，科技创新综合实力实现了新的跃升，为高质量发展注入新动力。盛泰光电也一定会把握好政府创设的优越营商环境，奋勇拼搏赢未来，为大足经济社会发展作出新的贡献。" },
            ],

        }
      }
      else if (route.query.id == 64) {
        state.dataList = {
          title: "【盛泰资讯】重庆市政协主席唐方裕莅临盛泰光电科技股份有限公司参观调研",
          date: "2023-6-25",
          img_2: require('@/assets/news/64/1.png'),
          content_2: [
            { id: 1, title: "2023年6月20日下午，重庆市政协主席唐方裕，在大足区区委书记陈一清、大足区政协主席廖文丽、经开区党工委副书记周树云等领导陪同下莅临盛泰光电科技股份有限公司参观调研，公司高层领导进行了热情的接待。" },
            { id: 2, title: "参观调研时，一清书记对大足的发展战略规划，企业亮点做了详细讲解，市政协唐主席此行调研川渝毗邻地区协同发展情况时强调，要认真落实川渝两省市毗邻地区合作共建区域发展功能平台要求，统筹推进基础设施、产业发展、公共服务等领域合作，为双城经济圈高质量发展提供重要支撑。" },
            { id: 3, title: "参观调研中，市政协唐方裕主席关切询问产品应用领域、市场占有率等情况。他希望企业抓住成渝地区打造的两大万亿级优势产业集群的机遇，发挥自身关键零部件上的技术优势，进一步把企业做大做强。" },
            { id: 4, title: "参观结束后，区委区府领导们肯定了企业近年来为全区和全市稳增长做出的重要贡献，希望企业要加大技改投入，坚定发展信心，继续扎根重庆，争取长期可持续发展。" },
            ],
        }
      }
      else if (route.query.id == 65) {
        state.dataList = {
          title: "消防技能大比武，筑牢安全防火墙——2023盛泰消防技能比武活动",
          date: "2023-7-3",
          img_2: require('@/assets/news/65/1.png'),
          content_2: [
            {id:1,title:"做好消防安全工作是推进公司高质量发展的重要一环，为提升公司人员消防安全技能，培养好内部人才，品质部联合行政部结合消防演习安排，开展了消防安全知识有奖问答及消防技能比武活动，以赛为练，开展消防技能竞技，加强应急处置能力，提高公司安全管理水平。"}, 
            {id:2,title:" 6月28日下午，消防技能比武活动在小广场顺利开展，来自公司各部门的7支队伍参与了比赛。此次比武共设置“双人戴过滤面具施救人员”、“单人穿防护服”、“四人灭火接力”和“两人两水带连接出水”4个比赛项目。"}, 
            {id:3,title:"双人戴过滤式消防自救呼吸器施救人员项目中，选手快速打开面罩并正确佩戴后抬起“伤员”将其转移到安全地点。单人穿防护服项目看似简单，但想赢得比赛就得快中有细，选手依照顺序快速穿好战斗服下装，系好下装腰带，穿好上衣，粘合好上装搭扣，并佩戴好手套与头盔。四人往返跑灭火接力项目中，每队派出4人进行往返跑接力，选手们迅速交接灭火器，最后一人冲向灭火点，“摇、对、拨、压”一气呵成,将“着火点”迅速扑灭。两人两水带连接出水项目中，2位选手需相互配合完成水带与消防栓、2条水带间以及水带与水枪头的正确连接，奔向灭火点，并打开阀门口，完成水枪的成功出水。"}, 
            {id:4,title:"比武过程中各参赛队选手间配合默契、分工明确，领先队伍不骄不躁，落后队伍奋力赶超，比武场上加油欢呼声不断。最终信息资讯部/人力资源部/行政部联队以181秒累计成绩获得本次消防技能比武第一名、移动终端事业部研发/PM/自动化秒的累计成绩获得第二名、NB&AIOT BU代表队以215秒的累计成绩获得第三名。"},
            {id:5,title:"6月21日中午，消防安全知识有奖问答在餐厅一楼进行，答对5题即可获得抽奖机会，问答题目涉及消防事故预防、应急逃生和公司安全规定等方面，前来参加答题的同事络绎不绝，活动共52人获奖。"},
            {id:6,title:"安全线就是生命线，它是员工生命健康的保障也是企业生存与发展的基础。此次活动的举办有效提升了员工的消防技能水平，进一步增强了员工安全意识，希望大家不仅仅是在比赛时拼尽全力，在日常工作及生活中也能重视消防安全，做到“高高兴兴上班来，平平安安回家去”。"},
          ],
        }
      }
      else if (route.query.id == 66) {
        state.dataList = {
          title: "“逐梦盛泰 扬帆起航”欢迎2023届新进大学生",
          date: "2023-7-4",
          img_2: require('@/assets/news/66/2.png'),
          img_3: require('@/assets/news/66/1.png'),
          content_2: [
            {id:1,title:"岁月不居，时节如流，毕业如歌，与尔相遇，在这阳光灿烂，欣欣向荣的毕业季，盛泰光电迎来了新一批应届大学生，这些朝气蓬勃的新面孔，为盛泰光电注入了新鲜血液。为了解应届大学生的思想状态，构建沟通交流的桥梁，促进新人的成长，并营造舒适、积极、平等、和谐的工作氛围，盛泰光电人力资源部于7月3日下午在培训室举行了2023届大学生入职见面会，公司各部门领导、大学生导师以及新入职大学生参与了本次活动。"}, 
          ],
          content_3: [
            {id:1,title:"见面会开始，总经理Allen及副总经理Tony作为领导代表致辞，对应届大学生的加入表示热烈的欢迎，并对各位新入职的应届生们寄予厚望，鼓励大家尽快适应公司环境和工作节奏，顺利完成从学生到职场人的角色转变，为个人成长不断努力，为公司发展贡献自己的力量。"}, 
            {id:2,title:"参会的应届大学生逐一进行了自我介绍和入职感悟，大家都积极踊跃，生动的语言、自信的态度让在场的人都留下了深刻的印象。"}, 
            {id:3,title:"在郑重的拜师环节中，学长，学弟、学妹们先是集体朗诵了盛泰企业文化，让同学们对公司的企业文化有了初步了解，再进行了师徒宣誓后，学长们鼓励大学生进入实训岗位后脚踏实地、快速成长、实现自我价值。在互动游戏的欢声笑语中，本次大学生见面会也进入了尾声。长期以来，公司都高度重视新人引进和培养工作，此次2023新进大学生见面会的顺利召开有利于倾听新心声，增进新进大学生与公司管理层之间的了解，能够帮助新员工更好更快地融入集体，与公司共同发展，互相成就！接下来，让我们一起期待这群年轻人从大学生蜕变成逐梦盛泰人吧，加油！"}, 
          ],
        }
      }
      else if (route.query.id == 67) {
        state.dataList = {
          title: "不负韶华争朝夕，奋楫扬帆正当时，2023届大学生岗前培训结业仪式圆满落幕",
          date: "2023-7-10",
          img_2: require('@/assets/news/67/2.png'),
          img_3: require('@/assets/news/67/1.png'),
          content_2: [
            {id:1,title:"青年兴则国家兴，青年强则国家强，7月3日，盛泰迎来了朝气蓬勃的新生代力量，为了使大家快速融入盛泰大家庭，熟悉公司发展、产品制程及职场生活，掌握岗位相关知识技能，使其顺利实现角色转变，成为企业发展需要的战略性人才；7月3日-7月8日人力资源部组织了为期一周的岗前培训，20名学员，为期一周的岗前培训，汇聚成了一场收获满满的“学习之旅”，7月8日，盛泰2023届大学生岗前培训班顺利结业。"}, 
            {id:2,title:"培训采取理论与实践相结合的方式，前4天开展的是系列理论培训，包括品质相关的HSF、ESD、体系基础等，研发相关的二级物料、摄像头模组简介和模组制程介绍等，也有IT相关的IT应用规范及信息安全，以及通用素质课：时间管理、沟通技巧、职场形象与礼仪、电子邮件使用规范等；后2天实践环节走进车间，近距离参观产品的生产工艺流程，了解各种专业技术设备的运转方式和运用功能，也亲身体验，动手完成了一些相应站位的工作。"}, 
          ],
          content_3: [
            {id:1,title:"本次岗前培训结业仪式我们荣幸的邀请到公司高管为本次结业仪式致辞。读万卷书不如行万里路，行万里路不如阅人无数，阅人无数不如名师指路；领导们对学员们顺利结业表示祝贺、对于学员们在培训过程中的表现给予肯定、也对他们日后的工作寄予了希望，希望他们能够学以致用，将学到的知识运用到工作当中；并希望学员们一要提升自身工作能力和专业水平，使工作更加规范化、二要敢担当敢创新，利用好公司提供的平台，展示新时代青年风采。"}, 
            {id:2,title:"在这一周的学习中，学员们认真听课、勇于发言、敢于思考、积极交流，不断提高自己的专业素养和知识水平；讲师们在讲课的过程中倾囊相授，对学员们的提问知无不言、言无不尽。因此我们结合各学员在培训期间的表现，评选出了优秀学员、学习标兵、突出学员各一名；学员们也选出了心目中优秀的讲师，并为他们颁发荣誉证书和奖品，以表彰他们的努力和付出。优秀学员获奖者是信息资讯部系统开发助理工程师杨清清，学习标兵获奖者运营部工程课PE组PE助理工程师李霖聪，突出学员获奖者是移动终端事业部研发设计课EE助理工程师赵艳，优秀讲师为人力资源部袁佳、任甜甜。"}, 
            {id:3,title:"这次培训也邀请了周亚、柯宝林两位学长学姐来经验分享，两位学长学姐作为过来人分享了怎么适应从学生到员工身份的转变、在工作中积累的经验以及给学弟学妹们一些在工作中的叮嘱；并希望学弟学妹们能够一次次突破自我，在工作中绽放异彩！"}, 
            {id:4,title:"本次培训，全体新进学员不仅丰富了岗位理论知识，而且提高了投身于工作的责任感和事业心，同时也让学员们有很多的感受和体会，在分享环节中学员们积极参与，有谈对公司的认识、培训的收获、身份转变的感受也有对个人成长的体会。大学生岗前培训虽然结束了，但是每个学员的职业生涯才刚刚开始，他们的身份也会从学员变为新员工，相信加入盛泰大家庭的新员工们定不会负领导们的期望，脚踏实地、努力奋进，在盛泰开启新征程并在工作中找到自己的天地！"}, 
          ],
        }
      }
      else if (route.query.id == 68) {
        state.dataList = {
          title: "互信共荣，合力同辉——2023第二季度IDL员工座谈会",
          date: "2023-7-13",
          img_2: require('@/assets/news/68/1.png'),
          content_2: [
            {id:1,title:"为了切实帮助广大员工解决工作和生活中的实际问题，构建员工与管理层的有效沟通，巩固员工对公司的认同感与归属感，营造良好的企业氛围，创造更加高效协同的组织团队，2023年7月11日，人力资源部组织开展了2023年第二季度IDL员工座谈会。"}, 
            {id:2,title:"本次座谈会邀请了今年入职的部分IDL员工及各部门领导与嘉宾，在轻松愉悦的氛围中倾听彼此心声，拉近彼此距离，相互交流工作感受，提出合理建议及意见。"}, 
            {id:3,title:"座谈会少不了自我介绍环节。为了让在场的同仁快速熟悉身边的小伙伴，我们给每一位参加活动的员工发放了数字号，在自我介绍前大家需要说出上一名介绍的小伙伴的名字后，再介绍自己，接着通过喊号让下一名员工继续接龙。该环节采取将传统的自我介绍与趣味游戏相结合的方式，让员工们积极参与讨论，互相交流，分享各自的兴趣爱好，使得现场的氛围很快变得轻松愉快起来。经过自我介绍，大家对彼此有了更加深入的了解，也进一步促进了员工间友好关系的建立，帮助大家快速融入集体。"}, 
            {id:4,title:"接着，活动来到最重要的环节——分享答疑。针对在工作中、生活上的种种困难或疑问，比如关于食堂饭菜的、宿舍规划的、办公桌椅等，关于工作中应用到的硬件及软件的，员工们勇于分享各自想法，积极提出建设性意见；各部门领导针对大家提出的不同问题也耐心做出解答，并在会后协调资源、迅速落实大家提出的合理性建议。公司的发展离不开每一位员工的努力，也离不开我们彼此的充分沟通和互相理解，员工座谈会正是这样一个可供大家沟通交流的良好平台之一。"}, 
            {id:5,title:"作为盛泰大家庭的一员，我们每一个人都需要心系企业的发展，因为这里是我们职业的依靠，只有与企业心连心、同命运、共呼吸，我们才能相互成长，合力同辉。"}, 
          ],
        }
      }
      else if (route.query.id == 69) {
        state.dataList = {
          title: "与你相约，光影共赏，7月生日员工观影活动圆满结束",
          date: "2023-7-31",
          img_2: require('@/assets/news/69/1.png'),
          content_2: [
            {id:1,title:"生日会有N+1种过法，每个特殊的日子都值得纪念，为丰富员工精神文化生活，放松员工心情的同时增强员工间的沟通交流，盛泰光电组织7月生日员工一起观看影片《八角笼中》，共同感受电影中：追求、坚持、陪伴和永不服输的魅力。生活需要仪式感，工作需要归属感，暂时放下繁忙的工作，享受放松与美好，让我们为忙碌的生活加点糖，是为了更好地出发。"}, 
            {id:2,title:"向腾辉为了山区孩子的前途第一次说狠话后自责地狂扇自己耳光以及孩子们不顾一切的样子，因为这是他们改变人生的唯一出路，等等这一幕幕让盛泰光电的员工们潸然泪下。"}, 
            {id:3,title:"影片《八角笼中》讲述了普通人如何在困境中寻找出路，面对生活不认命也不服输的现实题材故事，展现了冲破苦难命运的牢笼，走向更开阔的人生的突破精神。"}, 
            {id:4,title:"观影活动结束后，大家纷纷表示影片不仅仅是一部励志感人的电影，同时通过影片中的“格斗”也表现了对于抗争命运的“具像化表达”，现实生活中，每个人不能选择自己出生的环境和条件，但可以选择自己生活的方式和目标。要始终拥有勇敢的心、坚持和敢于追求梦想的精神，努力坚持，努力奋斗！"}, 
          ],
        }
      }
      else if (route.query.id == 70) {
        state.dataList = {
          title: "盛泰光电公司荣获2022年度大足区特色产业高地建设先进集体",
          date: "2023-8-30",
          img_2: require('@/assets/news/70/1.png'),
          content_2: [
            {id:1,title:"8月28日下午，大足区推动制造业高质量发展暨科技创新和人才工作大会召开。区委书记陈一清出席会议并讲话。他强调，要深入学习贯彻习近平总书记关于制造业和民营经济高质量发展的重要论述，全面落实党的二十大精神和市委六届二次、三次全会工作部署，抓住用好成渝地区双城经济圈和渝西地区一体化高质量发展机遇，持续优化产业结构、积蓄增长动能、重塑发展优势，加快推动制造业高质量发展，全面增强民营经济综合实力和竞争力，为社会主义现代化新大足建设注入强劲动力。"}, 
            {id:2,title:"会上，盛泰光电科技股份有限公司被评为“2022年度大足区特色产业高地建设先进集体”，盛泰光电总经理参加会议并围绕大会主题作了交流发言。"}, 
            {id:3,title:"公司负责人发言谈道，盛泰光电作为第一批手机摄像头模组制造商，专注于CCM摄像模组的研发和制造，基于2023年续回暖，盛泰光电新的业务布局，智能制造工艺和硬件升级、设备数据联通，工业互联网智能信息化和灯塔工厂持续推进，盛泰将以更加严格的指标考核和要求自己；后续在区委区政府、经开区管委会和各级部门的大力支持下，在全体盛泰人的共同努力和拼搏下，相信盛泰很快会达到上市指标，早日向深交所提交上市申报材料，为加快做靓享誉世界的文化会客厅，建强链接成渝的“两高”桥头堡做出新的贡献。"}, 
          ],
        }
      }
      else if (route.query.id == 71) {
        state.dataList = {
          title: "怦然“新”动，筑梦未来——2023年盛泰光电新员工欢迎会",
          date: "2023-8-31",
          img_2: require('@/assets/news/71/1.png'),
          content_2: [
            {id:1,title:"梧桐引凤来，英才聚风华，2023年上半年盛泰大家庭注入了很多新鲜力量。为了帮助新员工更加深入感受企业文化，强化员工主人翁意识和集体荣誉感；促使新员工更快融入工作环境和新集体，激发他们立足岗位、扎根企业的决心和热情，8月29日人力资源部举办了2023年首届新员工欢迎会，欢迎会邀请了公司各部门领导以及2023年入职公司的部分新员工。"}, 
            {id:2,title:"为了消除新员工与领导之间的距离感，人力资源部策划了3个游戏：数字传递、智慧一条龙、真假难辨，将参会的人员分为4组，每组选出一名队长，各自为战。数字传递游戏培养队员之间的默契，各组可谓是奇招尽出，每一个小组在开始前都进行了头脑风暴，想出专属自己队伍的暗号与手势来获得游戏的胜利；智慧一条龙游戏是思维与速度的博弈，每个小组在赛前认真研究制胜的方案，比赛更是进行的如火如荼，每一位成员都全力以赴；真假难辨游戏是对大家演技的考验，参与的人员要通过表演，做出表情与动作来迷惑猜题人员，在该游戏中大家的表情丰富，演技出神入化，使得现场气氛轻松活跃。"}, 
            {id:3,title:"经过3个游戏的角逐，大家慢慢没有拘束感，能够敞开心扉参与活动，勇于表达与表现自己。在接下来的畅所欲言环节中，新员工们积极分享自己在工作、生活中遇到的难题与困惑，表达对工作的感受、对公司的印象；各部门领导针对新员工提出的难题与困惑做出解答与回复，并希望新员工在日后的工作中也能积极的发现与指正问题。"}, 
            {id:4,title:"山高路远，但见风光无限，跋山涉水，不改一往无前。本次新员工欢迎会帮助新员工更好的融入盛泰大家庭。新的起点，新的征程，愿加入盛泰光电的新力量铆定方向、接续奋斗，以积极向上的精神面貌面对生活与职场，用拼搏定义工作、以奋斗礼赞生活，共同创造盛泰美好未来！"}, 
          ],
        }
      }
      else if (route.query.id == 72) {
        state.dataList = {
          title: "盛泰光电荣获工信部2022年国家级智能制造优秀场景",
          date: "2023-9-6",
          img_2: require('@/assets/news/72/1.png'),
          content_2: [
            {id:1,title:"9月5日上午智博会举办了“第六届智能制造和工业互联网创新发展论坛”，并对当选2022年国家级智能制造优秀场景单位现场授牌。盛泰光电科技股份股份有限公司凭借“生产作业-产线柔性配置”场景，成功入选并获得授牌。"}, 
            {id:2,title:"该场景通过部署全自动上下料机械手、八工位自动调焦机、全自动高速点胶机等智能制造装备，应用模块化、成组和产线重构等技术，搭建柔性可重构产线，可根据订单、工况等变化实现产线的快速调整和按需配置，实现多种产品的自动化混线生产。"}, 
            {id:3,title:"通过产线柔性配置，使智能装备与智能控制系统、信息化系统紧密联动，实现生产装备智能物联、智能人机物协同、生产线动态智能调度，充分发挥工业互联网、智能制造对制造业的赋能作用，加强生产过程管控和协同，缩短产品上市时间，提高生产效率，降低生产成本。建成后的产线可根据订单、工况等变化，使人员、设备、工装、物料、信息软件等因素实现最佳组合，系统资源得到最有效的配置和优化，实现产线的快速调整和按需配置。"}, 
            {id:4,title:"此次授牌，体现了国家对盛泰光电科技股份有限公司在智能制造领域能力及可复制化制造模式的充分认可。未来，盛泰光电科技股份有限公司将继续深耕数字产业化，引领制造全方位转型，加速智能制造工艺和硬件升级、设备数据联通，促进工业互联网智能信息化和灯塔工厂持续推进。"}, 
          ],
        }
      }
      else if (route.query.id == 73) {
        state.dataList = {
          title: "踢出风采，赛出友谊|2023年盛泰光电首届足球赛圆满落幕",
          date: "2023-9-8",
          img_2: require('@/assets/news/73/1.png'),
          content_2: [
            {id:1,title:"为助力幸福企业建设，丰富职工的业余文化生活，提升职工的团队精神与合作意识，进一步增强双桥经开区各单位间的交流与沟通，近日，以体育运动为纽带，盛泰光电与五个球队开展了“踢出风采，赛出友谊”的主题足球友谊赛。、"}, 
            {id:2,title:"哨声响起，比赛开始。双方队员精神饱满，热情高涨，身着各色队服，你来我往，攻防有序。每一次拼抢，每一次防守，每一次断球，都是团队精神和个人能力的极致展现。欢呼声、加油声此起彼伏，热烈的气氛感染着在场的每一个人。"}, 
            {id:3,title:"此次足球友谊赛，不仅为企业双方提供了一个在赛场上并肩作战、共同挑战的平台，丰富了职工的业余文化生活，也充分展现了“盛泰人”团结拼搏、积极向上的精神风貌，进一步激发了广大职工对足球运动的参与热情和追求卓越、勇攀高峰的精神力量，切实为公司高质量发展注入了强大动能。"}, 
          ],
        }
      }
      else if (route.query.id == 74) {
        state.dataList = {
          title: "盛泰光电科技股份有限公司入选重庆市企业创新奖！",
          date: "2023-9-13",
          img_2: require('@/assets/news/74/1.png'),
          content_2: [
            {id:1,title:"近日，重庆市人民政府公布《关于表彰重庆市企业创新奖的决定》。为深入贯彻习近平新时代中国特色社会主义思想，全面贯彻党的二十大精神，认真落实市委六届二次、三次全会部署，进一步牢固树立创新发展理念，切实营造担当作为、唯实争先、“赛马比拼”的良好氛围，更好引领推动全市经济社会高质量发展，市政府决定，授予盛泰光电科技股份有限公司等150家企业“重庆市企业创新奖”，并对获奖企业予以表彰。"}, 
            {id:2,title:"盛泰光电2006年始创于深圳，2018年集团设立重庆总部公司。数年的CCM摄像模组行业的深耕，在技术研发、精密制造、图像软件服务等领域的技术积累和创新奠定了高新技术企业的基础，我们的产品广泛应用于手机、平板、电脑、自动驾驶、医疗、智慧城市、无人机、航天航空等领域；拥有专利400余项；先后获得市级“科技型企业”、“技术创新中心”、“中小企业技术研发中心”、“双百企业”、“制造业100强”等荣誉，成功入选了工信部2022年度“智能制造优秀场景”。盛泰合作伙伴遍布全球各地。盛泰光电在快速成长中秉承强化中国制造，壮大民族产业的初心，不断奋勇前进。"}, 
            {id:3,title:"盛泰光电将珍惜荣誉、再接再厉，开拓创新、再创佳绩。持续加大研发投入、不断增强创新能力，聚焦新技术、新产品、新模式、新业态，开展原创性、引领性技术攻关，加快创新成果转化和产业化进程，在全面建设社会主义现代化新重庆中展现新作为、作出新贡献。"}, 
          ],
        }
      }
      else if (route.query.id == 75) {
        state.dataList = {
          title: "恭喜！盛泰光电公司“5G工业互联摄像头模组封测车间”荣获第六届“绽放杯”5G应用征集大赛重庆区域赛二等奖",
          date: "2023-9-20",
          img_2: require('@/assets/news/75/1.png'),
          content_2: [
            {id:1,title:"9月14日，由重庆市经济和信息化委员会（重庆市5G发展联席会议办公室）、重庆市通信管理局、5G应用产业方阵主办的第六届“绽放杯”5G应用征集大赛重庆区域赛决赛成功举办。"}, 
            {id:2,title:"本次重庆区域赛以“5G耀山城 数实向未来”为主题，共征集到348个参赛项目，并从通过复赛的200个项目中评选出35个优秀5G应用项目入围决赛角逐一等奖、二等奖及特别奖。经过激烈角逐，最终评选出一等奖10名、二等奖20名、三等奖30名和最具创新突破奖、最具应用示范奖、最具功能设计奖、最具商业价值奖、最具社会效益奖各1名，盛泰光电公司“5G工业互联摄像头模组封测车间”荣获第六届“绽放杯”5G应用征集大赛重庆区域赛二等奖。"}
          ],
        }
      }
      else if(route.query.id == 76){
        state.dataList = {
          title: "突破是成功的前奏，方法是成功的利器",
          date: "2023-10-16",
          img_1: require('@/assets/news/76/1.png'),
          content_1: [
            {id:1,title:"10月13日，盛泰光电重庆工厂第一期精益六西格玛项目DM阶段评审工作已圆满开展，由培训机构六西格玛资深专家、公司总经理及公司运营、品质、研发等部门领导，共同组成本次评审的专家团队，从项目思路、团队作用发挥、六西格玛工具使用及项目取得的实质成效等方面对本期7个绿带项目进行了综合把关与评价。"}, 
            {id:2,title:"精益六西格玛从2023年6月引进重庆工厂至今，已完成定义[Define]与测量[Measure]阶段的学习与辅导，各项目团队以数据和事实为驱动器，对需要改进的流程进行区分，找到最有潜力的改进机会，运用专业工具分析问题焦点，已逐步为公司产生收益。经过评审，本期7个项目评审成绩全部超过60分，及格率为100%。其中，共有3个项目获得了80分以上的优秀成绩，优秀率为42.9%。公司分别对过程表现较为突出及成绩优秀的项目团队进行了800元~2000元的奖励，对表现优异的项目组长和成员予以了200元~800元的奖励以示鼓励。"}, 
          ],
          content_2: [
            {id:1,title:"从项目过程表现上看，各小组的执行力、整体氛围、团队积极性、主动性都还有待提高；部分技术人员经验主义较重，仍有先入为主的思想，分析问题的思路没有打开；年轻项目负责人专业知识与组织能力的欠缺等都是需要我们在后续工作中不断加强的方面。评委对各项目问题进行一一点评，并就如何进一步完善项目工作、解决公司实际问题方面提出了建设性的建议，为项目团队下一步工作指明了方向；同时，各部门领导对本次项目都给予了足够支持，为后期顺利开展提供了有力保障。"}, 
            {id:2,title:"公司总经理就下一步工作提出了具体要求，在今后的项目开展过程中，各级领导、各部门要一如既往的全力支持项目开展，为项目创造一切有利条件；团队要继续不断培养对数据的敏感性，养成用数据讲话的习惯，坚决杜绝经验主义；要放开眼界，科学对标，以行业最佳作为团队努力方向，努力使自身水平有质的提升；坚定实施六西格玛管理的信心，充分理解六西格玛管理为工具、为方法，不能误以为为了六西格玛而做项目，应发挥其为生产经营服务的真正作用。"}, 
            {id:3,title:"在不断变化和竞争日益激烈的商业环境中，六西格玛成为很多组织取得成功和持续发展的基石。然而，六西格玛的学习目前还只在初期阶段，需要靠大家持续投入时间、资源和承诺，在后期的学习中学会运用系统化和科学的方法来管理和改善，引导公司朝着高效、高质量的方向快速发展。"}, 
          ],
        }
      }
      else if(route.query.id == 78){
        state.dataList = {
          title: "传递爱心，传承知识，盛泰光电图书角正式开放啦！",
          date: "2023-10-31",
          img_1: require('@/assets/news/78/1.png'),
          content_1: [
            {id:1,title:"阅读是获取知识、启智增慧的重要途径，可以让人树立崇高理想，涵养浩然之气。为进一步丰富职工文化生活，营造书香四溢的文化氛围，10月24日，重庆市双桥经开区文化馆联络共青团盛泰光电委员会，共同举行“传递爱心，传承知识，助力全民阅读”赠书仪式。"}, 
            {id:2,title:"活动中，双桥经开区文化馆馆长郑学斌通过自身体会，语重心长勉励大家多读书，书籍是人类文明进步的阶梯，是传承传播知识文化最有效的载体，小时候就觉得读书是一件非常幸福的事情。本次捐赠虽然力量微薄，但能给更多的人带来前行的动力和知识的滋润，也是我们发起本次活动最大的成就感。"}, 
          ],
          content_2: [
            {id:1,title:"为了更好地服务于广大读者，深入开展国家公共文化服务体系示范项目,盛泰团委与双桥经开区文化馆联合设立了企业文化馆分馆与图书馆分馆，进一步推动职工书屋建设，为广大职工创造一个“爱读书、读好书”的良好环境。本次送来的书籍共100本，内容以儿童文学为主，公司员工可以在餐厅三楼的图书角免费扫码借阅并按规定归还。郑馆长还表示，盛泰光电企业员工有其他类型图书的需求可以提出，图书馆会统一为盛泰职工进行免费书籍采购并送至企业供大家阅读，同时，文化馆将不定期举办素质讲座或各种阅读推广活动，邀请企业职工积极参与，共同汲取精神力量。"}, 
            {id:2,title:"下一步，盛泰团委将与盛泰党支部一同深入推进全民阅读活动，将读书与团员青年主题教育相结合，引导广大青年读原著、悟原理，常学常新，充分展示新时代青年朝气蓬勃、奋发进取的良好精神面貌。"}, 
          ],
        }
      }
      else if(route.query.id == 79){
        state.dataList = {
          title: "力拔山河，绳彩飞扬，2023年盛泰光电拔河比赛圆满结束",
          date: "2023-11-1",
          img_1: require('@/assets/news/79/1.png'),
          content_1: [
            {id:1,title:"为丰富公司员工文化生活，进一步提升团队凝聚力，培养公司员工集体荣誉意识，10月25日至10月31日，盛泰光电工会委员会开展了“力拔山河，绳彩飞扬”2023年拔河比赛。"}, 
            {id:2,title:"双方队员紧握绳索，严阵以待，裁判一声令下，拔河比赛正式开始。此次比赛不仅是力量的角逐，更是团队精神和集体荣誉的展现舞台，以部门为单位，共8支队伍在比赛现场蓄势待发。 "}, 
            {id:3,title:"在激烈的拔河比赛过程中，双方参赛选手紧握长绳，使足力气拼命往后拉，呈微仰姿态，“一、二、一、二”齐声呼号，节奏分明。长绳中间的红带子时而缓慢向一侧移动，在将过界的时刻被对手奋起拉回；时而僵持在中间有来有往地来回飘动；时而被实力强劲的参赛队伍一鼓作气拉过界线、宣告获胜。"}, 
          ],
          content_2: [
            {id:1,title:"拔河场中激烈争，绳索牵动众心魂。比赛氛围热火朝天，各队拉拉队那份呐喊的热情力量似乎也传递到了参赛者手中。观众奋力为选手们加油鼓劲，两队选手全力以赴，激烈的竞争让围观者热血沸腾。"}, 
            {id:2,title:"咬定青山不放松，立根原在破岩中。咬紧牙关，挥汗如雨，这是我们心中的那份坚定和执着。比赛中，每位选手都激发出了自身潜力，不惜磨破手皮，也不愿意放弃手中的绳。"}, 
            {id:3,title:"最后留在决赛场上的四支队伍可谓实力相当，棋逢对手，难以一决高下。选手们的表现愈发精彩，每一局获胜都将现场氛围推向新高。他们步伐稳健，眼神坚定，全身心投入到了这场力量与毅力的较量中。选手们脚踏实地，身体后倾，几乎与地面平行，展现出了卓越的力量与技巧。每一次用力的拉扯，每一次艰难的迈步，都仿佛在挑战自己的身体极限。一刹那间，观众们的欢呼和加油声响彻云霄，如潮水一般涌来，一浪高过一浪。 经过激烈的角逐，冠军由SMT战队夺得，亚军为NB战队，季军为品质部战队。由衷的祝贺上述获奖的代表队伍，既是对生命的敬畏，也对自己的热爱。拔河是博弈，也是合作，蓬勃的朝气，矢志不渝地指向前方，这是属于我们的热烈时光。"}, 
          ],
        }
      }
      else if(route.query.id == 80){
        state.dataList = {
          title: "为爱同行，徒步公益行|盛泰光电徒步活动圆满结束",
          date: "2023-11-13",
          img_1: require('@/assets/news/80/1.png'),
          content_1: [
            {id:1,title:"为推动全民健身，鼓励员工参与体育运动，提倡健康的生活方式，同时提供一个交流互动的平台，引导员工积极参加公益活动，同时让员工在徒步中感受双桥的风光之美。11月12日上午八点半，盛泰光电工会委员会组织举办“为爱同行，徒步公益行”活动。"}, 
            {id:2,title:"冬已至，凉风习习，11月已是寒意乍起，清晨的风略显刺骨，活动当天飘起了小雨，但盛泰光电志愿者们依然相聚在活动集合点，以昂扬的斗志，寻风漫步，不畏寒意，勇毅前行。"}, 
          ],
          content_2: [
            {id:1,title:"一本书籍，一份爱心。早上8点半，盛泰光电志愿者们早早来到了花样龙水湖图书捐赠现场，气氛热烈，书香浓郁，大家将自己精心挑选的书籍进行依次捐赠。现场爱心捐赠的书籍种类繁多，有儿童绘本、中外名著、散文、科普类……本次活动共收集图书100余本。活动结束后，盛泰光电公司将会把这份书香之情传递到大足区邮亭镇海兰云天希望小学孩子们的手中。"}, 
            {id:2,title:"一程徒步，一段收获。捐书仪式结束后，大家在龙水湖开展环湖徒步行，都说寒风萧瑟天气冷，草木摇落露为霜，但是雨中的龙水湖却别有一番滋味，湿漉漉的小路上似乎能闻到泥土的芳香，在桥上极目远眺尽览湖中美景。参加徒步活动的志愿者们沿着美丽的龙水湖畔边走边欣赏，在践行“低碳生活，绿色出行”环保理念的同时，实地感受双桥经开区的快速发展。"}, 
            {id:3,title:" 是运动，更是筑梦，本次公益徒步是盛泰光电助力公益事业的又一次重要活动，用脚步感受城市温度，用爱心弘扬公益精神。未来，盛泰光电还将在公益道路上，初心如磐，奋楫笃行。"}, 
          ],
        }
      }
      else if(route.query.id == 81){
        state.dataList = {
          title: "拥抱自然，幸福采摘|员工生日采摘活动",
          date: "2023-11-20",
          img_1: require('@/assets/news/81/1.png'),
          content_1: [
            {id:1,title:"2023年第10月-11月员工生日会如期将至，11月正好是果冻橙成熟的季节，也是盛泰家人们初冬来临相聚的季节，不负韶光是场青春的聚会， 11月18日下午，生日会通过柑橘采摘的形式，给员工提供一个亲近大自然，体验采摘乐趣的机会，共同为寿星们送上生日祝福！"}, 
            {id:2,title:"活动当天，天气晴朗，参与活动的盛泰家人们来到果冻橙基地，看到满园橙子特别兴奋，树枝上挂满了饱满的果实，空气中充溢着泥土和果实的混合芳香。大家提着桶、拿着剪刀，立即投入到采摘活动中。"}, 
          ],
          content_2: [
            {id:1,title:"大家一边尽情地享受着乡村空气中的水果芳香，一边俯下身躯去采摘，在“橙子们”中百里挑一。园内处处伴随着欢声笑语，洋溢着轻松与和谐的氛围，平日里工作的疲劳都融化在这“暖暖”的冬日里，在汗水与收获的满足中，让员工们充分体验了一次采摘的乐趣。"}, 
            {id:2,title:"采摘活动结束后，大家在果园内席地而坐，共享野餐，各位寿星们相谈甚欢，分享着生活中的各种趣事，一时之间，现场氛围热闹非凡。"}, 
            {id:3,title:"采摘活动在大家的欢声笑语中圆满结束，每个人都满载而归。大家纷纷表示，对这次采摘活动非常满意，在丰收的季节，组织丰富多彩的采摘活动，既亲近了自然，体验了劳动的乐趣，又使大家的身心得到很好的调整。"}, 
            {id:4,title:"感谢各位寿星日复一日年复一年的无私奉献与奋力拼搏，成就了盛泰光电高质量发展的累累硕果。祝愿大家三冬温暖、岁月长安、心想事“橙”、马到“橙”功！"}, 
          ],
        }
      }
      else if(route.query.id == 82){
        state.dataList = {
          title: "广东深圳先进材料企业考察团一行莅临盛泰光电参观交流",
          date: "2023-11-24",
          img_1: require('@/assets/news/82/1.png'),
          content_1: [
            {id:1,title:"访企交流，聚力前行。11月21日上午，广东深圳先进材料企业考察团由重庆市大足区经信委引领，在重庆双桥工业园区主任彭学文领导陪同下，来盛泰光电参观交流，主要对围绕先进材料和智能网新能源汽车发展进行实地走访，盛泰光电公司单位负责人热情接待并陪同参观交流。"}, 
            {id:2,title:"企业负责人详细介绍了公司的发展史、产品构成、产品应用领域、产品生产工艺流程以及今后发展规划的相关情况。他们一边听取介绍，一边询问相关产品的原料来源和用途。"}, 
            {id:3,title:"来访考察团参观结束后，对盛泰光电科技创新、助力高质量发展表示肯定，并共同表示，希望未来加强交流,分享工作经验。期待能与企业合作共赢，互惠互利，共同成长。"}, 
          ],
        }
      }
      else if(route.query.id == 83){
        state.dataList = {
          title: "国家级《2023年5G工厂名录》公布，盛泰光电入选！",
          date: "2023-12-01",
          img_1: require('@/assets/news/83/1.png'),
          content_1: [
            {id:1,title:"据工信部官网消息，工业和信息化部办公厅29日发布关于印发《2023年5G工厂名录》的通知，公布了2023年5G工厂名录。其中，盛泰光电5G工厂、重庆格力电器5G工厂、重庆美的5G+工业互联网智能工厂等6个项目入选。"}, 
            {id:2,title:"5G工厂是充分利用以5G为代表的新一代信息通信技术集成，打造新型工业互联网基础设施，新建或改造产线级、车间级、工厂级等生产现场，形成生产单元广泛连接、信息（IT）运营（OT）深度融合、数据要素充分利用、创新应用高效赋能的先进工厂。"}, 
            {id:3,title:" 盛泰光电专注为全球客户提供“芯片+硬件+软件算法”一体化系统解决方案，致力于打造5G应用智能工厂，依托工业互联网平台、云计算、大数据、人工智能等新一代信息技术，重点打造摄像头模组制造链全过程8个5G应用场景，是公司敏捷供应链的重要载体。"}, 
            {id:4,title:"公司智能制造总体规划 + 问题 + 政府项目 + 校企合作，实际需求结合5G+工业互联网技术高带宽、低延时、快响应的特点，用5G+MEC替代传统工业WIFI和工业以太网，构建基于5G的创新工业应用场景。"}, 
          ],
          content_2: [
            {id:1,title:"车间以数字柔性生产为核心，以数据为中心，自动化控制、生产调度优化、资源计划管理多位融合的智能制造过程控制管理，实现生产设备网络化、生产数据可视化、生产过程透明化、生产管理数字化，生产计划协同化，生产过程实时化，物流配送准时化，质量管理追溯化，资源管理全面化。"}, 
            {id:2,title:"通过系统集成以及目视化电子看板管理，车间建有5G物联设备库位存储、模组化专业生产，通过CTU和AGV构建车间物流系统，打通物料运输，生产平衡动态调整、数字化工艺指导实现最小库存和高效配送。仓库自动配对提升发货效率，生产物料精准配送，物流全链路信息化、智能化管理，提升企业管理水平。"}, 
            {id:3,title:"基于Scada平台，并基于Web设计器构建Web远程控制台，实现远程启停和调节。MES系统通过控制平台在生产过程中对设备进行自动控制，通过MES系统进行计划管控，工序排产，各工位上线智能终端，提高产品合格率，实现生产过程数据的传输与共享，使生产过程可视化，显著提高生产效率，提高车间的生产平衡率。"}, 
            {id:4,title:"运营数字中心，通过看板实时掌握现场整体运行情况，及时准确识别异常，快速响应、快速决策，提升整体运营效率。以供应敏捷化、制造柔性化、决策智能化为标准，建立智慧工厂生产体系，通过智能化设备和数字化平台系统的建设，数据全面监控可查，全流程质量信息采集和问题闭环管理，提升产品质量，提升制造能力。"}, 
            {id:5,title:"下阶段，盛泰光电将继续构建多个场景建设，加快推进“5G+工业互联网”创新发展，加快企业转型升级，不断提升企业发展水平和核心竞争力，形成精密摄像头模组创新标杆工厂。"}, 
          ],
        }
      }
      else if(route.query.id == 84){
        state.dataList = {
          title: "袁家军在盛泰光电调研时强调 更好服务企业服务群众 以主题教育成效推动高质量发展",
          date: "2023-12-05",
          img_1: require('@/assets/news/84/1.png'),
          content_1: [
            {id:1,title:"12月4日，重庆市委书记袁家军前往荣昌区、大足区调研产业发展等工作，指导开展第二批主题教育。他强调，要全面落实党的二十大部署，深入贯彻习近平总书记重要指示精神，紧扣渝西地区战略定位，发挥区县特色优势，加快构建现代化产业体系，提升基层治理现代化水平，更好服务企业服务群众，以主题教育实际成效推动高质量发展，为全市发展大局贡献更多力量。"}, 
            {id:2,title:"盛泰光电科技股份公司是智能网联新能源汽车、新一代电子信息制造业集群关键零部件生产企业。袁家军观看产品展示，听取公司发展情况介绍。看到企业发展动能强、势头好，袁家军十分高兴。他指出，光电产业发展前景广阔，要抢抓智能制造新机遇，拓展产品应用场景，增强核心竞争力，加快发展壮大。要扎实推动制造业与服务业深度融合发展，创新丰富企业服务模式和商业模式，持续拓展发展空间，不断提升产品质量，满足人民群众高品质生活需求。"}, 
            {id:3,title:"下阶段，盛泰光电将积极融入成渝地区双城经济圈建设，加快企业转型升级，不断提升企业发展水平和核心竞争力，形成精密摄像头模组创新标杆工厂。"}, 
          ],
        }
      }
      else if(route.query.id == 85){
        state.dataList = {
          title: "消防“零”距离，安全记在心！盛泰光电开展2023年下半年消防演练",
          date: "2023-12-19",
          img_1: require('@/assets/news/85/1.png'),
          content_1: [
            {id:1,title:"为了认真落实公司火灾事故的防范措施，增强全员的消防安全意识，消除火灾安全隐患，最大限度减少损失，确保安全生产。加强公司火灾安全事故的应急工作，让全员懂得基本消防知识和自救技能，12月18日盛泰光电组织策划了一次消防演习。"}, 
            {id:2,title:"12月18日下午17点，公司消防演练火情警报声响起，参演人员在疏散引导组成员的组织下有序安全撤离； 本次消防演习的工作人员分为义务消防队、医疗救护组、疏散引导组、摄像拍照组、警戒秩序组、后勤保障组、动力组七个小组；并对各小组的工作内容进行了详细分配说明，由各小组组长负责落实推进；同时组织了主管级领导消防演习动员大会以及消防培训，明确了各部门负责人职责要求，各部门主管负责组织带领分散逃生，并宣导消防演习中严格遵守安全注意事项，避免安全事故的发生。"}, 
          ],
          content_2: [
            {id:1,title:"演习场地选在公司小广场，为了确保万无一失，后勤保障组准备了2个火桶，模拟车间火灾着火点和消防器材实操人员使用灭火器留有一定的安全距离并且是站在上风或者侧风位置进行灭火，要求分组进行实操消防灭火器材灭火。"}, 
            {id:2,title:"本次消防演习还开展了应急救援措施与成人心肺复苏的培训，此次培训意义重大，它能提高员工的应急救援能力，增强员工的自救互助意识，减少突发事件的损失，着力培养员工“懂应急、会应急、能应急”的急救意识。"}, 
            {id:3,title:"通过这次的消防演习，使全体员工了解和掌握如何识别危险、如何采取必要的应急措施、如何报警、如何安全疏散人员，如何自救急救等基本操作，从而提高了全体员工的安全意识和素质，确保应急行动高速有效的完成。员工通过亲身参与，一定程度上对火灾事故灭火抢险和正确使用灭火器材的技能有了进一步的实操体验。"}, 
          ],
        }
      }
      else if(route.query.id == 88){
        state.dataList = {
          title: "岁末寒冬送温暖 关心关爱暖人心",
          date: "2024-2-7",
          img_1: require('@/assets/news/88/1.png'),
          content_1: [
            {id:1,title:"2月4日下午，盛泰光电科技股份有限公司党支部书记、工会主席彭世雨带队，走访慰问大足区龙岗街道龙岗村80岁以上空巢、老弱病残老人及留守儿童。慰问组与他们亲切交谈，详细了解了其身体和生活状况，将爱心礼物111份食用油、米、牛奶等慰问品分别送到她们手中，传递盛泰光科党支部、工会的关怀与温暖，并向她们致以新年的问候和美好的祝福。"}, 
          ],
          content_2: [
            {id:1,title:"自盛泰光电科技股份有限公司党支部和工会成立以来，始终围绕“关爱困难儿童、助力健康成长”的宗旨，积极作为。关心关注留守儿童的学习、生活和情感，引导他们树立积极向上的人生态度，让留守儿童真切感受到“党员妈妈”常在身边，温暖常在身边。"}, 
             ],
        }
      }
      else if(route.query.id == 90){
        state.dataList = {
          title: "共同书写携手并进、合作共赢的高质量发展新篇章|眉山市洪雅县党政代表团来盛泰考察",
          date: "2024-2-26",
          img_1: require('@/assets/news/90/1.png'),
          content_1: [
            {id:1,title:" 2月26日上午，四川省眉山市洪雅县党政代表团来盛泰考察。大足区徐晓勇区长温暖陪同，双桥经开区管委会领导周树云书记、曾华主任、经发局陈瑜局长等参加，公司单位负责人热情接待并陪同参观交流。"}, 
           ],
          content_2: [
            {id:1,title:"代表团一行首先来到盛泰公司展厅，观看了公司企业宣传片，听取了单位负责人对公司企业文化、产品及工艺流程介绍等，随后代表团成员参观SMT、FOL、EOL车间时，认真察看各类新技术、新设备、新产品，询问了解集聚式产业布局模式推进情况和全产业链优化升级情况等，表示要学习借鉴盛泰光电经验，坚持以项目为中心，以人才为基础，持续深化创新驱动，不断优化营商环境，探索加强数字赋能，狠抓落实建圈强链，更好发挥龙头企业引领作用，多措并举推动经济高质量发展。"}, 
            {id:2,title:"参观结束时，单位负责人表示，此次眉山市洪雅县党政代表团到盛泰考察，既是交流互动，更是传经送宝，给盛泰人提供了难得的学习机会。希望双方以此次活动为新的起点，在两地交往中互学互鉴，在重点领域中互联互通，在经济发展中互惠互利，共同书写携手并进、合作共赢的高质量发展新篇章。"}, 
           ],
        }
      }
      else if(route.query.id == 91){
        state.dataList = {
          title: "农业银行重庆分行领导一行莅临盛泰光电参观指导",
          date: "2024-3-1",
          img_1: require('@/assets/news/91/1.png'),
          content_1: [
            {id:1,title:"2024年2月29日下午，中国农业银行重庆市分行纪委书记夏晓东，率领重庆市分行总经理伍英、大足支行行长李小平、大足支行副行长吴刚、大足支行公司部经理李昕蓉等领导一行莅临盛泰光电参观指导。公司单位负责人对农业银行领导一行的莅临表示热烈的欢迎，引导参观了公司展厅、生产车间、研发中心、信息中心，参观期间领导们进行了深度交流。"}, 
           ],
          content_2: [
            {id:1,title:"单位负责人代表公司对农业银行给予盛泰光电产业的大力支持表示衷心感谢，全面介绍了公司的发展历程、竞争优势及核心经营理念，就盛泰光电产业经营情况和战略发展规划作了汇报。"}, 
            {id:2,title:"农业银行重庆分行纪委书记夏晓东一行对生产现场进行全面走访，深入调研了公司生产工艺、技术水平及经营情况，对盛泰光电产业研发、制造、市场及经营发展等方面给予高度的认可和赞许，表达了支持民营企业、支持实体经济的决心，并对企业的发展提出合理化建议。"}, 
           ],
        }
      }
      else if(route.query.id == 92){
        state.dataList = {
          title: "2024盛泰大讲堂《以客户为导向的企业战略与运营管理》",
          date: "2024-3-20",
          img_1: require('@/assets/news/92/1.png'),
          content_1: [
            {id:1,title:"为了充实战略管理知识储备，提升实践实战能力，明确战略目标，统一战略思想，盛泰光电于2024年3月16日-17日组织开展为期两天的《以客户为导向的企业战略与运营管理》专项培训，邀请到了前华为高管苏先生到我司授课交流。"}, 
            {id:2,title:"战略管理能力是经营者领导力的重要体现，也是影响企业经营的关键所在。本次培训，苏老师从战略管理基本概念、管理解析、趋势分析、方向设定、基本意图、总体构思到实际应用等方方面面进行了深入浅出的讲解。从“点亮”企业经营的本质与战略管理的关键痛点出发，苏老师引用了大量实践工作经验和案例，仔细介绍了IPD、ISC、LTC对应的管理体系流程、工具和方法，并通过分组研讨，指出了公司目前在业务经营领域存在的问题和短板，从产品力、交付力、销售力等多方面、多角度对如何提升经营能力给出了相应的意见和建议。"}, 
          ],
          content_2: [
            {id:1,title:"两天的培训收获颇丰，为了促进知识的内化，更好地做到学以致用，公司在培训结束后组织召开了内部交流分享会。总经理苏启雄先生通过视频案例，对干部的使命与责任、标准与选拔、评价与激励等方面做出指导和要求，重点对企业文化的建设方面加强了宣导，提出了新的规划，并告诫大家要克服麻痹思想和侥幸心理，抓好源头管理，坚持洁身自好，正确对待和行使手中的职责，做一名合格的管理者。同时，大家对两天来的学习内容进行了感悟分享，并结合所学对公司尚且存在的不足和弱项提出改善方向。"}, 
            {id:2,title:"战略的执行是一个连续的过程，把战略和运营高效契合起来的过程也是知行合一的过程。我们要朝着共同的使命和愿景，聚焦资源，深度思考和复盘；保持开放、归零的心态，不断提高认知水平，进而有效强化组织建设和行业竞争力，实现公司的商业成功和长期生存。"}, 
          ],
        }
      }
      else if(route.query.id == 93){
        state.dataList = {
          title: "春暖三月，筝筝日上||盛泰光电2-3月员工生日会圆满举行",
          date: "2024-3-25",
          img_1: require('@/assets/news/93/1.png'),
          content_1: [
            {id:1,title:"春意盎然，繁花似锦，又到了一年之中最温暖明媚的三月时节，三月的春风暖意融融，正是放风筝的好时节。在这个充满生机与活力的春天里，3月23日下午，我们迎来了一场特别的生日庆祝活动，一起共度这一难忘时刻，在绿草如茵的大地上，我们一起追逐春风，放飞手中的五彩风筝，共享童年的欢乐时光和对自由天空的向往。当风筝在蔚蓝天空中翱翔之际，我们在欢声笑语中为2-3月的寿星献上最真挚的祝福，在蓝天白云下，携手共绘一幅绚丽的春日画卷！"}, 
            ],
          content_2: [
            {id:1,title:"阳光正好，微风正佳，承载着温馨与欢乐，轻风托纸鸢，扶摇上青天。在和煦的春风中，寿星们一起在草坪上放飞风筝，风筝在高空飞舞，大家互相交流着放风筝的技巧，比试谁的风筝飞得更高，仿佛回到了快乐的童年时光，成为了一道亮丽的风景线。"}, 
            {id:2,title:"生日会在欢声笑语中画上圆满句号，但我们与春天的故事才刚刚开始。愿我们如那高飞的风筝，在广阔的天空中自由翱翔似那美味的蛋糕，在舌尖上留下春天的甜蜜，让我们继续书写与春天的约定，用诗意描绘生活的画卷。让我们继续保持对生活的热爱，对工作的热情，共同创造更多美好的回忆，携手迎接每一个绚丽的明天。"}, 
          ],
        }
      }

      else if(route.query.id == 94){
        state.dataList = {
          title: "盛泰光电入选2023年度市级工业设计中心名单",
          date: "2024-4-2",
          img_1: require('@/assets/news/94/1.png'),
          content_1: [
            {id:1,title:"近日，市经济信息委公布了2023年度市级工业设计中心名单，盛泰光电科技股份有限公司等42家企业入选。盛泰光电也将在大足区政府的支持下，积极融入成渝地区双城经济圈建设，加快企业转型升级，不断提升企业发展水平和核心竞争力，形成精密摄像头模组创新标杆工厂。"}, 
            ],
          content_2: [
            {id:1,title:"未来，盛泰光电将持续推动市级工业设计中心建设，不断提升设计创新能力和服务水平，为全市制造业“带来好设计、制造好产品、实现好效益”。同时也会扎实推动制造业与服务业深度融合发展，创新丰富企业服务模式和商业模式，持续拓展发展空间，不断提升产品质量，满足人民群众高品质生活需求。"}, 
          ],
        }
      }
      else if(route.query.id == 95){
        state.dataList = {
          title: "千里驰援，盛泰支援队用实际行动践行企业使命",
          date: "2024-4-2",
          img_1: require('@/assets/news/95/1.png'),
          content_1: [
            {id:1,title:" 在春节后，盛泰光电华南地区的合作伙伴出现短期的困难，对于客户交付有些影响，盛泰光电充分发扬团结协作、互助共赢、与合作伙伴共同发展的企业精神，于2月26日派遣了一支由江西工厂和重庆工厂组成的支援团队，这个支援团队由50余名优秀的员工组成，他们技术精湛、经验丰富且反应敏捷，分别从重庆工厂和江西工厂奔赴合作伙伴在华南地区的工厂进行实地支持。"}, 
            ],
          content_2: [
            {id:1,title:"在这段期间，盛泰员工们克服了种种困难，凭借扎实的专业技能和坚韧不拔的精神风貌，积极投入到零件生产的各个环节，有效地解决了合作伙伴所面临的困难和问题，极大地降低了潜在风险，有力地保障了生产线的正常运行，确保了客户端的顺利交付。他们全力以赴，夜以继日，为的就是确保客户服务的质量和产品交付的时效，始终坚守着对客户承诺的那份诚信与执着。"}, 
            {id:2,title:"这次跨公司的协同作战，不仅体现了盛泰光电科技股份有限公司与合作伙伴公司之间深厚的战略合作关系，更彰显了我们共同致力于提供最优服务给客户的坚定信念和无比决心。我们深知，每一次成功交付的背后，离不开供应商们的鼎力支持，离不开盛泰员工们的辛勤付出，更离不开广大客户对我们坚定不移的信任与期待。"}, 
            {id:3,title:"在此，我们向参与此次支援任务的50余名员工表示深深的敬意和感谢，他们的辛勤付出和无私奉献是我们公司宝贵的精神财富；最后，我们要感谢所有信任并选择我们的客户，你们的支持与理解是我们不断前进的动力源泉。让我们携手共进，共创辉煌！"}, 
          ],
        }
      }
      else if(route.query.id == 96){
        state.dataList = {
          title: "西婵小学小记者走进盛泰光电，探寻新时代科技企业发展之路",
          date: "2024-4-3",
          img_1: require('@/assets/news/96/1.png'),
          content_1: [
            {id:1,title:"科技之光，点亮生活新篇章。4月2日上午，大足区西禅小学的小记者梁宸霖，怀揣着对“少年向上，传承红岩精神，争做时代新人”的深度思考与探究之心，走进了盛泰光电科技股份有限公司，以独特的视角和敏锐的洞察力，实地探访了盛泰光电如何将红岩精神融入现代科技发展，激励新一代青少年积极向上、勇担使命。"}, 
            ],
          content_2: [
            {id:1,title:"梁宸霖参观了公司的科技展厅和生产车间，在这里，她看到了科研人员们严谨求实的工作态度，以及他们面对技术难题时展现出的百折不挠、勇于突破的精神风貌。一位研发工程师向她表示到：“我希望新时代少年儿童能扎实学好科学文化知识，传承重庆红岩战士们百折不挠的民族气节，将来能为国家解决更多的科技难题。”"}, 
            {id:2,title:"采访结束之际，小记者梁宸霖通过此次探访，深深理解到，红岩精神并非遥远的历史回响，而是活生生地根植于我们的日常生活与工作中。新时代少年要将红岩精神内化于心，外化于行，刻苦学习科学知识，培养创新思维，将来为我国的科技进步贡献自己的力量。同时，也希望更多的青少年朋友能深入了解并传承红岩精神，共同肩负起时代赋予我们的责任，争做有理想、有担当的时代新人。"}, 
          ],
        }
      }
      else if(route.query.id == 97){
        state.dataList = {
          title: "绿色行动 · 守护五档山——盛泰光电公益行活动圆满",
          date: "2024-4-15",
          img_1: require('@/assets/news/97/1.png'),
          content_1: [
            {id:1,title:" 4月14日下午两点，阳光明媚，春风和煦。在这充满生机的季节里，盛泰光电志愿者队伍与双桥经开区小微志愿服务队强强联手，共同奔赴五档山，展开了一场意义深远的公益捡垃圾行动，以实际行动践行绿色理念，守护自然生态，为美化环境、提升公众环保意识贡献了一份力量。"}, 
            {id:2,title:" 此次联合公益活动在五档山脚下正式启动。两支队伍身着志愿者马甲，精神抖擞，脸上洋溢着对环保事业的热情与决心。盛泰光电志愿者队伍代表首先发表讲话，强调了企业社会责任与环保公益的紧密联系，表示将一如既往地支持并参与各类环保活动，积极倡导绿色生活方式。随后，双桥经开区小微志愿服务队负责人也表示，对双方的合作表示高度赞赏，并呼吁更多社会力量加入到保护环境的行列中来。"}, 
            ],
          content_2: [
            {id:1,title:"随着一声令下，志愿者们手持垃圾袋、垃圾夹等工具，沿着蜿蜒的山路有序展开清理工作。他们目光敏锐，不放过任何一处角落，无论是草丛中的塑料瓶、烟蒂，还是隐藏在石缝间的食品包装袋，都被一一拾起，装入垃圾袋中。尽管汗水浸湿了衣衫，但大家热情不减，相互鼓励，全身心投入到这场净化山林的“战斗”之中。这一幕幕场景，生动诠释了“奉献、友爱、互助、进步”的志愿精神，也展现了志愿者们对美好生态环境的执着追求。"}, 
            {id:2,title:"下午四时许，经过两个多小时的辛勤努力，五档山的面貌焕然一新，志愿者们手中的垃圾袋满载而归。他们在山脚集合，将所收集的垃圾放置在统一清运点，以便后续进行妥善处理。此刻，志愿者们的脸上虽略显疲惫，但更多的是完成使命后的满足与喜悦。"}, 
            {id:3,title:"此次活动的成功举办，不仅有效改善了五档山的环境卫生，更彰显了盛泰光电与双桥经开区小微志愿服务队在环保公益领域的深度合作与积极作为。以实际行动呼吁社会各界关注环保问题，倡导绿色生活，共同构建美丽家园。我们期待未来有更多这样的公益行动涌现，让更多人参与到环保事业中来，共同守护我们的绿水青山。"}, 
          ],
        }
      }
      else if(route.query.id == 98){
        state.dataList = {
          title: "坚持预防为主，守护职业健康 | 盛泰光电荣获“重庆市健康企业”荣誉称号",
          date: "2024-4-27",
          img_1: require('@/assets/news/98/1.png'),
          content_1: [
            {id:1,title:"2024年4月26日上午，大足区卫生健康委在盛泰光电科技股份有限公司成功举办了一场主题鲜明、内容丰富的职业健康活动。本次活动旨在推动大足区健康企业建设，提升广大劳动者的职业健康意识，营造积极向上的健康工作氛围。"}, 
            {id:2,title:"活动在大足区卫生健康委领导的精彩致辞中拉开序幕。领导强调了职业健康的重要性，指出“健康是促进经济社会发展的基础，也是每个人全面发展的前提”。领导肯定了盛泰光电在过去一年里，推进健康企业建设方面取得的显著成效，并对新的一年提出了更高的期许，呼吁各企业及全体职工共同努力，将职业健康理念融入日常工作生活，打造更健康、更安全的工作环境"}, 
            {id:3,title:"活动的亮点环节之一是为“2023年健康企业”授牌。经过严格评选，我司在职业健康管理、员工健康促进、安全生产等方面表现突出，被授予“重庆市级健康企业”荣誉称号。"}, 
            ],
          content_2: [
            {id:1,title:"紧接着为在日常工作中积极践行健康生活方式、传播职业健康知识的“健康达人”颁发荣誉证书，在本次名单中安保工程课陈英虎同事获得了“健康达人”的称号。表彰“健康达人”们在维护自身及他人健康方面的突出贡献。他们的存在，如同点点星光，照亮了职业健康之路，引领着广大职工追求健康、快乐的工作生活。"}, 
            {id:2,title:"活动期间，专业人员还进行了正确佩戴防护用品用具的现场演示，直观生动地展示了如何在工作中有效保护自身安全，提升了大家的防护技能。同时，活动现场发放了各类职业健康宣传资料，内容涵盖职业病防治、健康生活方式、应急处置等多个方面，旨在进一步普及职业健康知识，增强公众自我防护能力。"}, 
            {id:3,title:"最后，大足区卫健委贴心安排了义诊义检服务，邀请医疗专家为参会人员提供免费健康咨询和体检服务，让每一位参与者都能切实感受到职业健康关怀的温度。"}, 
            {id:4,title:"此次职业健康活动的成功举办，不仅提升了我司的职业健康意识，也标志着我司在推进职业健康工作上迈出了坚实一步。未来，盛泰光电将继续深化健康企业建设，全力保障劳动者的职业健康权益，助力打造“健康职场”，为构建和谐劳动关系、推动经济社会高质量发展注入强劲动力。"}, 
          ],
        }
      }
      else if(route.query.id == 99){
        state.dataList = {
          title: "盛泰光电第三届钓王大赛圆满结束",
          date: "2024-5-15",
          img_1: require('@/assets/news/99/1.png'),
          content_1: [
            {id:1,title:"在这风和日丽的初夏早晨，盛泰光电科技股份有限公司的景观湖畔迎来了一场别开生面的盛会——盛泰光电第三届钓王大赛！于5月12日早上7点准时拉开序幕，直至11点，在这黄金四小时里，35位来自不同部门的钓鱼高手，齐聚一“塘”，共同展现了一场智慧与耐心的较量，也为这个周日增添了一抹生动的色彩。"}, 
            {id:2,title:"清晨7点，选手们摩拳擦掌，蓄势待发，准备在比赛中一展风采。赛前，裁判宣读了比赛规则，进行了钓位抽签。随后随着裁判员一声号响，参赛选手们摆开阵势，打开钓竿，迅速挂上鱼饵，纷纷抛竿。他们中有经验丰富、动作娴熟的钓鱼老手，也有刚刚接触钓鱼的新人，选手们各显身手，不亦乐乎。"}, 
            ],
          content_2: [
            {id:1,title:"比赛过程中，各参赛选手展现出不凡的垂钓技术，尽情享受竞钓的乐趣。“上鱼啦！”，一条条鲜活的鱼被收进鱼护。每当有“重大”收获时，选手们的喜悦之情溢于言表，还会引来旁边垂钓者的赞叹声和欢呼声。"}, 
            {id:2,title:"在紧张而充满期待的钓鱼比赛中，时间还未跨过一小时的门槛，行政部杨洪兵凭借他精湛的垂钓技巧，迅速地钓起了一条活蹦乱跳的鱼，这一成就令在场的所有人惊叹不已，毫无悬念地将最快上鱼奖收入囊中，经过四个小时的激烈角逐，SMT胡仕迁凭借精湛的钓鱼技艺和过人的耐心，以22斤渔获的骄人战绩脱颖而出，荣获本届钓王大赛的冠军！IE课唐权和行政部杨洪兵分别以15斤和14斤的渔获分获亚军和季军。在颁奖仪式上，大家对获奖选手表示热烈祝贺，并表示钓王大赛不仅丰富了员工的业余文化生活，还锻炼了大家的意志，增强了团队协作精神。让大家在轻松愉快的氛围中增进友谊、交流感情。"}, 
            {id:3,title:"此次盛泰光电第三届钓王大赛圆满落幕，不仅展示了公司员工的风采和实力，也彰显了企业文化的独特魅力。让我们期待下一届钓王大赛的到来，共同见证更多精彩瞬间。"}, 
          ],
        }
      }
      else if(route.query.id == 100){
        state.dataList = {
          title: "盛泰光电携产品参加第六届中国西部国际投资贸易洽谈会",
          date: "2024-5-28",
          img_1: require('@/assets/news/100/1.png'),
          content_1: [
            {id:1,title:"5月24日上午，第六届中国西部国际投资贸易洽谈会在重庆国际博览中心开幕。本次西洽会以“新西部、新制造、新服务”为主题，来自40个国家和地区以及国内27个省区市的1700余家企业参展参会。"}, 
            ],
          content_2: [
            {id:1,title:"盛泰光电作为大足区智能制造龙头企业，此次参展的产品有手机摄像头、笔电摄像头、AI摄像头、无人机、车载等模组零部件。"}, 
            {id:2,title:"盛泰光电将秉持创新、智能、绿色、开放的办展理念，突出展览与发布相结合、线下与线上相结合，增强西洽会产业集聚效应、经贸合作效应、引领带动效应。"}, 
          ],
        }
      }
      else if(route.query.id == 101){
        state.dataList = {
          title: "创新突破稳定品质，落实管理提高效率——2024年盛泰光电六西格玛终期评审工作落幕",
          date: "2024-5-28",
          img_1: require('@/assets/news/101/1.png'),
          content_1: [
            {id:1,title:"2024年5月25日，盛泰光电重庆公司第一期精益六西格玛项目终期评审会顺利举行。本次评审会标志着公司在推行精益六西格玛管理方面取得了重要阶段性成果，为今后的持续改进和发展奠定了坚实基础。评审委员会由六西格玛资深专家、公司总经理及运营、品质、研发等相关部门领导组成，共同对项目的计划、进展、质量和成果进行了全面的专业评估。"}, 
            {id:1,title:"六西格玛管理法是一套科学的工具和经营管理策略，能够帮助企业降低劣质成本，增强应对市场的抗风险能力，塑造长期产品质量竞争力。推进六西格玛项目是公司进一步提升质量管理、全面挖掘质量效益的重要举措。本次专项计划从2023年6月导入，在近一年的教学与实践中逐步扩大实施范围，深化实施成果，通过基础知识概述、案例分析、现场互动等培训方式交替展开，加快促进学员们对知识的汲取，并将所学理论、技能、方法、工具运用到实际工作中。在项目组成员和相关部门领导及同事的共同努力和帮助下，第一期六西格玛项目取得了不错的成果。"}, 
            ],
          content_2: [
            {id:1,title:"评审会上，各项目小组运用数据分析，展示项目实施前后的关键指标变化情况；运用图表和实例，展示具体的改进效果，包括缺陷率降低、生产效率提升和成本节约等。六西格玛评审专家组对项目的实施情况和成果进行了专业点评，指出了亮点和不足之处，就项目的后续改进措施和推广计划进行了讨论，提出了指导意见。同时，本次评审对项目推进过程中表现优异的成员进行了表彰，并选出了两组最佳改善项目进行嘉奖。"}, 
            {id:1,title:"评审会结尾，六西格玛专项指导老师对第一期的工作做出总结，他指出，项目的成功不仅体现在数据和指标的改善上，更重要的是培养一支具备精益思维和技能的团队。成功的项目需要构建横向打通关的能力，通过跨部门协作，项目能够更有效地解决问题，实现全流程优化；通过项目的推进和复盘，可以清晰地发现哪些部门或个人是积极增值的，哪些是拖后腿的；通过赋能并推广正确的理念和方法，提升员工发现问题和解决问题的能力；通过持续的项目实施和改进，企业才能保持竞争优势，实现长久发展。同时，老师还提出，在项目改进的过程中，我们应不断完成从个体能力到团队能力的转变、从独立部门到协同作战的转变、从设计导向到制造导向的转变、从执行驱动到卓越执行的转变，从而促使我们进一步加快实现从低端加工向高端制造的丝滑转变。"}, 
            {id:1,title:"六西格玛是一个持续改进的过程，成功的项目只是一个新的起点，未来，我们将继续推动六西格玛的理念和方法在公司内部的深度融合，不断优化流程，提升产品质量和客户满意度。同时，我们也将积极响应各方建议，进一步加强内部协作，持续提升团队综合素质和执行力，为实现公司的战略目标和长期发展做出更大的贡献。"}, 
          ],
        }
      }
      else if(route.query.id == 102){
        state.dataList = {
          title: "浓情端午·共庆生辰",
          date: "2024-6-7",
          img_1: require('@/assets/news/102/1.png'),
          img_2: require('@/assets/news/102/2.png'),
          content_1: [
            {id:1,title:"在这个充满传统文化韵味与温馨祝福的季节，盛泰光电科技股份有限公司于6月6日下午，精心筹备了一场别开生面的端午节暨第二季度员工生日会。在这特别的日子里，共有54位家人欢聚一堂，共同参与这场意义非凡的庆典，我们不仅庆祝了传统节日的传承，更是一同见证了同事们的成长与喜悦。"}, 
            {id:2,title:"活动现场，色彩斑斓的颜料与古朴典雅的纸扇相遇，每位参与者都化身为艺术家，用灵感勾勒图案，用色彩填充想象。从构思到滴墨，从选材到成品，每一把漆扇都独一无二，不仅承载了制作者的巧思与情怀，更成为了连接古今文化的一座桥梁。这不仅是一次手作的乐趣体验，更是一场关于美与创造的心灵之旅，让传统文化在现代职场生活中绽放出新的活力与光彩。"}, 
            ],
          content_2: [
            {id:1,title:" 随后，我们迎来了紧张刺激的端午射五毒活动。这项源自古老习俗的游戏，象征着驱邪避害，祈求平安。六人一组，各显神通，通过团队协作与个人技巧的完美结合，展开了一场智慧与勇气的较量。积分榜上，竞争激烈，但无论输赢，每个参与者脸上洋溢的笑容，都让这份竞技多了几分温情与欢乐。最终，前三名的队伍凭借出色的表现，赢得了精美的奖品，更是收获了满满的成就感与团队荣誉感。"}, 
          ],
          content_3: [
            {id:1,title:" 在欢声笑语中，我们迎来了活动的高潮。大家围坐一堂，共享美味的蛋糕与粽子，共同许下了美好的愿望。这一刻，不仅是对过往岁月的回顾，更是对未来无限可能的憧憬。盛泰光电不仅是一个工作的地方，更是一个充满爱与关怀的大家庭，每一位成员的成长与进步，都是我们共同的骄傲。让我们记住这个特别的日子，它不仅是一次文化的传承，更是一场心灵的盛宴。盛泰光电将继续携手每一位家人，共创美好未来，让我们的故事更加精彩！"}, 
          ],
        }
      }
      else if(route.query.id == 103){
        state.dataList = {
          title: "盛泰光电与极米科技深化战略合作，共绘未来智能视界新蓝图",
          date: "2024-6-22",
          img_1: require('@/assets/news/103/1.png'),
          content_1: [
            {id:1,title:"极米科技股份有限公司（以下简称“极米科技”）与盛泰光电科技股份有限公司（以下简称“盛泰光电”）共同宣布，双方在智能显示领域达成深度合作共识，标志着两家公司在未来产品研发及市场拓展方面迈向全新阶段。随着本次深度合作的启动，极米科技与盛泰光电将共同探索智能显示技术的边界，携手开发更多具有市场竞争力的创新产品，为消费者带来更多惊喜，引领智能显示行业的未来发展潮流。"}, 
            ],
          content_2: [
            {id:1,title:"2024年6月20日，在盛泰光电总部举行的简短而庄重的仪式上，极米科技赠送了一台极米旗舰产品——RS 10 Ultra给盛泰光电，作为双方紧密合作的象征。该款投影仪不仅体现了极米科技在智能显示领域的卓越技术实力，更值得一提的是，它搭载了由盛泰光电自主研发的两款高性能iTOF光学模组，全面赋能极米Eagle Eye鹰眼计算光学，这不仅是对盛泰光电技术创新的认可，也是双方技术融合的典范之作。"}, 
            {id:2,title:"强强联合，共筑智能显示新时代。此次合作的深化，不仅是两家公司技术实力与市场战略的高度契合，更是双方对未来智能显示市场的共同愿景。极米科技与盛泰光电将携手在产品创新、技术研发、市场开拓等多个维度展开更为广泛且深入的合作，旨在为消费者提供更加丰富、智能、高品质的视觉体验。"}, 
            {id:3,title:"盛泰光电致力于成为智能显示领域的核心供应商，与极米科技的合作是基于双方对智能显示领域未来的共同信念，将极大推动我们的技术革新与市场拓展。我们坚信，通过双方的共同努力，必将探索更多可能，为消费者创造前所未有的视觉盛宴，开创智能显示行业的崭新篇章。"}, 
            {id:4,title:"极米科技股份有限公司于2013年在成都高新区创立，是一家专注于新型显示领域，重点打造智能投影和激光电视等创新产品，集设计、研发、制造、销售和服务于一身的高新科创企业。2021年3月，极米科技成功登录上交所科创板。"}, 
            {id:5,title:"自成立以来，公司一直保持高增长速度发展，以提升产品用户体验为目标进行深入研发，在光机设计、硬件电路设计、整机结构设计、智能感知算法开发、画质优化算法开发、软件系统开发等方面掌握了多项核心技术和能力， 大幅提高了智能投影设备的性能和用户体验。根据IDC数据显示，自2018年打破外资品牌在中国投影机市场垄断之后，极米连续五年半获得出货量第一、连续三年半获得出货量和销售额双第一，并拉开了与跟随者的差距，在智能投影行业整体处于龙头地位。"}, 
            ],
        }
      }
      else if(route.query.id == 104){
        state.dataList = {
          title: "盛泰光电2024上半年消防安全演练圆满成功",
          date: "2024-7-2",
          img_1: require('@/assets/news/104/1.png'),
          content_1: [
            {id:1,title:"2024年6月28日下午17:00时，我司组织了一场规模宏大的消防应急演练活动，旨在提升全体员工的消防安全意识及应对突发事件的能力。本次活动由行政部主导策划，确保演练的顺利进行。"}, 
            {id:2,title:"演练模拟了办公区突发火情的场景，随着警报声响起，全体员工迅速响应，按照预先制定的应急预案有序疏散至安全集合点。在模拟火源处，公司义务消防队成员穿戴专业防护装备，展示了灭火器的正确使用方法，并成功扑灭了“火源”。整个过程紧张有序，充分体现了盛泰光电员工们高效的应急反应能力和良好的协同作战精神。"}, 
          ],
          content_2: [
            {id:1,title:"演练结束后，行政部负责人对本次消防演练给予了评价，指出这不仅是一次对现有应急预案的检验，更是对全体员工安全素养的一次实战提升。鼓励大家将演练中学到的知识运用到日常工作中，共同维护公司及个人的安全。"}, 
            {id:2,title:"此外，活动还设置了互动问答环节，员工们积极提问，消防专家现场答疑解惑，进一步巩固了消防安全知识的学习成果。盛泰光电通过此次演练，不仅增强了员工的消防安全意识，也为构建更加安全和谐的工作环境奠定了坚实基础。盛泰光电将继续秉持“安全第一，预防为主”的原则，定期举办此类安全教育活动，不断提升全体员工的安全防范和应急处置能力，确保公司的持续稳定发展。"}, 
          ],
        }
      }
      else if(route.query.id == 105){
        state.dataList = {
          title: "绿色行动，从我做起——共建节约型企业环境",
          date: "2024-7-3",
          img_1: require('@/assets/news/105/1.png'),
          content_1: [
            {id:1,title:"亲爱的同事们，大家好！在这个充满活力的日子里，咱们不妨来一场“绿色革命”，一起为我们的企业穿上环保的绿衣吧！"}, 
            {id:2,title:"是不是有时候，一不留神，小小的包裹就满天飞了？咱们来个“三思而后寄”，看看那些文件能不能用电子邮件飞，会议资料能不能共享云盘见。寄送前，群里多召唤一下，拼单跨越，折扣更多，更省钱。"}, 
          ],
          content_2: [
            {id:1,title:"电灯、水龙头、还有那些无辜的纸张，它们都在默默呼唤着“节约”。记得离开座位时，给电脑、电灯、空调、打印机说声“拜拜”，水龙头用完记得拧紧，别让它默默流泪。纸张，双面打印，循环利用，让每一片纸都发挥出它的最大价值！"}, 
            {id:2,title:"出差虽好，可别贪杯哦！视频会议、电话会议，这些高科技工具用起来，既能高效沟通，又能减少碳排放，咱们何乐而不为呢？当然，必要的出差还是要的，但记得精打细算，合理安排，让每一趟旅程都物超所值！"}, 
            {id:3,title:"车子少开一天，蓝天就多一天。拼车出行，认识更多新朋友。绿色出行，或者干脆来个“步行日”，让咱们的身体也享受一下自然的馈赠。别忘了，健康环保两不误，才是真本事！"}, 
            {id:4,title:"同事们，节约不是口号，而是行动；不是一时兴起，而是持之以恒。让我们携手并肩，从我做起，从现在做起，为建设一个更加节约型、更加绿色的企业环境贡献自己的力量吧！"}, 
            {id:5,title:" 期待在节约的路上，与你并肩同行！"}, 
          ],
        }
      }
      else if(route.query.id == 106){
        state.dataList = {
          title: "激扬时代韶华，共聚青春力量——2024年第二期大学生岗前培训结业仪式",
          date: "2024-7-8",
          img_1: require('@/assets/news/106/1.png'),
          content_1: [
            {id:1,title:"激扬青春，放飞梦想，2024年新一批优秀大学应届毕业生也陆续走进了盛泰重庆公司的大门。为了让他们尽快熟悉工作环境，适应工作岗位，顺利实现角色转换，公司组织开展了为期五天的岗前培训，并于7月5日顺利完成结业仪式。本次活动不仅体现了公司对新人的重视和关怀，也展示了公司致力于人才培养的坚定信念。"}, 
           ],
          content_2: [
            {id:1,title:"岗前培训的内容同样分为了对公司品质相关基础知识讲解，研发相关的模组制程介绍，通用素质类别的信息安全、时间管理、沟通技巧等，还带学员们走进一线生产车间，亲身了解公司产品的生产过程和设备的运用场景。学员们本着“唯进步，不止步”的心态，认真聆听，勇于发言、敢于思考，积极交流，可谓收获满满。同时，为了检验大家的学习效果，激励他们今后在工作中继续保持奋进向上的姿态与动力，岗前培训结束后，公司组织讲师从各维度进行考察，评选出了3名优秀学员，以示鼓励！学员们也一同分享与交流了在培训中学到的知识、对公司的感受和对个人成长的感悟与想法。是他们的坚持与努力、青春与激情，吹响了盛泰新一轮拼搏的号角！"}, 
            {id:2,title:"当然，欲行千里，必有良师。为帮助应届毕业生尽快掌握立足立身的本领，促进全面发展，公司应用师徒制，为每位应届生配备一名带教导师，充分发挥技术骨干“传、帮、带”作用，引导新进大学生学习经验、熟悉岗位，提高专业技能和业务素质。结业仪式上，我们举行了短暂而又意义深远的拜师会，小小的帖子，结下的是情谊，传承的是匠心。每一对师徒将结伴走过一段相对漫长的工作历程，他们将在这个周而复始的平凡工作中，互勉共进，砥砺前行。"}, 
            {id:3,title:"活动结尾，公司领导对新人们寄予期望，希望新员工们以优秀的学长为榜样，在今后的工作中虚心请假，戒骄戒躁，夯实专业基础知识和技能，拓宽行业宽度，强化专业深度，放宽视野，抓住机遇，脚踏实地，奋进新征程！"}, 
           ],
        }
      }
      else if(route.query.id == 107){
        state.dataList = {
          title: "无偿献血我先行 爱心传递勇担当",
          date: "2024-7-12",
          img_1: require('@/assets/news/107/1.png'),
          content_1: [
            {id:1,title:"每一滴血都是生命焕发生机的“及时雨”。为进一步营造无偿献血、传递爱心的良好风尚，2024年7月11日，盛泰光电科技股份有限公司响应区委号召，积极参与到无偿献血活动中，以实际行动弘扬人道友爱精神，彰显企业社会责任与时代担当，传递正能量。"}, 
           ],
          content_2: [
            {id:1,title:"上午9时，无偿献血活动正式拉开序幕。前来献血的职工们早早地聚集在献血车前，排队等待献血。大家在血液中心的工作人员的指导下，有条不紊地完成着报名登记、血压测量、取样化验、血液采集等流程，满含爱与奉献的血液从职工们的手臂缓缓流向储血袋，汇聚成希望的生命海。前来献血的职工中，有从忙碌岗位上赶过来的车间一线员工和技术管理人员，有多次参加献血从容不迫的“老将”，也有初次参加献血稍显紧张的“新生力量”，他们都是无偿献血者，以博爱的名义致敬生命，以捐献热血的方式，为生命助力、为爱礼赞。此次无偿献血活动共有20余人报名，11人成功献血，部分员工因未达到献血要求而感到遗憾，但他们纷纷表示下次有机会还要来尝试，希望为社会公益事业尽自己的一份微薄之力"}, 
            {id:2,title:"鲜血有情，爱心无价，点滴温暖，积流成川。志愿者们用生命的一小部分，换来的是无数个急需血液救助的病患其生命的全部。此次无偿献血，不仅是代表公司对我区输血事业发展的一片心意，更是我司精神文明建设的重要内容之一，我们将会让这份大爱融入到企业文化的长河之中，将爱心的接力棒继续传递下去。"}, 
           ],
        }
      }
      else if(route.query.id == 108){
        state.dataList = {
          title: "荣誉加冕，致敬精英，共绘蓝图！",
          date: "2024-7-19",
          img_1: require('@/assets/news/108/1.png'),
          content_1: [
            {id:1,title:"为加强公司企业文化建设，不断增强企业向心力和凝聚力，提高工作效率，有效地激励工作业绩突出、综合表现优秀的员工，公司开展季度“盛泰之星”评选活动，经过为期1个月的评选，评选出了6位优秀的员工。2024年7月18日，人力资源部特举办2024年第二季度“盛泰之星”颁奖典礼，邀请大家共同见证他们的光荣时刻！"}, 
           ],
          content_2: [
            {id:1,title:"在过去的一季度中，他们以积极的态度 、专业的技能和无私的奉献，推动着公司各项业务的顺利展开 。他们不仅在自己的岗位上表现出色，更在团队合作中发挥了关键作用，是所有人学习的榜样。经过严格的考核和筛选，获得本季度的盛泰之星的是信息资讯部蒋祖全、人力资源部刘丹阳、品质部CQE肖育苗、行政部蒋中媛、移动终端事业部研发吴咏恒、运营部工程课FOL-B谢乐云。"}, 
            {id:2,title:"颁奖典礼上6位获奖人员分享了自己的获奖感言，他们非常感谢部门的同事以及领导的帮助与指导，感谢自己持之以恒的努力，才能在这个大家庭中发光发热，对于公司的福利待遇，部门的氛围以及公司整体的设施设备有较好的评价，这也是他们不断积极向上的动力。总经理也特别强调部门氛围对于团队来说是非常重要的，一个良好的团队氛围可以促进沟通和团队合作的高效性，让团队成员感到彼此之间的关注和信任，从而顺利地完成工作任务。此外，良好的团队氛围对员工的心理健康有着重要的影响，能够减少负面情绪，提高工作效率。"}, 
            {id:3,title:"通过本次评选活动，不仅是对“盛泰之星”的表彰，更是激励我们每一个人不断追求卓越的动力。在今后的工作中，让我们携手并进，共同努力，为实现公司的发展目标而不懈奋斗。再次向所有获奖者表示热烈的祝贺，感谢他们为公司付出的努力和辛勤工作。也希望所有员工能够以获奖者为榜样，不断提升自己的能力，为公司的发展贡献更多的力量。"}, 
           ],
        }
      }
      else if(route.query.id == 109){
        state.dataList = {
          title: "爱拼才会赢，乒乓球比赛精彩纷呈",
          date: "2024-7-29",
          img_1: require('@/assets/news/109/1.png'),
          content_1: [
            {id:1,title:"为了丰富工程师及会员单位职工们的体育文化生活，培养拼搏精神，激发工程师及会员们积极进取的工作热情。大足区工程师协会于2024年7月27 日在协会乒乓球俱乐部，举行了工程师之家乒乓球俱乐部揭牌仪式暨2024年乒乓球比赛。"}, 
           ],
          content_2: [
            {id:1,title:"作为会员单位盛泰光电科技股份有限公司，组织了5名乒乓球业余爱好者参赛，与重汽集团专用公司、中帝机械制造公司及协会各专委会60余名运动员齐聚一堂，奋力拼搏，整个比赛精彩纷呈、引人入胜。比赛的每一个环节都严格执行竞赛规则，确保了比赛的公正性。"}, 
            {id:2,title:"经过激烈对决，盛泰光电球队获得优秀奖，通过此次活动，让我们队员们切磋了球技、提高了水平，激发了他们热爱锻炼，重视健康，以饱满的精力和拼搏精神投身于专业工作之中。"}, 
           ],
        }
      }
      else if(route.query.id == 110){
        state.dataList = {
          title: "“益”托安心，快乐同行——2024年暑期爱心公益托管班开启守护计划",
          date: "2024-7-31",
          img_1: require('@/assets/news/110/1.png'),
          content_1: [
            {id:1,title:"孩子放假，“神兽出笼”，职场爸妈们在假期里如何带娃？孩子在家只会玩手机？不少家长表示头疼不已。为缓解暑期孩子看护难的问题，进一步解决职工的后顾之忧，盛泰光科团委在团区委的带领下开展暑期爱心公益托管服务活动。本次暑托班得到上级团委的大力支持，上级团委号召志愿者做老师，盛泰公司规划员工活动中心做为场地，给老师和孩子们免费提供午餐，第一期托管班于7月17日-30日圆满完成，第二期将于7月31日-8月13日进行。"}, 
           ],
          content_2: [
            {id:1,title:"本次托管活动一经发布，报名人数十分火爆，相比往年增加了3倍，为了保证托管的质量和效果，活动按人数分批次进行了展开。有职工表示，“公司能有帮忙托管小孩的福利真的感到非常开心，也不用担心孩子们一天在家偷偷玩游戏。”“孩子听说有托管班期待了好几天，终于不用一个人无聊地待在家了”。相比起市面动辄几百上千的暑期培训班或托管班，本次服务通过“免费，自愿”的形式，充分利用公司资源予以有需求的职工家庭更多便利。"}, 
            {id:2,title:"“老师，打铁花是什么，看起来很危险。”“老师，长城我去过，非常壮观。”托管教师是大学生实践团队的青年志愿者，课程设置上也更加富有趣味性和教育性。志愿者们结合自身专业特长，精心设置丰富多彩的教学内容，例如汉服、打铁花、二十四节气等课程，树立孩子们对中华优秀传统文化的认识和理解，通过爱国主题教育和国防知识的讲解，增强他们的爱国情怀；另外，为助力孩子们“德智体美”多方面均衡发展，促进劳逸结合，志愿老师们还组织形式多样的实践活动，例如“飞花令”、围棋、象棋、足球等，引导孩子们度过一个安全健康、快乐充实、有意义、有收获的暑期生活。"}, 
            {id:3,title:"“托”得安心，“管”得放心，是本次活动的宗旨。目前爱心托管服务工作还在持续进行中，盛泰光科团委也将继续为广大职工办实事、办好事，用实际行动提升职工的获得感、归属感、幸福感。"}, 
           ],
        }
      }
      else if(route.query.id == 111){
        state.dataList = {
          title: "盛泰光电携手“红岩先锋”，以实际行动助力乡村振兴",
          date: "2024-8-1",
          img_1: require('@/assets/news/111/1.png'),
          content_1: [
            {id:1,title:"2024年7月31日在大足区宝顶镇慈航社区新时代文明站成功举办了一场别开生面的志愿服务活动——“红岩先锋·审计为民，拓宽农产品销售渠道·助力乡村振兴”。此次活动聚焦于特色农业产业的高质量发展，旨在通过一系列创新举措，确保现代农业特色产业资金有效利用，进一步巩固脱贫攻坚成果，为乡村产业的繁荣注入新动力。"}, 
           ],
          content_2: [
            {id:1,title:"作为行业领先的光电企业，盛泰光电积极响应当地政府乡村振兴战略号召，荣幸受邀参与此次活动。活动现场，盛泰光电不仅展示了企业社会责任的担当，还身体力行地支持当地农产品销售，展现了企业公民的责任与情怀。通过本次活动盛泰光电与宝顶镇农户之间建立了长期合作的桥梁，为农民增收、解决农产品销售难题助力。此举不仅丰富了企业员工的餐桌文化，更是为企业供应链注入了绿色健康的农产品资源，实现了双赢。"}, 
            {id:2,title:"活动中，盛泰光电的代表们与农户亲切交流，深入了解农产品的生长环境与生产过程，公司未来将继续探索更多合作模式，深化与农村地区的经济联系，为实现乡村产业振兴贡献更大的力量。此次合作不仅是对企业文化的生动诠释，也是对国家乡村振兴战略的有力支持，共同绘就了一幅城乡融合发展的美好图景。"}, 
           ],
        }
      }
      else if(route.query.id == 115){
        state.dataList = {
          title: "凝“新”聚力，扬帆远航——2024年第二期IDL员工座谈会圆满结束",
          date: "2024-8-16",
          img_1: require('@/assets/news/115/1.png'),
          content_1: [
            {id:1,title:"七月悄然离去，八月温柔而来，挥别夏日的热烈，拥抱初秋的静美，新的篇章即将开启。在这美好的季节，盛泰光电科技股份有限公司迎来了2024年第二期IDL员工座谈会。随着公司领导和各部门员工代表陆续到场，现场气氛逐渐热烈起来。"}, 
            {id:2,title:"首先是嘉宾致辞环节。运营工程技术部的领导发表讲话，在讲话中领导对各位新同事的到来表示欢迎，鼓励大家在座谈会中畅所欲言，并希望大家在各自的岗位上能积极学习，勇于进取。"}, 
           ],
          content_2: [
            {id:1,title:"接下来是自我介绍环节。在场员工依次介绍了自己的背景和兴趣爱好。他们充满活力的发言赢得了在场同事的阵阵掌声，也为彼此之间的了解搭建了桥梁。"}, 
            {id:2,title:"分享答疑环节是座谈会的高潮部分。几位比较活跃的员工率先就自己在工作岗位上遇到的一些技术难题进行了分享及提问，随后其他同事也围绕工作或生活等方面纷纷提出问题，紧接着公司领导针对员工们提出的问题和建议进行了回应。领导表示，公司会认真考虑员工的意见，努力改善工作和生活环境，提供更多的培训机会，以促进员工的个人成长和团队的整体发展。领导的真诚回应赢得了在场员工的热烈掌声。"}, 
            {id:3,title:"随着座谈会的圆满结束，大家带着满满的收获和愉悦的心情离开了会场。这次活动不仅增进了员工之间的交流与合作，也为公司未来的发展注入了新的活力。"}, 
           ],
        }
      }
      else if(route.query.id == 114){
        state.dataList = {
          title: "《2024年重庆市智能工厂名单》正式公布，盛泰光电入选！",
          date: "2024-8-16",
          img_1: require('@/assets/news/114/1.png'),
          content_1: [
            {id:1,title:" 据重庆市经济信息委官网消息，发布《关于公布2024年重庆市智能工厂名单》的通知，公布了2024年重庆市智能工厂名单。其中，盛泰光电科技股份有限公司高端精密摄像头模组封装智能工厂入选。"}, 
            {id:2,title:" 据了解，这次入选的项目，将融入重庆市“产业大脑+未来工厂”数字化转型体系，推动新一代信息技术和制造业深度融合，纵深推进全市制造业数字化转型升级。“产业大脑”是以单个行业智能化建设场景为主，形成“政府端、企业端、行业端”互通，让数据在三者之间有效聚合、流通、使用；“未来工厂”是推动产业链、供应链深度互联和协同响应的高效率工厂。"}, 
           ],
          content_2: [
            {id:1,title:"盛泰光电自成立以来，始终坚持创新驱动发展，不断加大研发投入，致力于构建数字化、网络化、智能化的生产体系。公司引入了先进的智能制造设备与系统，如物联网、大数据分析、人工智能等前沿技术，实现了生产流程的精准控制与优化，大幅提升了生产效率与产品质量，降低了运营成本，为客户提供更优质的产品和服务。"}, 
            {id:2,title:"此次入选智能工厂名单，标志着盛泰光电在智能制造领域迈入了新的发展阶段。公司将继续深化智能制造技术的应用与创新，加速传统生产线向智能产线的转型升级，推动上下游产业链协同合作，构建智能制造生态圈，为重庆市乃至全国的智能制造产业升级贡献力量。同时将以此为契机，持续推动技术创新与管理革新，引领行业迈向更高水平的智能制造，为实现中国制造强国梦不懈奋斗。"}, 
           ],
        }
      }
      else if(route.query.id == 116){
        state.dataList = {
          title: "匠心筑梦，技胜未来，2024年岗位技能大赛动员大会正式拉开比赛序幕！",
          date: "2024-8-17",
          img_1: require('@/assets/news/116/1.png'),
          content_1: [
            {id:1,title:" 2024年8月16日下午，盛泰光电岗位技能大赛动员大会如期召开，标志着这场集专业性、竞技性与团队精神于一体的盛会正式拉开帷幕。本次大赛由运营部、品质部、行政部联袂主导，汇聚了全公司的目光，旨在提升员工专业技能，激发团队创新活力，促进企业文化的深层次交流。"}, 
           ],
          content_2: [
            {id:1,title:"大会现场，各部门领导嘉宾齐聚一堂，共同见证了这一振奋人心的时刻。裁判组代表庄严宣誓，承诺将以最公正无私的态度，严格执行比赛规则，确保每位参赛者的努力都能获得应有的评价。参赛选手代表满怀激情地表达了对比赛的期待与决心，他们的发言鼓舞了在场的每一个人，彰显了盛泰人追求卓越、勇于挑战的精神风貌。"}, 
            {id:2,title:"今年的岗位技能大赛除了有传统的外观检验、ME调机比赛项目外，还增加了维修比赛项目；同时考虑技术能力等级不同，还在三个比赛单项中分级增设工程师比赛，能更全面展示大家的技术实力。截止8月15日，外观检验项目127人报名参赛，ME调机+维修项目137人报名参赛；结合车间实际排产计划，外观检验实操比赛、ME调机和维修项目理论和实操比赛将在接下来的8、9月分别进行。当然本次比赛也为获奖选手、参赛选手、裁判组、策划组、工作人员准备了丰富的奖品"}, 
            {id:3,title:"动员大会的成功举办，不仅预示着接下来的赛事将是一场知识与技能的盛宴，更是对公司“本分、拼搏、分享、创造”核心价值观的一次生动实践。盛泰光电期望通过这次大赛，不仅能够选拔出一批技术标兵，更能整体提升公司员工的专业技能水平，为企业的持续发展注入强大动力。在此，我们预祝2024年盛泰光电岗位技能大赛圆满成功，期待各位参赛选手在赛场上勇于拼搏，展现风采，以优异的成绩为个人职业生涯添彩，为企业文化建设贡献力量。+维修项目137人报名参赛；结合车间实际排产计划，外观检验实操比赛、ME调机和维修项目理论和实操比赛将在接下来的8、9月分别进行。当然本次比赛也为获奖选手、参赛选手、裁判组、策划组、工作人员准备了丰富的奖品"}, 
           ],
        }
      }
      else if(route.query.id == 112){
        state.dataList = {
          title: "推进企业数字化转型升级 构建新质生产力发展",
          date: "2024-8-23",
          img_1: require('@/assets/news/112/1.png'),
          content_1: [
            {id:1,title:" 8月23日，大足区政协夏尧宣 、唐华俪副主席等领导一行到盛泰光电就制造业数字化转型工作进行调研。领导们强调，要深入学习贯彻党的二十届三中全会精神和习近平总书记关于加快构建现代化产业体系的重要指示精神，抢抓新一轮科技革命和产业变革机遇，大力实施产业升级战略，紧扣特色优势，加快推进企业数字化转型升级，构建新质生产力发展。"}, 
           ],
          content_2: [
            {id:1,title:"企业负责人带领参观了生产车间、智能仓库和重点实验室等，了解了车间生产过程数字化、关键工序数控化率、工业设备上云率等情况。公司荣膺全市摄像头模组生产领域的数字化车间与智能工厂典范，成功树立了数字领航标杆企业的卓越形象，领导们对此成就给予高度评价。鼓励盛泰加快制造业数字化转型步伐，示范带动更多企业乘“云”而上、借“数”发展。"}, 
           ],
        }
      }
      else if(route.query.id == 113){
        state.dataList = {
          title: "慰问优秀高考学子，温情关怀暖人心",
          date: "2024-8-30",
          img_1: require('@/assets/news/113/1.png'),
          content_1: [
            {id:1,title:"8月29日，在大足区石马镇政府会议室举行了2024年优秀高考学子慰问座谈会，大足区石马镇党委书记喻正科及盛泰光电负责人等相关领导参加慰问。会上，盛泰光电科技股份有限公司向7名大足区籍优秀学子送上美好祝福与殷切期盼，并向每位学子发放了助学奖励金，将温暖和关怀送到学生心坎上。"}, 
           ],
          content_2: [
            {id:1,title:"在慰问活动中，大足区政府领导和盛泰光电负责人对学子们成功考取心仪高校表示热烈祝贺，同时也对学子们表达了殷切期盼，要树立远大理想，脚踏实地向前进；懂感恩、知责任，力所能及回报社会。"}, 
            {id:2,title:"活动结束时，7名优秀学子对党和政府的关心及企业的爱心鼓励表示感谢，一致表示，将以心怀感恩之心，更加勤奋学习，掌握新本领，增长新才干，做一名对社会对国家有用的人才。"}, 
           ],
        }
      }

      else if(route.query.id == 117){
        state.dataList = {
          title: "月圆人团圆，趣玩中秋夜",
          date: "2024-9-14",
          img_1: require('@/assets/news/117/1.png'),
          content_1: [
            {id:1,title:"“皎皎明月意，浓浓中秋情”，为了弘扬中华民族的优秀传统文化，丰富公司员工的精神文化生活，营造热烈喜庆，文明祥和的节日气氛，同时庆祝员工生日，特此举办“月圆人团圆，趣玩中秋夜”中秋节活动暨7-9月员工生日会。"}, 
            {id:2,title:"桌面上丰富的制作月饼材料彰显着浓浓的中秋氛围，引起了大家的强烈兴趣，在主持人讲解完中秋节的意义及制作冰皮月饼的方法后，大家便迫不及待的动手制作起来。和面、搓面团、压饼皮、包馅料、压成型，大家分工明确，相互配合，经过半个小时的制作，桌子上的原材料变成了漂亮的冰皮月饼。整个制作过程中大家相互交流，相互借鉴，相互夸奖，现场一片祥和。-9月员工生日会。"}, 
           ],
          content_2: [
            {id:1,title:"众星捧月游戏需要通过团队成员间的默契配合，确保“月亮”不落地，团队协作能力、沟通技巧以及应急处理反应能力是获胜的关键。运送龙珠的游戏是一人在绳子一端放龙珠，一人通过绳子控制龙珠掉落位置，根据不同的掉落位置，获得不同的分数，积分最多的获胜。游戏体验中大家笑声连连，欢乐无限，让我们在收获快乐时光的同时，让团队凝聚力也得到提升。"}, 
            {id:2,title:"快乐的使馆少不了蛋糕与美食相伴，生日寿星们齐聚一堂，共切蛋糕，共享精致的月饼、美味的月饼蛋糕、无限小零食，共同许下美好的愿望。本次活动我们不仅感受到了公司的关爱和员工们的热情参与，更体会到了节日喜庆的气氛，大家不仅收获了丰富的奖品，更重要的是增强了员工之间的凝聚力和向心力。月圆中秋，情满人间，这场月光下的盛宴，让团圆的温暖成为心中永恒的光亮。"}, 
           ],
        }
      }
      else if(route.query.id == 118){
        state.dataList = {
          title: "匠心筑梦，技胜未来 | 2024年岗位技能大赛圆满结束",
          date: "2024-9-23",
          img_1: require('@/assets/news/118/1.png'),
          content_1: [
            {id:1,title:"“匠心筑梦，技胜未来”2024年岗位技能大赛经过紧张激烈的角逐已于9月6日完成所有比赛项目，198人完成比赛。本次大赛旨在弘扬工匠精神，激励技能人才追求卓越，经过多轮比拼，一批技艺精湛、勇于创新的优秀人才脱颖而出。我们秉承着“表彰优秀、树立榜样、鼓舞斗志“的宗旨，在这金秋送爽、硕果累累的美好时节，于9月20日在公司报告厅举办了颁奖仪式，与公司领导、各部门负责人、获奖选手、裁判工作组、参赛选手一起见证这份荣耀时刻！"}, 
            {id:2,title:"今年的岗位技能大赛由行政部、运营部、品质部共同组织策划，相较于以往有所改变，新增了维修项目，并且在WB、四工位、八工位项目按工程能力将工程师和技术员分开较量。各位选手以卓越的技能、严谨的态度和不懈的努力，在赛场上展现了非凡的风采，最终调机维修项目40人获奖，外观检测项目6人获奖。"}, 
           ],
          content_2: [
            {id:1,title:"“理想和现实之间，不变的是跋涉；暗淡与辉煌之间，不变的是开拓。”获奖选手在这次技能大赛中脱颖而出正是完美诠释了这句话。"}, 
            {id:2,title:"“九层之台，始于垒土。”每份耕耘，都有着一份收获。公司的成长和发展，离不开在平凡岗位上兢兢业业奋斗的员工。他们本着“不问东西、不负芳华、初心如磐、责任在肩”的精神在岗位上努力奉献，发光发热；他们在各自的岗位上默默耕耘，不断从日常工作中汲取经验，磨炼技能，提升自我，为公司的事业版图添砖加瓦。"}, 
            {id:3,title:"“匠心筑梦，技胜未来”不仅是前进的方向，更是我们对未来美好的期许。在往后的工作中让我们激流勇进、铿锵前行；心怀远梦、攀越高峰；携手并进，开创更美好的未来。"}, 
           ],
        }
      }
      else if(route.query.id == 119){
        state.dataList = {
          title: "红花背后是绿叶 ——致岗位技能大赛的裁判组人员",
          date: "2024-9-26",
          img_1: require('@/assets/news/119/1.png'),
          content_1: [
            {id:1,title:"“奉献既是⼀种⾼尚的情操，也是⼀种平凡的精神”用这句话送给我们这次技能大赛的所有裁判当之无愧。今年由行政部、运营部、品质部一起合力举办的技能大赛，除了有获奖选手精彩的表现，更有背后一批默默付出的裁判在认真出题、监考、审题、核对成绩，为比赛保驾护航。 ——致岗位技能大赛的裁判组人员"}, 
            {id:2,title:"2024年技能大赛从8月20号开始理论考试截止到9月6号实操比赛全部结束，历时18天就全部完成比赛。对比去年的比赛因AA项目延迟半个月来看，时间提升50%。这里面自然离不开辛勤工作的裁判团队，本次比赛三个段加上维修总共安排25名裁判，其中SMT段3名裁判，FOL段9名，EOL段8名，维修5名裁判。 ——致岗位技能大赛的裁判组人员"}, 
           ],
          content_2: [
            {id:1,title:"这次比赛之前我们就定下三个原则。第一，不能影响车间生产。第二，不能影响车间改机，比赛要在改机前做准备，参赛选手提前安排人替补改机。第三，不能造成品质问题，所有比赛改机后产品，裁判要追踪良率，保证首件正常。比赛作为练兵的最佳训练手段，必须跟平时实战改机一样，如果发现有比赛中改机造成产品不良的评分作废。"}, 
            {id:2,title:"8月份正是车间忙碌的时候，各段都没有多余的设备，我们计划是实操每天比赛1-2名选手，每天上午跟生产部门确认改机的机种和设备，提前通知选手做好准备，等机台做完前一个弹夹人员到位，工治具在前1小时，必须到位。在比赛期间，策划组每天跟踪比赛进度，最终确保今年的实操比赛提前完成。"}, 
            {id:3,title:"今年还安排小组裁判长和裁判员机制，裁判员负责现场监督比赛，裁判长负责最后审核分数，做到交叉审核，减少出错机会。"}, 
            {id:4,title:"这次比赛因为选手是白晚班都有，且分布各车间，很多裁判要分别跑各车间去现场监考。比如FOL的张卫既是现场RME主管，要负责现场车间改机安排工作，还要在繁重的工作中抽出时间审核比赛成绩。再比如EOL车间李莉，作为生产副经理，现场生产任务繁重，每天出货机种繁多，各种工作千头万绪，还需要抽出时间加班协助监考比赛。最后就是我们运营部领导方总给予了最有力度的支持，每次开会对我们技能比赛团队都是非常支持，需要协调的事情都是第一时间安排。因为有了各团队成员的共同努力，才会有最后的成果。最后感谢我们裁判团队的所有成员，相信大家在运营部各位领导的带领下将继续为公司发展业绩做出更大的贡献。"}, 
           ],
        }
      }
      else if(route.query.id == 120){
        state.dataList = {
          title: "双喜临门‖盛泰光电荣膺2024重庆制造业百强与重庆市企业创新奖",
          date: "2024-9-27",
          img_1: require('@/assets/news/120/1.png'),
          content_1: [
            {id:1,title:"9月25日，重庆市企业联合会（企业家协会）揭晓2024重庆企业100强榜单，同期发布了2024重庆制造业企业100强、重庆服务业企业100强名单。"}, 
            {id:2,title:"在“2024重庆制造业企业100强”榜单中，盛泰光电科技股份有限公司凭借其卓越的创新能力、稳健的经营业绩及显著的行业影响力，荣耀入选。"}, 
            {id:1,title:"与此同时，公司凭借其在技术创新方面的杰出表现，再度传来捷报，荣获“重庆市企业创新奖”。两项殊荣的取得，不仅是对盛泰光电综合实力的高度肯定，也是对公司持续探索创新、引领行业进步的有力证明。"}, 
           ],
          content_2: [
            {id:1,title:"盛泰光电科技股份有限公司始终坚持以科技创新为核心驱动力。在过去的一年里，公司不断突破技术瓶颈，推出了一系列具有自主知识产权的高端光电产品，有效提升了国内光电行业的国际竞争力。其产品广泛应用在5G通信、物联网、智慧城市等多个前沿领域，为全球客户带来了前所未有的智能生活体验。"}, 
            {id:2,title:"智能制造，赋能产业升级。顺应“工业4.0”发展趋势，盛泰光电积极布局智能制造体系，引进国际先进的自动化生产线与管理系统，实现了生产流程的智能化、精细化管理。这一战略转型不仅极大提高了生产效率与产品质量，更为企业可持续发展奠定坚实基础，引领重庆乃至全国光电制造业向高端化、智能化转型升级。"}, 
            {id:3,title:"展望未来，公司将继续秉承“做强，做大，走远，做百年光电企业”的企业愿景，深化产学研合作，拓展国际合作版图，致力于成为全球光电科技领域的领航者，为推动重庆乃至中国制造业高质量发展不懈努力。"}, 
           ],
        }
      }
      else if(route.query.id == 121){
        state.dataList = {
          title: "盛泰光电成功举行2024年下半年消防安全演练——强化安全意识，共创平安厂区",
          date: "2024-9-27",
          img_1: require('@/assets/news/121/1.png'),
          content_1: [
            {id:1,title:"2024年9月26日，盛泰光电成功举办了一场规模宏大的消防安全演练活动，此次活动旨在提高全体员工的消防安全意识与应急处理能力，确保在紧急情况下能够迅速、有效地采取行动，保护生命财产安全。"}, 
            {id:2,title:"此次演练，模拟了办公区某处突发火情，随着警报声响起，全体员工迅速响应应急预案，有序撤离至安全集合点。同时，公司义务消防队迅速集结，使用干粉灭火器和消防栓有效控制并扑灭“火源”，展现了高效的应急反应速度和团队协作能力。随后，通过模拟火灾场景，让员工亲身体验火灾报警、使用灭火器等环节。"}, 
           ],
          content_2: [
            {id:1,title:"盛泰光电行政部负责人表示：“通过这次演练，不仅检验了我们应急预案的有效性，更增强了全员的安全意识，这是我们对员工安全负责的具体体现。本次消防演练不仅是一次安全教育的实践，更是对公司应急管理体系的一次重要检验。盛泰光电将持续加大安全生产投入，定期举办此类活动，确保每位员工都能成为企业安全的守护者，共同营造一个安全、和谐的工作环境。”"}, 
           ],
        }
      }
      else if(route.query.id == 122){
        state.dataList = {
          title: "盛泰光电科技股份有限公司荣获2024年重庆市制造业单项冠军",
          date: "2024-10-9",
          img_1: require('@/assets/news/122/1.png'),
          content_1: [
            {id:1,title:"9月27日，重庆市经济和信息化委员会公布了《2024年重庆市制造业单项冠军企业名单》，盛泰光电科技股份有限公司成功入选。"}, 
            {id:2,title:"根据《重庆市制造业单项冠军企业认定管理办法》（渝经信规范〔2024〕8号）、《关于开展2024年重庆市制造业单项冠军企业遴选认定的通知》（渝经信中小〔2024〕10号），经企业申报、区县初审、市级评审、社会公示等程序，确定了2024年重庆市制造业单项冠军企业名单。有效期3年：2024年10月1日至2027年9月30日。"}, 
            {id:3,title:"单项冠军被誉为制造业金字塔的“塔尖”，是指长期专注并深耕制造业产品领域，生产技术高，关键性能指标处于国内同类产品领先水平，自主创新能力突出的企业，对于推动制造业转型升级具有重要意义。盛泰光电获此殊荣，是对其在摄像模组制造领域长期深耕细作、不断创新突破的高度认可。"}, 
           ],
          content_2: [
            {id:1,title:"盛泰光电2006年7⽉创⽴于深圳，2010年7⽉成⽴江西公司，2018年4⽉成⽴重庆公司，2019年成⽴印度公司。⽬前已形成重庆（总部+基地）中⼼、深圳（研发+销售）中⼼、江西+印度制造基地、台湾研发中⼼、新加坡研发中⼼，以及美国办事处的总体布局。"}, 
            {id:2,title:"公司建成现代化⽣产线85条、新建COB芯⽚封装线40余条、新购进超⾼精度AA⽣产线多条，摄像头产能达5亿个/年，现有⾼端摄像头模组⽣产⻋间全部升级为百级、千级⽆尘⻋间；同时，依托《中国制造2025》的国家战略，快速推进⾃动化、智能化⽣产，参照⼯业4.0标准向⾼技术、智能化、数字化制造企业快速迈进。"}, 
            {id:3,title:"2020年公司规划占地⾯积261亩的盛泰光电科技企业孵化园投⼊建设 。— 期建设投资10亿元，建筑⾯积约83000㎡，其中产品技术研发中⼼8000㎡，联合实验室2000㎡，⽣产中⼼40000㎡，配套办公⽤房10000㎡，⽣活配套⽤房23000㎡，并在2022年正式投⼊了使⽤。"}, 
            {id:4,title:"盛泰合作伙伴遍布全球各地，其中包括著名的⼿机、笔电、电脑、⻋载及医疗品牌，如OPPO、传⾳、三星、荣耀、华为、vivo、TCL、华硕、海信、ACER、⽐亚迪、⼴汽、道通、微觅、瑞派医疗等世界知名客⼾。盛泰光电在快速成⻓中秉承强化中国制造，壮⼤⺠族产业的初⼼，不断奋勇前进。"}, 
           ],
        }
      }
      else if(route.query.id == 123){
        state.dataList = {
          title: "“秋风启航越山海,盛泰之星逐梦行 ”2024年第三季度盛泰之星颁奖典礼圆满落幕",
          date: "2024-10-24",
          img_1: require('@/assets/news/123/1.png'),
          content_1: [
            {id:1,title:"为弘扬先进精神，树立行业标杆，进一步凝聚团队力量，激发全体员工的积极性和创造力，我司于近日成功举办了2024年第三季度“盛泰之星”评选活动。经过一个多月的严格评选，最终有8名杰出员工脱颖而出，荣获“盛泰之星”称号。"}, 
            {id:2,title:"10月22日，公司隆重举行了颁奖典礼。在过去的第三季度里，这些优秀的同事们以卓越的工作业绩和实际行动，生动诠释了公司“本分、拼搏、分享、创造”的核心价值观。他们在各自的岗位上默默耕耘，为公司的繁荣发展注入了强大的动力"}, 
           ],
          content_2: [
            {id:1,title:" 一等奖由移动终端事业部贾进全夺得，他凭借其卓越的研发能力，为公司产品的技术创新做出了重要贡献。二等奖获奖者为移动终端事业部吉雄、行政部胡佳、运营部工程课杨小林。他们分别在技术研发、行政管理、生产运营等领域展现了出色的专业素养和敬业精神，为公司的稳定发展提供了坚实保障。三等奖获奖者为移动终端事业部闫小刚、品质部张义、品质部马雪瑜、董事办沈鹭。他们在测试验证、品质控制、审计监督等方面发挥了重要作用，为公司的产品质量和合规经营保驾护航。"}, 
            {id:2,title:"这些优秀员工是盛泰大家庭中积极向上、奋发有为的杰出代表。他们展现出的开拓精神、敬业精神以及无私奉献的职业操守，如同一面面鲜艳的旗帜，引领着全体员工不断前行。公司的发展离不开每一位员工的辛勤付出和无私奉献，正是有了这些优秀员工的共同努力，公司才取得了今天的辉煌成就。"}, 
            {id:3,title:"在此，公司希望全体员工能够以这些优秀员工为榜样，保持昂扬向上的斗志和充沛的工作干劲，在团队合作中相互理解、相互鼓励、相互支持。让我们携手并进，共同为实现企业的高质量发展创造更加辉煌的成绩！"}, 
            ],
        }
      }
      else if(route.query.id == 124){
        state.dataList = {
          title: "廊桥通途，便捷启航 —— 打造更贴心的员工通行环境",
          date: "2024-10-28",
          img_1: require('@/assets/news/124/1.png'),
          content_1: [
            {id:1,title:"本着方便研发及生产制造部门同仁日常通行，全面优化员工工作环境这一朴素而坚定的初心，盛泰光电于2024年6月初正式开启公司廊桥修建工作，经过数月的精心规划与科学施工，盛泰光电廊桥项目已顺利竣工并正式投用。"}, 
           ],
          content_2: [
            {id:1,title:"这条横贯一号楼与二号楼之间的廊桥，不仅是一道亮丽的建筑风景线，更是连接起各部门之间的纽带。廊桥的投用极大地缩短员工通勤时间，提升员工办公效率与出行便捷性，让研发创新的灵感得以无缝流转，让生产制造的活力涌动在每一步脚下。"}, 
            {id:2,title:"廊桥的落成是公司对“知员工情、解员工忧、纾员工困、暖员工心”理念的又一次生动实践。我们相信，这一细微的改变，将为盛泰大家庭增添更多的温暖与便利，激发出每一位员工更大的工作热情与创造力。"}, 
            ],
        }
      }
      else if(route.query.id == 125){
        state.dataList = {
          title: "运营部中连线专案报告大会圆满结束",
          date: "2024-10-28",
          img_1: require('@/assets/news/125/1.png'),
          content_1: [
            {id:1,title:"运营部中连线专案历时半年的设备调试，终于在10月份瓜熟蒂落，COB三个车间数条中连线已经正常运转，并于10月22号召开中连线专案报告大会，各部门领导和车间主要负责人参加本次会议。会上，运营部负责人从中连线项目品质改善、不良率提升、生产物料管理、线体OEE等四个方面进了细致深入总结。"}, 
           ],
          content_2: [
            {id:1,title:"公司总经理总结，以往的COB作业模式较为孤立，每个站位独立运作，工作主要依靠个人技能完成，而中连线项目特别强调各部门之间的团队协作，生产和RME部门需要设立明确的责任，并为此制定合理的奖惩规则，以增强团队的凝聚力和向心力。同时中连线项目推行也面临换线和改机时间增加的问题，在以后的工作中，提前准备和快速响应变得尤为重要，这也对维修工程师的技术水平提出了更高的要求，需要大家通过日常训练不断提高技术水平，以减少修机时间，确保生产流程的顺畅。"}, 
            {id:2,title:"手机模组生产的两大杀手就是ESD静电和灰尘，实现中连线连接后，相关站位可以一站式完成，这将极大地提升现场生产产品的合格率。"}, 
            {id:3,title:"我们通过技术创新和高效管理，追求卓越，努力达到行业的最高标准，致力于成为行业领先的自动化手机模组生产企业。"}, 
            ],
        }
      }
      else if(route.query.id == 126){
        state.dataList = {
          title: "喜讯丨盛泰光电荣获2024年度智能摄像头检测技术知识产权保护示范创建项目",
          date: "2024-10-28",
          img_1: require('@/assets/news/126/1.png'),
          content_1: [
            {id:1,title:"据重庆市知识产权局官网消息，发布《关于2024年度知识产权保护示范创建拟立项项目名单》的公示通知，公布了2024年度知识产权保护示范创建项目入选企业名单。其中，盛泰光电科技股份有限公司智能摄像头检测技术知识产权保护示范创建项目成功入选。"}, 
            {id:2,title:"盛泰光电科技股份有限公司自成立以来，公司一向注重知识产权的保护，积极申请各类专利、著作权等。截止至2024年10月20日，公司累计获得专利超过400项。公司现有知识产权团队，通过持续的学习和培训，不断提升自身的专业素养，为公司提供高质量的知识产权保护和管理服务。"}, 
           ],
          content_2: [
            {id:1,title:"在全球化的竞争环境下，知识产权已成为国际竞争的关键要素，此次获得智能摄像头检测技术的知识产权保护，不仅有助于提升公司在该领域的国际竞争力，还能进一步扩大市场份额，提高公司整体科研能力。构建一个健全的知识产权保护体系，充分保护创新成果，避免技术泄露和非法复制，从而激发行业内持续的创新动力，促进相关产业及其上下游企业的协同发展，为经济增长注入新的活力。"}, 
            ],
        }
      }

      else if(route.query.id == 127){
        state.dataList = {
          title: "重庆市经济和信息化委员会党组书记、主任王志杰一行莅临我司开展调研工作",
          date: "2024-11-1",
          img_1: require('@/assets/news/127/1.png'),
          content_1: [
            {id:1,title:"10月30日下午，重庆市经济和信息化委员会党组书记、主任王志杰一行莅临我司开展调研工作，大足区委书记陈一清，大足区委副书记、大足高新区党工委书记罗晓春等领导陪同调研。"}, 
           ],
          content_2: [ 
            {id:1,title:"王志杰主任一行参观了公司展厅、生产车间、智能化仓库以及重点实验室等多个核心区域，并与公司管理层进行了深入交流，希望以问需求、话发展、解难题的方式，了解企业发展中需要的支持，全力助力企业做大、做优、做强。"}, 
            {id:2,title:"在交流中，调研组对盛泰光电近年来在产品研发、技术创新以及市场拓展取得的成就给予了高度评价和充分肯定，同时特别鼓励公司能够敏锐把握市场机遇，结合自身技术优势，不断推陈出新，拓展更多领域的产品线，持续增强企业的核心竞争力，充分发挥作为龙头企业的引领作用和重点项目的拉动效应，招引更多优质、大型的项目落地，深耕重庆大足这片沃土，进一步拓展产业链条，加速打造具有影响力的产业集群，为地方经济的繁荣发展作出更大的贡献。"}, 
            ],
        }
      }
      else if(route.query.id == 128){
        state.dataList = {
          title: "喜讯|盛泰光电荣膺2024年高新技术企业认证",
          date: "2024-11-13",
          img_1: require('@/assets/news/128/1.png'),
          content_1: [
            {id:1,title:"近日，经科技部、财政部、国家税务总局三大部委共同认定，盛泰光电科技股份有限公司通过高新企业认定备案。"}, 
            {id:2,title:"盛泰光电近年来在光电领域加大技术开发投入、鼓励专利研发、倡导技术进步和产品创新，不断向市场和客户提供高质量的产品和服务。"}, 
            {id:3,title:"未来，盛泰光电将继续以技术研发为主要竞争策略，在光电领域持续深耕细作，不断开启新篇章。"}, 
           ],
        }
      }
      else if(route.query.id == 129){
        state.dataList = {
          title: "篮球风暴·梦想起航——2024盛泰光电冬季篮球赛圆满收官",
          date: "2024-11-23",
          img_1: require('@/assets/news/129/1.png'),
          content_1: [
            {id:1,title:"为丰富员工体育文化生活，增强团队凝聚力，盛泰光电于11月21日在盛泰篮球场举行了一场别开生面的冬季篮球赛。"}, 
            {id:2,title:"球场上，球员们身着各自鲜艳的队服，在结束热身运动后蓄势待发，眼神中透露出对胜利的渴望，热闹的场面吸引了数位同事驻足观看。随着裁判一声哨响，比赛正式开始，两队球员迅速进入状态，展开了你来我往的攻防战。"}, 
           ],
          content_2: [ 
            {id:1,title:"红队灵活穿梭于对手之间，精准地传球给前锋，蓝队高举双手跃跃欲试，吸引了全场的注意力。红队不甘示弱，随后红队队员凭借敏捷的步伐和出色的运球技巧，连续突破得分。"}, 
            {id:2,title:"半场结束时，蓝队领先。中场休息期间，双方队长紧张地布置战术，球员们也在互相打气，调整状态。下半场开始后，比赛更加激烈，蓝队主力在内线展现出强大的统治力，多次抢下篮板并完成得分。"}, 
            {id:3,title:"最后几分钟，双方的比拼进入白热化阶段，每一次进攻和防守对于最后的比赛结果都显得至关重要。在激烈的角逐后，蓝队夺得了本次篮球赛的冠军。球员们相互拥抱庆祝，观众席上的球迷们也激动地跳跃欢呼。"}, 
            {id:4,title:"在这场充满激情与汗水的篮球赛中，我们见证了体育竞技的魅力、运动员们的拼搏精神、团队协作的力量。每一次跳跃投篮，每一次接力传球，都凝聚着团队成员之间的深厚信任，正是这种无间的团队合作，才铸就了比赛的全部精彩瞬间。感谢所有参与和支持这场比赛的球员、裁判和观众，是你们共同创造了这个难忘的夜晚，让我们期待下一场更加精彩的对决！"}, 
            ],
        }
      }
      else if(route.query.id == 130){
        state.dataList = {
          title: "训有所成，试有所展，教有所长——2024年内部讲师培训圆满结束",
          date: "2024-11-23",
          img_1: require('@/assets/news/130/1.png'),
          content_1: [
            {id:1,title:"在知识经济的浪潮中，人才是企业发展的核心竞争力，而内部讲师则是知识传承的关键纽带。2024 年9月-10月，人力资源部举办了新一届的内部讲师培训，为公司的知识传承和人才发展注入了新生力量。"}, 
            {id:2,title:"本次培训讲授从课程设计的艺术到授课技巧的打磨，全方位提升讲师的能力。培训导师从通过自我介绍抓住学员的注意力开始，用生动的案例阐述了在课程开场时设计引人入胜环节的重要性；在方法传授方面，详细讲解了多样化的教学方法，例如视听教学、个案研讨、角色扮演等，让学员在参与中学习，在实践中领悟；在课程设计板块，深入拆解了如何确定清晰的教学目标，搭建合理的内容框架以及设计引人入胜的教学活动。通过欢快的互动，学员讲师们将理论与实践相结合，碰撞出智慧的火花。"}, 
           ],
          content_2: [ 
            {id:1,title:"每一次上台都是一次挑战，但也是一次成长的机会，经过一个月的消化和准备，学员讲师试讲评定也如期开展。报名参加试讲的讲师们怀揣着紧张与期待，纷纷登上讲台展示自己这段时间的学习成果。他们风格各异，每一位都展现出了对讲师这份职业的热情和尊重。同时，台下扮演学员的评委组不仅生动演绎了学员注意力不集中、不愿互动、提出挑战性问题等各种常见课堂情形，让讲师们现场体验，学习如何灵活应对，还给予详细的反馈和专业的建议。经过试讲与评定，本次共计9名讲师脱颖而出，被评定为初级讲师，为公司的内部讲师队伍增添了新的风采。"}, 
            {id:2,title:"此次内部讲师培训的圆满开展，是公司重视人才培养的生动写照。它不仅传承了优秀讲师的宝贵经验，更像是一把神奇的火炬，点燃了公司培养新生力量的希望之火。我们期待着这些讲师在未来的培训工作中发光发热，肩负起传播知识的重任，将所学运用到实际教学中，共同推动公司向着更高的目标迈进。"}, 
            ],
        }
      }
      else if(route.query.id == 131){
        state.dataList = {
          title: "同心同行同成长，携手共进创未来|人力资源部举行2024年第三期IDL座谈会",
          date: "2024-11-25",
          img_1: require('@/assets/news/131/1.png'),
          content_1: [
            {id:1,title:"秋末初冬，时光如诗，为帮助新员工快速了解公司，融入集体，11月21日人力资源部组织开展了2024年第三期IDL员工座谈会，旨在为大家解决工作和生活中的问题，加强员工与公司管理层的有效沟通，提升员工满意度。"}, 
            {id:2,title:"本次座谈会荣幸地邀请了来自各部门的员工及领导，共计50人参加。会上新员工们围绕各自的姓名、部门、工作经历、兴趣爱好、职业规划等依次进行了自我介绍，消除了彼此之间的距离感，增进了相互之间的了解，为未来工作中的交流与合作奠定了坚实的基础。"}, 
           ],
          content_2: [ 
            {id:1,title:"座谈会的核心环节是畅所欲言，在这个环节，员工们纷纷敞开心扉，与领导们进行现场一对一交流与反馈，就日常工作、生活中遇到的需要协助解决的问题、存在的难点问题等进行了深入交流，无论技术上的平静、流程上的不畅，还是工作以来的感受体会、个人成长中的困惑，员工们都毫无保留地提了出来。面对这些真实的声音，现场相关领导展现出了高度的责任感和专业精神，能够当场解答的问题立即给予明确回复，对于需要进一步研究和探讨的问题，则在会议后组织相关人员讨论协商后作出回复。"}, 
            {id:2,title:"心有繁星，沐光而行。员工座谈会不只是一场有温度的交流会，它更传递了公司对新员工的期望。希望每一位新伙伴，都可以以满腔热情迎接工作任务，以坚韧毅力攻克艰难险阻，携手并进，共筑盛泰美好未来！"}, 
            ],
        }
      }
      else if(route.query.id == 132){
        state.dataList = {
          title: "大足区委组织部（老干部局）组织离退休干部到盛泰光电参观调研",
          date: "2024-11-27",
          img_1: require('@/assets/news/132/1.png'),
          content_1: [
            {id:1,title:"11月25日上午，大足区委组织部（老干部局）组织离退休干部一行到盛泰光电参观调研，了解大足区在科技创新和产业发展方面的最新成果。"}, 
           ],
          content_2: [ 
            {id:1,title:"离退休干部一行在公司展厅近距离参观盛泰近年来自主研发的一系列高科技产品，如智能终端设备、物联网解决方案以及智能家居摄像头模组等；了解产品的开发背景、技术创新点及其市场应用领域，摄像模组行业的最新发展。在座谈会上，公司相关负责人向老同志们全面介绍了盛泰光电的成长历程、当前运营状况及未来发展规划等信息。"}, 
            {id:2,title:"各位离退休干部对盛泰公司的科技创新能力和发展现状给予高度赞赏，对于更好促进区域经济持续健康发展也提出了许多宝贵意见和建议。"}, 
            ],
        }
      }
      else if(route.query.id == 133){
        state.dataList = {
          title: "喜讯|盛泰光电荣获“双百企业”奖项",
          date: "2024-12-2",
          img_1: require('@/assets/news/133/1.png'),
          content_1: [
            {id:1,title:"11月25日，2024重庆民营企业100强、2024重庆制造业民营企业100强榜单发布。“双百企业”评选以企业营收总额为主要标准，盛泰光电凭借多年稳扎稳打的积累，分别荣获重庆制造业民营企业100强第38名，2024重庆民营企业100强第69名。"}, 
           ],
          content_2: [ 
            {id:1,title:"盛泰光电作为一家专注于摄像头模组研发、制造及销售的高新技术企业，凭借其强大的技术研发实力与先进的生产能力，在行业内树立了卓越的品牌形象。近年来，随着科技的进步和社会需求的变化，盛泰光电积极调整战略方向，不仅持续深耕手机摄像头模组市场，还成功将业务范围扩展到了笔记本、车载、医疗等多个新兴领域。"}, 
            {id:2,title:"展望未来，公司将以更加开放的姿态拥抱变化，继续发挥示范带动作用，持续推进各项技术创新，不断谱写光电企业创新发展新篇章。"}, 
            ],
        }
      }
      else if(route.query.id == 134){
        state.dataList = {
          title: "喜讯|盛泰光电荣获大足区2023年度科技型企业研发投入第二名",
          date: "2024-12-2",
          img_1: require('@/assets/news/134/1.png'),
          content_1: [
            {id:1,title:" 11月28日，盛泰光电代表团赴区党政办公中心参加“大足区科技创新和人才工作大会”。此次会议旨在推动企业人才链、创新链、产业链、资金链深度融合发展。"}, 
           ],
          content_2: [ 
            {id:1,title:"会上，区科技局、区经济信息委、代表企业等政企代表在科技赋能制造、人才队伍建设、科技创新、产业创新、产业融合等方面进行了分享交流，同时公布了大足区2023年度科技型企业研发前3强、2023年度高新技术企业税收前3强企业，盛泰光电荣获2023年度大足区科技型企业研发投入第二名。盛泰光电始终秉承人才为先的战略，致力于构建“引进-使用-激励-培育”的良性人才机制，深化校企合作，引进更多多元化人才，努力提高产、学、研一体化发展以及科技创新成果的有效转化，以此推动公司技术创新的持续发展。"}, 
            {id:2,title:"多年来，盛泰光电坚持高强度的研发投入，将科技创新与产业创新紧密结合、垂直整合产业链，有效解决了数据孤岛问题，在打造百亿级光电产业集群的道路上稳步前行。"}, 
            ],
        }
      }
      // else if(route.query.id == 135){
      //   state.dataList = {
      //     title: "喜讯|xx获得亚洲区XX奖",
      //     date: "2024-12-X",
      //     img_1: require('@/assets/news/135/1.png'),
      //     content_1: [
      //       {id:1,title:" xxx1。"}, 
      //      ],
      //     content_2: [ 
      //       {id:1,title:"xxx2.1。"}, 
      //       {id:2,title:"xxx3.1。"}, 
      //       ],
      //   }
      // }
      else if(route.query.id == 135){
        state.dataList = {
          title: "关爱员工，健康同行——2024全员健康体检",
          date: "2024-12-3",
          img_1: require('@/assets/news/135/1.png'),
          content_1: [
            {id:1,title:"公司基于关爱员工出发，为帮助员工更好地了解自身健康状况，增强大家的健康意识和身体素质，盛泰光电于2024年11月30日至12月1日组织员工分批次进行健康体检。"}, 
            {id:2,title:"本次体检涵盖肝功能、血常规、DR、血压以及视力检查等多个重要项目。为确保体检工作顺利进行，公司提前与合作医疗机构进行了充分沟通协调，并特别提醒所有参与者在体检前一天保持清淡饮食、充足休息以及空腹状态，以保证体检数据的准确性和可靠性。"}, 
           ],
          content_2: [ 
            {id:1,title:"体检当天，现场井然有序，同事们听从安排，按照预定流程有序完成各项检查；医护人员专业对待，无论是简单的测量血压、抽取血液样本，还是进行各项复杂的仪器检测，他们始终保持严谨的态度，面对大家提出的各种健康问题，也耐心解答，并提供个性化建议。此外，本次体检活动还得到了内部各部门的大力支持。从前期策划到后期执行，各个环节紧密配合，使得整个体检过程既高效又人性化。"}, 
            {id:2,title:"通过健康体检，不仅让广大员工对自己的身体状况有了更清晰的认识，同时也让大家感受到了来自公司的关怀与温暖。员工身体健康是企业最大的财富，也是企业得以稳定发展的保证，公司坚持落实健康体检，把对员工的关爱落到实处，既促进了公司与员工的共同健康发展，又进一步增强了员工的归属感和凝聚力！"}, 
            ],
        }
      }
      else if(route.query.id == 136){
        state.dataList = {
          title: "圈友相聚展实力、继往开来谱新篇——2024年QCC品质圈活动圆满完成",
          date: "2024-12-3",
          img_1: require('@/assets/news/136/1.png'),
          content_1: [
            {id:1,title:"2024年11月27日，由品质部主办的QCC品质圈活动在热烈的氛围中圆满落幕，人力资源部、信息资讯部、品质部、供应链管理部、行政部、NB&AIOT运营部、工程技术部共计10个团队踊跃参与并为品质提升贡献了智慧与力量。"}, 
           ],
          content_2: [ 
            {id:1,title:"活动期间，各团队充分发挥专业优势，针对工作中的问题展开热烈的讨论。从精准选题到原因的深入分析，再到提出切实可行的解决方案，每一个环节都凝聚着团队成员的心血与智慧。在总结报告中，评审团对各小组的成果展示进行了专业细致的点评。他们充分肯定了各团队的努力，也针对存在的问题和不足提出改进意见和建议，并为各团队进一步完善方案指明了方向，同时希望后期能根据部门特性分类开展活动，强调QCC品管圈活动要注重“小、实、活、新”，以形成全员参与品质管理的良好氛围。各位领导的点评不仅让参赛小组受益匪浅，也为整个活动增添了深度和广度。"}, 
            {id:2,title:"经过各团队的激烈竞争，品质攻坚队凭借出色的表现摘得冠军,运营工程技术部专案改善队、供应链管理部必胜队荣获亚军。"}, 
            {id:3,title:"运营工程技术部点胶改善队、品质部质控先锋队获得季军，人力资源部可乐队、信息资讯部信息队、行政安保工程课北斗星队、行政服务课阳光先锋队、NB&AIOT运营部开拓者队5个团队获得优秀奖。"}, 
            {id:4,title:"本次QCC品管圈活动，在探索如何降低产品不良率，提高生产效率，优化工作流程以及增加客户满意度等方面均取得了显著成果。这离不开每一位参与者的辛勤付出，更离不开领导们的关心与支持。公司将以此为契机，将QCC品管圈的理念和方法持续融入到日常工作中，不断发现问题、解决问题，同时鼓励各团队持续深化品质意识，不断追求卓越，为公司的品质管理工作注入源源不断的动力。在未来的工作中，品质部将与各部门携手共进，充分发挥团队的智慧和力量，为公司发展提供更加坚实的品质保障，创造更加辉煌的业绩。"}, 
            ],
        }
      }
      else if(route.query.id == 137){
        state.dataList = {
          title: "喜报 | 盛泰光电荣膺“重庆市企业技术中心”称号",
          date: "2024-12-7",
          img_1: require('@/assets/news/137/1.png'),
          content_1: [
            {id:1,title:" 近日，重庆市经济和信息化委员会最新发布《重庆市认定企业技术中心2022—2023年度评价结果》，盛泰光电科技股份有限公司荣膺“重庆市企业技术中心”称号。"},  
           ],
          content_2: [ 
            {id:1,title:"重庆市企业技术中心是指具备较强经济技术实力、较高研发投入和显著创新能力的企业，要求企业研发投入强度高于全市同行业平均水平，并且在全市同行业中具有较强的创新人才优势。我司此次荣膺此称号，是对公司综合实力及创新能力的认可。"}, 
            {id:2,title:"盛泰光电以技术创新为驱动，不断强化核心自主知识产权、科技成果转化能力、研发组织管理水平等体系建设，在技术研发方面保持强劲势头，拥有专利技术400余项。未来将继续加大研发投入，不断提高企业核心竞争力，为打造“百年光电企业”持续奋斗。"}, 
            ],
        }
      }
      else if(route.query.id == 138){
        state.dataList = {
          title: "喜讯|盛泰光电笔电摄像头模组荣获2024年重庆市制造业单项冠军",
          date: "2024-12-11",
          img_1: require('@/assets/news/138/1.png'),
          content_1: [
            {id:1,title:"12月7日，2024年重庆市产业创新大会成功举办，来自各科技企业、高等院校、科研院所、区县部门的200余名嘉宾汇聚一堂，共商共谋创新路径，共话共促融合发展。大会发布了《重庆市未来产业培育行动计划》、《重庆市产业创新综合体建设方案》和《重庆市产业技术创新发展报告》三项重要内容，同时为25家荣获2024年重庆市制造业单项冠军企业授牌，盛泰光电作为获奖企业受邀参加本次大会。"}, 
           ],
          content_2: [ 
            {id:1,title:"盛泰光电本次获得单项冠军的产品是笔记本电脑摄像头模组。公司自主研发的Flip chip封装工艺+独创 EGA 嵌入式贴合工艺，成功解决了感光芯片和滤光片直接接触导致影像存在光斑的问题，同时笔电摄像头模组厚度可做到2.02mm，降低了摄像头模组的重量并减小了厚度有限的限制，补齐了我国微型摄像头生产技术短板。根据尚普公司市场占有率报告显示，我司笔记本电脑摄像头模组2021-2023年市场占有率连续三年均位列全球排名前三，国内排名第一。"}, 
            {id:2,title:"盛泰光电始终坚持技术创新和智能制造，以高质量的产品与高品质的服务持续满足市场发展需求，贯彻落实培育新质生产力，为全市产业创新发展提供强有力的支撑。"}, 
            ],
        }
      }
      else if(route.query.id == 139){
        state.dataList = {
          title: "中国中小企业国际合作协会深入盛泰光电调研，共探产教融合新路径",
          date: "2024-12-17",
          img_1: require('@/assets/news/139/1.png'),
          content_1: [
            {id:1,title:"12月12日下午，中国中小企业国际合作协会携手中国中小企业出海服务联盟、成渝地区双城经济圈产教融合发展联盟莅临盛泰光电开展企业调研与交流。"}, 
           ],
          content_2: [ 
            {id:1,title:"调研中，企业负责人向来自哈萨克斯坦等中亚国家的外宾介绍了公司的发展史、产品构成、应用用领域、生产工艺流程。调研组一行对盛泰光电在科技创新的成就及助力行业发展的成就和贡献给予了高度赞赏并指出盛泰光电的经验对于探索产教融合新路径及区域经济社会的可持续发展具有重要参考意义。"}, 
            ],
        }
      }
      else if(route.query.id == 140){
        state.dataList = {
          title: "探技术深境，于论坛畅行；汇百家卓见，燃创新繁星——2024年盛泰举办首届技术论坛预选赛",
          date: "2024-12-19",
          img_1: require('@/assets/news/140/1.png'),
          content_1: [
            {id:1,title:"为搭建技术交流与合作的优质平台，促进各类工程技术人员的专业知识成长和技术能力的提升，盛泰光电科技股份有限公司于12月14日举办了首届技术论坛预选赛，一场技术与智慧交融的盛宴就此展开。"}, 
            {id:2,title:"本次技术论坛秉持开放包容的原则，诚挚邀请了不同方向的技术单位人才踊跃报名参加，旨在汇聚各方智慧，挖掘前沿技术理念与创新项目，促进各技术单位的知识共享和经验交流。历经近一个月的紧张准备与项目提报，经过初审，18个项目入围预选赛，它们涵盖光学设计、精密制造、材料研发、自动化工程、IT技术等多个与公司业务紧密相关的技术领域。在准备期间，所有报名项目的成员们展现出了持续的热情和高度关注，他们在本职工作忙碌的间隙，认真投入到项目的构思与筹备之中，每一个项目都凝聚着他们的汗水与智慧，无论是对技术难题的攻克，还是对创新理念的挖掘，都彰显出了他们深厚的专业功底和无畏的探索勇气。"}, 
           ],
          content_2: [ 
            {id:1,title:"本次评审依据创新程度、应用价值、发展潜力等多维度进行严格评判，同事评审方式也独具特色，不仅有公司内部资深技术专家，还特别引入了观众打分机制，让对此次活动充满兴趣的观众参与到项目打分环节。观众来自公司的不同部门，他们以各自的视角和对技术创新的独特理解，为评审过程增添了多元的声音与活力，使得整个评审结果更加全面、客观且富有公信力。"},  
            {id:2,title:"经过激烈角逐与综合评定，10个项目组脱颖而出，成功晋级决赛，也将迎来终极较量，再展风采。进入决赛的项目组，无疑是众多参赛者中的佼佼者，他们的项目不仅在技术上独具匠心，更在应用前景和商业潜力方面表现出色。他们用实际行动诠释了对技术创新的执着追求，为其他选手树立了优秀的榜样。"}, 
            {id:3,title:"持续创新是公司保持长久竞争力的核心要素。本次技术论坛中，所有参赛人员展现出的技术实力和创新意识，是推动公司技术发展的重要力量源泉，希望所有人能从中收获宝贵经验，在未来的技术探索中不断优化提升，以更加成熟和完善的姿态迎接新的挑战。公司始终坚定不移地走在创新驱动发展的道路上，探寻无限可能，期望通过此次活动，挖掘更多优质项目，并将其转化为推动公司发展的核心生产力，同时也期待更多志同道合的伙伴一同加入到创新的浪潮中。"},        
            ],
        }
      }
      else if(route.query.id == 141){
        state.dataList = {
          title: "创新成果“亮剑”，发展引擎劲燃——公司首届技术论坛决赛顺利收官",
          date: "2025-1-10",
          img_1: require('@/assets/news/141/1.png'),
          content_1: [
            {id:1,title:"技术论坛是打破部门间的信息壁垒，促进不同专业背景的员工相互学习、协同合作的有效平台。2025年1月4日，盛泰光电首届技术论坛决赛顺利举办，这充分展现出了公司内部强大的创新潜能，让我们看到了不同思维碰撞下所诞生的智慧结晶。"}, 
           ],
          content_2: [ 
            {id:1,title:"决赛中，10个项目组再次满怀信心地登台亮相，纷纷展示出了各自的技术亮点与创新成果。有的项目组聚焦于智能平台与系统的开发，针对人工记录繁琐且易出错的场景，将整个流程进行系统化的梳理与整合，通过信息化技术的深度应用，使各个业务流程之间实现了有效对接，数据能够实时自动采集、存储与分析，极大地提高了工作效率；有的项目组在高端模组方面取得了重大突破，这无疑是对公司产品性能提升的一次有力推动，他们深入钻研技术难题，通过独特的结构设计以及先进的制造工艺，精准地满足了用户日益增长的更高需求，让公司的产品在市场上更具竞争力，赢得了客户的青睐，也为公司进一步拓展市场奠定了坚实基础；还有的项目组专注于产品设计与工艺开发，展现出了卓越的创新智慧，他们对产品结构进行了全面且深入的重新审视与优化，摒弃了传统结构中不合理、冗余的部分，融入了更加科学、高效的设计理念，使产品结构和功能更加完善，为公司节省了大量的生产成本，带来了可观的经济效益。项目组们都敢于突破传统的限制，通过创新思维打造出不同的技术成果，且具备较强的适用性，能运用到其他领域，展现出极大的拓展价值。"},  
            {id:2,title:"专家评审团在评审过程中也积极给出了诸多建设性的建议。他们建议进一步拓展技术原理与实际生产和需求相结合，在技术应用的深度及广度继续深入挖掘，让创新成果能更切实地落地转化，发挥出更大的价值。创新从来都不是脱离实际的空想，可以来源于解决实际业务和技术场景难点和懂点，让看似高深的技术理论在日常的工作中有效应用。这还需要我们的技术团队进一步加强与各部门的沟通协作，共同探索更多的应用场景和创新出发点，确保技术不再是”空中楼阁”，而是实实在在能为公司创造效益的有力工具。"}, 
            {id:3,title:"首届技术论坛的成功举办，不仅在公司内部营造出了浓厚的创新氛围，激励着更多的员工积极主动地去思考、去探索新技术、新方法，还是推动公司持续发展的有力引擎，助力公司朝着更高端、更具竞争力的方向迈进，为公司开拓多元化的业务版图提供了无限可能。"},        
            ],
        }
      }

    })
    return {
      ...toRefs(state), ...methods
    }
  }
}
</script>

<style scoped lang="less">
.p_1 {
  color: #939393;
  font-size: 17px;
  margin-top: 30px;
  text-align: center;
}

.content p:nth-child(4) {
  margin-top: 60px;
}

.content img {
  margin: 30px auto;
  width: 60%;
  height: auto;
  display: table-cell
}

.box {
  width: 100%;

  p {
    color: #666;

  }

  .p_01 {
    font-size: 18px;
    margin-top: 30px;
    text-indent: 0;
  }

  .img_1,
  .img_2 {
    cursor: pointer;
    width: 50px;
    height: 50px !important;
    height: 100%;
    margin: 0;
  }

  .img_2 {
    margin-left: 15px;
  }

  .content {
    margin: 0 auto;
    width: 70%;
    min-height: 100px;
    max-width: 1920px;

    h1 {
      text-align: center;
      margin-top: 50px;
    }
  }
}

::v-deep .el-carousel {
  max-width: 1920px;
  margin: 0 auto;
  overflow-y: hidden;
  height: auto;
  max-height: 600px;
  // max-height: 700px;
}

::v-deep .el-carousel__container {
  height: 100% !important;
  min-height: 500px;
}

.el-carousel {
  max-width: 1920px;
  margin: 0 auto;
}

::v-deep .el-card {
  border-radius: 8px;
}

::v-deep .el-carousel {
  max-width: 1920px;
  margin: 0 auto;
  overflow-y: hidden;
  height: auto;
  max-height: 600px;
  // max-height: 700px;
}

::v-deep .el-carousel__container {
  height: 100% !important;
  min-height: 500px;
}

.el-carousel {
  max-width: 1920px;
  margin: 0 auto;
}

::v-deep .el-card {
  border-radius: 8px;
}

.images {
  width: 100%;
  height: auto;
  max-height: 600px
}

.divI {
  margin-top: 50px;
}



.span_1 {
  position: relative;
  width: 55px;
  height: auto;
  font-size: 40px;
  color: rgb(102, 102, 102);
  cursor: pointer;
  display: inline-block;
}

.span_2 {
  font-size: 48px;
  color: rgb(102, 102, 102);
  cursor: pointer;
  margin-left: 10px;
}

.span_1:hover,
.span_2:hover {
  color: rgb(51, 51, 51);
}

.qrcode {
  position: absolute;
  top: 55px;
  left: 50%;
  transform: translateX(-50%);
  min-width: 100px;
  min-height: 100px;
  padding: 10px;
  box-shadow: 0 2px 10px #aaa;
  box-sizing: border-box;
  background-color: #fff;
  // border: 1px solid #ccc;
  display: block;
  display: flex;
  flex-direction: column-reverse;

  p {
    text-align: center;
    font-size: 14px;
    margin: 5px 0;
    padding: 0;
  }
}

.qrcode::before {
  content: '';
  position: absolute;
  left: 50%;
  top: -10px;
  transform: translateX(-50%);
  width: 0;
  height: 0;

  border-bottom: 5px solid #ddd;
  border-top: 5px solid transparent;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}

// 小屏幕区域
@media only screen and (min-width: 767px) {
  .box {
    display: block;
  }

  ._div {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .box {
    display: none;
  }

  ._div {
    position: relative;
    display: block;

    .shade {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.3);
      z-index: 10;
    }

    .diaLog {
      position: fixed;
      top: 50%;
      transform: translate(-50%, -50%);
      left: 50%;
      background: #fff;
      width: 900px;
      height: 700px;
      box-shadow: 0px 0px 10px rgb(0 0 0 / 20%);
      padding: 70px 30px 0px 30px;
      box-sizing: border-box;
      text-align: center;
      z-index: 99;

      h3 {
        margin-top: 20px;
        font-size: 60px;
      }

      p {
        font-size: 45px;
        margin-top: -30px;
      }

      .I {
        margin: 30px 0;
        font-size: 45px;
        color: #1f8dd6;
        cursor: pointer;
      }
    }

    h2 {
      font-size: 80px;
    }

    .center {
      text-align: left;

      h2 {
        text-align: center;
        width: 80%;
        margin: 50px auto;
      }

      .textImg {
        padding: 0 30px;
        box-sizing: border-box;

        p {
          margin: 50px 0;
          font-size: 60px;
        }

        img {
          margin: 50px 0;
          width: 100%;
        }
      }

      .p_1 {
        font-size: 40px;
      }

      .divI {
        margin: 100px auto;
        width: 80%;

        #qrcode {
          width: 500px;
        }

        .span_1,
        .span_2 {
          font-size: 100px;
        }

        .span_2 {
          margin-left: 100px;
          font-size: 120px;
        }
      }
    }
  }

}
</style>

<style>
</style>